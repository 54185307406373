import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push, goBack} from 'connected-react-router';
import * as mediaEntitlementActions from '../media-entitlement/mediaEntitlementActions';
import './MediaPlayerView.scss';
import withOverlay from '../ui-elements/overlay/withOverlay';
import AzurePlayer from '../ui-elements/azure-player/AzurePlayer';
import AzurePlayerControls from '../ui-elements/azure-player/AzurePlayerControls';

const HASH_VALUE = 'player-view';

@withOverlay
class MediaPlayerView extends Component {
    static propTypes = {
        mediaItemPlaybackSource: PropTypes.object,
        mediaItem: PropTypes.object,
        stopMediaItemPlayback: PropTypes.func.isRequired,
        openOverlay: PropTypes.func,
        closeOverlay: PropTypes.func,
        subscribeToOverlayClick: PropTypes.func,
        unsubscribeFromOverlayClick: PropTypes.func,
        location: PropTypes.object.isRequired,
        push: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
    };

    static defaultProps = {
        mediaItemPlaybackSource: null,
        mediaItem: null,
        openOverlay: null,
        closeOverlay: null,
        subscribeToOverlayClick: null,
        unsubscribeFromOverlayClick: null,
    };

    componentDidUpdate(prevProps) {
        const {location} = this.props;
        // stop playback on back browser button click
        return location.hash !== prevProps.location.hash && prevProps.location.hash.includes(HASH_VALUE)
            ? this.onMediaPlaybackStop()
            : null;
    }

    onPlayerReady = () => {
        const {location, push} = this.props;
        const updatedLocation = {
            ...location,
            hash: HASH_VALUE,
        };
        return !location.hash.includes(HASH_VALUE) && push(updatedLocation);
    };

    onMediaPlaybackStop = () => {
        const {stopMediaItemPlayback, closeOverlay, unsubscribeFromOverlayClick} = this.props;
        stopMediaItemPlayback();
        closeOverlay();
        unsubscribeFromOverlayClick(this.onOverlayClick);
        this.goBackInHistory();
    };

    onOverlayClick = () => {
        const {stopMediaItemPlayback, closeOverlay} = this.props;
        stopMediaItemPlayback();
        closeOverlay();
        this.goBackInHistory();
    };

    goBackInHistory(location = this.props.location, hashValue = HASH_VALUE) {
        const {goBack} = this.props;
        return location.hash.includes(hashValue) && goBack();
    }

    render() {
        const {mediaItemPlaybackSource, mediaItem, openOverlay, subscribeToOverlayClick} = this.props;
        if (mediaItemPlaybackSource && mediaItemPlaybackSource.url) {
            openOverlay();
            subscribeToOverlayClick(this.onOverlayClick);
            return (
                <div className="vub-c-media-player-view">
                    <AzurePlayer
                        playbackSource={mediaItemPlaybackSource}
                        onMediaPlaybackStop={this.onMediaPlaybackStop}
                        onPlayerReady={this.onPlayerReady}
                    >
                        <AzurePlayerControls
                            mediaItem={mediaItem}
                        />
                    </AzurePlayer>
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = state => ({
    mediaItemPlaybackSource: state.mediaEntitlement.mediaItemPlaybackSource,
    mediaItem: state.media.media.get(state.mediaEntitlement.playbackMediaItemId),
    location: state.router.location,
});

const mapDispatchToProps = dispatch => ({
    stopMediaItemPlayback: payload => dispatch(mediaEntitlementActions.stopMediaItemPlayback(payload)),
    push: payload => dispatch(push(payload)),
    goBack: () => dispatch(goBack()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MediaPlayerView);
