import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import routePaths from '../routePaths';
import resolveRoute from '../navigation/resolveRoute';
import * as mediaEntitlementActions from '../media-entitlement/mediaEntitlementActions';
import * as authActions from '../auth/authActions';
import * as selectors from './fastCheckoutViewSelectors';
import './FastCheckoutView.scss';
import withPopUp from '../ui-elements/pop-up/withPopUp';
import CTAButton from '../ui-elements/CTAButton';
import CustomSelect from '../ui-elements/custom-select/CustomSelect';
import Option from '../ui-elements/custom-select/Option';

@withPopUp
class FastCheckoutView extends Component {
    static propTypes = {
        isFastCheckoutInProgress: PropTypes.bool,
        basketItem: PropTypes.object,
        isBasketChargingInProgress: PropTypes.bool,
        userAccount: PropTypes.object,
        mediaItem: PropTypes.object,
        cancelMediaItemOfferFastCheckout: PropTypes.func.isRequired,
        chargeBasket: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
        setPopUp: PropTypes.func,
        closePopUp: PropTypes.func,
    };

    static defaultProps = {
        isFastCheckoutInProgress: false,
        basketItem: null,
        isBasketChargingInProgress: false,
        userAccount: null,
        mediaItem: null,
        setPopUp: null,
        closePopUp: null,
    };

    state = {
        paymentMethod: null,
    };

    componentDidUpdate(prevProps) {
        const {isFastCheckoutInProgress, basketItem, isBasketChargingInProgress, closePopUp, setPopUp} = this.props;
        if (isFastCheckoutInProgress
            && (basketItem !== prevProps.basketItem
                || isBasketChargingInProgress !== prevProps.isBasketChargingInProgress)) {
            setPopUp(this.renderFastCheckoutView());
        }

        if (!isFastCheckoutInProgress && prevProps.isFastCheckoutInProgress) {
            closePopUp();
        }
    }

    cancelMediaItemOfferFastCheckout = () => {
        const {cancelMediaItemOfferFastCheckout, closePopUp} = this.props;

        cancelMediaItemOfferFastCheckout();
        closePopUp();
    };

    onPaymentMethodSelect = paymentMethod => {
        this.setState({
            paymentMethod,
        }, () => this.props.setPopUp(this.renderFastCheckoutView()));
    };

    renderFastCheckoutView = () => {
        const {userAccount, updateUser} = this.props;
        const {basketItem, mediaItem, chargeBasket, isBasketChargingInProgress} = this.props;
        const offer = basketItem ? mediaItem.offers.find(offer => offer.id === basketItem.offerId) : null;

        const formatTag = basketItem && offer.resolution === '1080p' ? (
            <sup className="vub-c-fast-checkout-view__format-hd">HD</sup>
        ) : null;

        return basketItem ? (
            <div className="vub-c-fast-checkout-view">
                <div className="vub-c-fast-checkout-view__title">
                    Purchase confirmation
                </div>
                <div className="vub-c-fast-checkout-view__poster">
                    <img
                        className="vub-c-fast-checkout-view__image"
                        src={`${mediaItem.imageBoxCover}&q=70&w=200`}
                        alt="tile"
                    />
                </div>
                <div className="vub-c-fast-checkout-view__item-title">
                    {mediaItem.title}&nbsp;{formatTag}
                </div>
                <div className="vub-c-fast-checkout-view__basic-information">
                    <div className="vub-c-fast-checkout-view__certificate">
                        Certificate: {mediaItem.contentAdvisoryRating}
                    </div>
                    <div className="vub-c-fast-checkout-view__year">
                        {mediaItem.year}
                    </div>
                    <div className="vub-c-fast-checkout-view__duration">
                        {mediaItem.durationMinutes}
                    </div>
                    <div className="vub-c-fast-checkout-view__genre">
                        {mediaItem.mediaGenreId}
                    </div>
                </div>
                <div className="vub-c-fast-checkout-view__owned-notice">
                    Rental includes 30 days to start watching and 48h to finish.
                </div>
                <div className="vub-c-fast-checkout-view__payment">
                    <div className="vub-c-fast-checkout-view__payment-title">Payment</div>
                    {userAccount.paymentMethods.length === 0 && (
                        <CustomSelect
                            onChange={null}
                            isTouchDeviceCustomDropDownDisabled={true}
                        >
                            <Option
                                key="no-card"
                                value="no-card"
                            >
                                No cards available
                            </Option>
                        </CustomSelect>
                    )}
                    {userAccount.paymentMethods.length > 0 && (
                        <CustomSelect
                            value={this.state.paymentMethod}
                            onChange={this.onPaymentMethodSelect}
                            isTouchDeviceCustomDropDownDisabled={true}
                        >
                            {userAccount.paymentMethods.map(paymentMethod => {
                                const paymentMethodLabel = paymentMethod.creditCard
                                    ? `${paymentMethod.creditCard.name} *${paymentMethod.creditCard.account
                                        .slice(paymentMethod.creditCard.account.length - 4)}`
                                    : 'Unknown';

                                return (
                                    <Option
                                        key={paymentMethod.id}
                                        value={paymentMethod.id}
                                    >
                                        {paymentMethodLabel}
                                    </Option>
                                );
                            })}
                        </CustomSelect>
                    )}
                    <label
                        className="vub-c-fast-checkout-view__payment-link"
                        onClick={updateUser}
                    >
                        Update my details
                    </label>
                </div>
                {offer && (
                    <div className="vub-c-fast-checkout-view__item-total">
                        <span>Total: </span>
                        <span>
                            {offer.price.toLocaleString('en', {
                                style: 'currency',
                                currency: offer.currency,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </span>
                    </div>
                )}
                <div className="vub-c-fast-checkout-view__info">
                    By pressing &quot;Confirm Purchase&quot; you agree that you have read and accept&nbsp;
                    <Link
                        className="vub-c-fast-checkout-view__link"
                        to={resolveRoute(routePaths.PAGE, {pageId: 'terms-of-use'})}
                        target="_blank"
                    >
                        Terms & Conditions
                    </Link>
                    &nbsp;and wish to complete your purchase
                </div>
                <div className="vub-c-fast-checkout-view__cta-buttons">
                    <CTAButton
                        className="vub-c-cta-button--secondary"
                        onClick={this.cancelMediaItemOfferFastCheckout}
                        isDisabled={isBasketChargingInProgress}
                    >
                        Cancel
                    </CTAButton>
                    <CTAButton
                        onClick={chargeBasket}
                        isDisabled={isBasketChargingInProgress}
                    >
                        Confirm &amp; Rent
                    </CTAButton>
                </div>
            </div>
        ) : null;
    };

    render() {
        return null;
    }
}

const mapStateToProps = state => ({
    isFastCheckoutInProgress: state.mediaEntitlement.isFastCheckoutInProgress,
    basketItem: selectors.getBasketItem(state),
    isBasketChargingInProgress: state.mediaEntitlement.isBasketChargingInProgress,
    userAccount: state.auth.userAccount,
    mediaItem: selectors.getMediaItem(state),
});

const mapDispatchToProps = dispatch => ({
    cancelMediaItemOfferFastCheckout: payload => dispatch(
        mediaEntitlementActions.cancelMediaItemOfferFastCheckout(payload),
    ),
    chargeBasket: payload => dispatch(
        mediaEntitlementActions.chargeBasket(payload),
    ),
    updateUser: payload => dispatch(authActions.updateUser(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FastCheckoutView);
