import React from 'react';
import PropTypes from 'prop-types';
import './MediaItemHeroImage.scss';

const MediaItemHeroImage = ({mediaItem, children}) => (
    <div className="vub-c-media-item-hero-image">
        <div
            className="vub-c-media-item-hero-image__background-image"
            style={{backgroundImage: `url('${mediaItem.imageThumbnail}&q=70')`}}
        />
        {children}
    </div>
);

MediaItemHeroImage.propTypes = {
    mediaItem: PropTypes.object,
};

MediaItemHeroImage.defaultProps = {
    mediaItem: null,
};

export default MediaItemHeroImage;
