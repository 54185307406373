import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
    BEMClassNameResolver,
    BEMClassNameResolverPropTypes,
    BEMClassNameResolverDefaultProps,
} from '../../ui-kit/BEMClassNameResolver';
import {
    withMediaItemPopover,
    withMediaItemPopoverPropTypes,
    withMediaItemPopoverDefaultProps,
} from '../media-item-popover/withMediaItemPopover';
import './MediaCarouselSlidePortrait.scss';
import CarouselSlide from '../../ui-kit/CarouselSlide';

@withMediaItemPopover
@BEMClassNameResolver('vub-c-media-carousel-slide-portrait')
class MediaCarouselSlidePortrait extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
        ...withMediaItemPopoverPropTypes,
        mediaItem: PropTypes.object,
        linkTo: PropTypes.object,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
        ...withMediaItemPopoverDefaultProps,
        mediaItem: null,
        linkTo: {pathname: ''},
    };

    componentWillUnmount() {
        this.props.toggleMediaItemPopover(false);
    }

    onMouseEnter = () => this.props.toggleMediaItemPopover(true, this.props.mediaItem);

    onMouseOut = () => this.props.toggleMediaItemPopover(false);

    render() {
        const {resolveClassName} = this.props;
        const {mediaItem, popoverCallerRef, linkTo} = this.props;
        const imageUrl = mediaItem.imageBoxCover ? `${mediaItem.imageBoxCover}&q=70&w=200` : null;

        return (
            <CarouselSlide blockName={resolveClassName()}>

                <div
                    className={resolveClassName('__content')}
                    onMouseEnter={this.onMouseEnter}
                    onMouseOut={this.onMouseOut}
                    ref={popoverCallerRef}
                >
                    <div className={resolveClassName('__image-placeholder')}>
                        {mediaItem ? (
                            <Link
                                className="vub-c-media-gallery-tile-portrait__link"
                                to={linkTo}
                            >
                                <img
                                    className={resolveClassName('__image')}
                                    src={imageUrl}
                                    alt="box cover"
                                />
                            </Link>
                        ) : null}
                    </div>
                </div>

            </CarouselSlide>
        );
    }
}

export default MediaCarouselSlidePortrait;
