import React from 'react';
import PropTypes from 'prop-types';
import './Option.scss';

const Option = ({
    children,
    value,
    onChange,
    selectedValue,
    parentValue,
    isDisabled,
    label,
    className,
}) => {
    const val = value || label;
    return (
        <li
            className={`vub-c-custom-select-item ${selectedValue === val ? 'vub-c-custom-select-item--is-active' : ''} ${isDisabled ? 'vub-c-custom-select-item--is-disabled' : ''} ${className || ''}`}
            onClick={() => !isDisabled && onChange(val, parentValue)}
        >
            {children}
        </li>
    );
};

Option.propTypes = {
    value: PropTypes.node,
    onChange: PropTypes.func,
    parentValue: PropTypes.string,
    isDisabled: PropTypes.bool,
    selectedValue: PropTypes.node,
    label: PropTypes.node,
    className: PropTypes.string,
};

Option.defaultProps = {
    value: null,
    onChange: null,
    parentValue: null,
    isDisabled: false,
    selectedValue: null,
    label: null,
    className: null,
};

export default Option;
