import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {mediaItemStaffMemberType} from '@comrock/vub-wls-media-service';
import './MediaItemViewCredits.scss';

export default class MediaItemViewCredits extends PureComponent {
    static propTypes = {
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        mediaItem: null,
    };

    render() {
        const {mediaItem} = this.props;

        return mediaItem.staffMembers.length > 0 ? (
            <div className="vub-c-media-item-view-credits">
                <p className="vub-c-media-item-view-credits__row">
                    <span className="vub-c-media-item-view-credits__title">Director:</span>
                    <span className="vub-c-media-item-view-credits__text">
                        {mediaItem.staffMembers
                            .filter(staffMember => staffMember.type === mediaItemStaffMemberType.DIRECTOR)
                            .map(staffMember => staffMember.name)
                            .join(', ')}
                    </span>
                </p>
                <p className="vub-c-media-item-view-credits__row">
                    <span className="vub-c-media-item-view-credits__title">Cast:</span>
                    <span className="vub-c-media-item-view-credits__text">
                        {mediaItem.staffMembers
                            .filter(staffMember => staffMember.type === mediaItemStaffMemberType.ACTOR)
                            .map(staffMembers => staffMembers.name)
                            .join(', ')}
                    </span>
                </p>
            </div>
        ) : null;
    }
}
