import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import MobileDetect from 'mobile-detect';
import routePaths from '../../routePaths';
import resolveRoute from '../../navigation/resolveRoute';
import {mediaRouteResolver} from '../../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../../media/withMediaRouteHelpers';
import {markSearchQueryString} from '../../media-search-view/mediaSearchUtils';
import {
    withMediaSearch,
    withMediaSearchPropTypes,
    withMediaSearchDefaultProps,
} from '../../media/withMediaSearch';
import {
    withOutsideClick,
    withOutsideClickPropTypes,
    withOutsideClickDefaultProps,
} from '../../ui-elements/outside-click/withOutsideClick';
import './NavigationSearch.scss';
import {iconNavSearch, iconNavClear, iconInputSearch} from '../../ui-elements/icons';
import NavigationIconButton from './NavigationIconButton';
import NavigationIcon from './NavigationIcon';
import ShowMoreButton from './ShowMoreButton';
import SpinnerStandard from '../../ui-elements/spinner/SpinnerStandard';

const minimumSearchQueryLength = 3;

@withRouter
@withOutsideClick
@withMediaSearch
@withMediaRouteHelpers({mediaRouteResolver})
class NavigationSearch extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        ...withMediaSearchPropTypes,
        ...withOutsideClickPropTypes,
        history: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        ...withMediaSearchDefaultProps,
        ...withOutsideClickDefaultProps,
        history: null,
    };

    state = {
        isSearchOpened: this.props.isSearchRouteActive,
    };

    componentDidMount() {
        this.props.subscribeToClickOutside(this.searchContainer, this.onClickOutside);
    }

    componentWillUnmount() {
        this.props.unsubscribeFromClickOutside(this.searchContainer, this.onClickOutside);
    }

    searchInput = React.createRef();

    searchContainer = React.createRef();

    onClickOutside = () => {
        const {isSearchRouteActive} = this.props;
        const md = new MobileDetect(window.navigator.userAgent);
        const isMobile = md.phone();
        if (!isSearchRouteActive && !isMobile) {
            this.setState({
                isSearchOpened: false,
            });
        }
    };

    toggleSearch = () => {
        const {isSearchRouteActive} = this.props;
        const md = new MobileDetect(window.navigator.userAgent);
        const isMobile = md.phone();
        if (isSearchRouteActive && !isMobile) return;
        this.setState(state => ({
            isSearchOpened: !state.isSearchOpened,
        }), () => {
            if (this.state.isSearchOpened) {
                const searchInputNode = this.searchInput.current;
                searchInputNode.focus();
            }
        });
    };

    onSearchInputChange = event => this.props.setSearchQueryString(event.target.value);

    clearSearchInput = () => this.props.setSearchQueryString('');

    goToSearchPage = event => {
        if (event) event.preventDefault();

        const {isSearchRouteActive, searchQueryString, history} = this.props;
        if (isSearchRouteActive
            || searchQueryString.length < minimumSearchQueryLength) return;

        history.push(resolveRoute(routePaths.MEDIA_SEARCH, {searchQueryString}));
    };

    createMarkup = content => ({__html: content});

    render() {
        const {mediaBucket, resolveMediaItemRoute} = this.props;
        const {isSearchRouteActive, searchQueryString, areSearchResultsValid} = this.props;
        const {isSearchOpened} = this.state;

        return (
            <div className={`vub-c-navigation-search ${isSearchOpened ? 'cr-is-switched-on' : ''}`}>
                <NavigationIconButton
                    icon={iconNavSearch}
                    className={`vub-c-navigation-icon-button--large ${isSearchOpened ? 'cr-is-switched-on' : ''}`}
                    onClick={this.toggleSearch}
                />
                <div className="vub-c-navigation-search__input-container" ref={this.searchContainer}>
                    <form
                        className="vub-c-navigation-search__form"
                        onSubmit={this.goToSearchPage}
                    >
                        <input
                            type="text"
                            className="vub-c-navigation-search__search-input"
                            placeholder="Search"
                            value={searchQueryString}
                            onChange={this.onSearchInputChange}
                            ref={this.searchInput}
                        />
                        {isSearchOpened && (
                            <Fragment>
                                {searchQueryString && (
                                    <NavigationIconButton
                                        icon={iconNavClear}
                                        className="vub-c-navigation-icon-button--small"
                                        onClick={this.clearSearchInput}
                                    />
                                )}
                                <NavigationIcon
                                    icon={iconInputSearch}
                                    className="vub-c-navigation-icon-button--medium"
                                />
                            </Fragment>
                        )}
                    </form>

                    {searchQueryString.length >= minimumSearchQueryLength
                    && areSearchResultsValid
                    && !isSearchRouteActive
                    && (
                        <div className="vub-c-navigation-search__drop-down-wrapper">
                            <div
                                className="vub-c-navigation-search__drop-down"
                            >
                                {mediaBucket && mediaBucket.isUpdatePending && (
                                    <div className="vub-c-navigation-search__spinner">
                                        <SpinnerStandard className="vub-c-standard-spinner--xsmall" />
                                    </div>
                                )}

                                {mediaBucket && !mediaBucket.isUpdatePending && mediaBucket.media.size === 0 && (
                                    <span className="vub-c-navigation-search__no-results">
                                        No media available for your search.
                                    </span>
                                )}

                                {mediaBucket && !mediaBucket.isUpdatePending && mediaBucket.media.size > 0 && (
                                    <ul className="vub-c-navigation-search__drop-down-menu">
                                        {mediaBucket.media
                                            .toArray()
                                            .slice(0, 5)
                                            .map(mediaItem => (
                                                <li
                                                    key={mediaItem.id}
                                                    className="vub-c-navigation-search__drop-down-item"
                                                >
                                                    <Link
                                                        className="vub-c-navigation-search__drop-down-link"
                                                        to={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                                    >
                                                        <div className="vub-navigation-search__tile">
                                                            <div className="vub-c-navigation-search__image-placeholder">
                                                                <img
                                                                    className="vub-navigation-search__image"
                                                                    src={`${mediaItem.imageBoxCover}&q=70&w=60`}
                                                                    alt="poster"
                                                                />
                                                            </div>
                                                            <div
                                                                className="vub-c-navigation-search__drop-down-item-content"
                                                            >
                                                                <div
                                                                    className="vub-c-navigation-search__drop-down-category"
                                                                >
                                                                    ({markSearchQueryString(
                                                                    mediaItem.toJS(),
                                                                    searchQueryString,
                                                                ).meta})
                                                                </div>
                                                                <div
                                                                    dangerouslySetInnerHTML={
                                                                        this.createMarkup(
                                                                            markSearchQueryString(
                                                                                mediaItem.toJS(),
                                                                                searchQueryString,
                                                                            ).node,
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                )}

                                {mediaBucket && !mediaBucket.isUpdatePending && mediaBucket.media.size > 0 && (
                                    <ShowMoreButton onClick={this.goToSearchPage}>Show all results</ShowMoreButton>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="vub-c-navigation-search__cancel" onClick={this.toggleSearch}>Cancel</div>
            </div>
        );
    }
}

export default NavigationSearch;
