import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    BEMClassNameResolver,
    BEMClassNameResolverPropTypes,
    BEMClassNameResolverDefaultProps,
} from '../../ui-kit/BEMClassNameResolver';
import './MediaCarouselSlidePortraitSeeMore.scss';
import CarouselSlide from '../../ui-kit/CarouselSlide';

@BEMClassNameResolver('vub-c-media-carousel-slide-portrait-see-more')
class MediaCarouselSlidePortraitSeeMore extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
        linkTo: PropTypes.object,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
        linkTo: {pathname: ''},
    };

    render() {
        const {resolveClassName, linkTo} = this.props;
        return (
            <CarouselSlide blockName={resolveClassName()}>

                <div className={resolveClassName('__content')}>
                    <div className={resolveClassName('__text-placeholder')}>
                        <div className={resolveClassName('__text')}>
                            <Link
                                className={resolveClassName('__text-link')}
                                to={linkTo}
                            >
                                see more
                            </Link>
                        </div>
                    </div>
                </div>

            </CarouselSlide>
        );
    }
}

export default MediaCarouselSlidePortraitSeeMore;
