import React from 'react';
import PropTypes from 'prop-types';
import './DownloadMobileAppPopUp.scss';
import LinkButton from './LinkButton';
import withPopUp from '../withPopUp';

// TODO - Move this to translation folder
const title = 'play media item';
const description1 = 'In order to play this media item you need to download our mobile app on your device.';
const description2 = 'Please select below:';

const MobilePopUpPlayContent = ({closePopUp, appURI, content}) => (
    <div className="vub-c-download-mobile-app-pop-up">
        <div className="vub-c-download-mobile-app-pop-up__header">
            <div className="vub-c-download-mobile-app-pop-up__title">{title}</div>
            <div className="vub-c-download-mobile-app-pop-up__close" onClick={closePopUp} />
        </div>
        <div className="vub-c-download-mobile-app-pop-up__body">
            <div className="vub-c-download-mobile-app-pop-up__description">
                <div>
                    {description1}
                </div>
                <div>
                    {description2}
                </div>
            </div>
            <div className="vub-c-download-mobile-app-pop-up__buttons">
                <LinkButton
                    target="_blank"
                    uri={appURI}
                >
                    {content}
                </LinkButton>
            </div>
        </div>
    </div>
);

MobilePopUpPlayContent.propTypes = {
    closePopUp: PropTypes.func,
    appURI: PropTypes.string,
    content: PropTypes.string,
};

MobilePopUpPlayContent.defaultProps = {
    closePopUp: null,
    appURI: null,
    content: null,
};

export default withPopUp(MobilePopUpPlayContent);
