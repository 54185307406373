import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import debounce from 'lodash.debounce';
import routePaths from '../routePaths';
import resolveRoute from '../navigation/resolveRoute';
import MediaSearchContext from './MediaSearchContext';
import * as selectors from './mediaSearchProviderSelectors';
import * as mediaActions from './mediaActions';
import defaultOrderByOptions from '../static-data/orderByOptions';

const orderByOptions = [
    ...defaultOrderByOptions,
    {
        id: 3,
        value: 'relevance',
        name: 'Relevance',
        query: null,
    },
];

const minimumSearchQueryLength = 3;

@withRouter
class MediaSearchProvider extends Component {
    static propTypes = {
        mediaBucket: PropTypes.object,
        mediaGenres: PropTypes.object.isRequired,
        isSearchRouteActive: PropTypes.bool.isRequired,
        searchQueryString: PropTypes.string,
        searchMedia: PropTypes.func.isRequired,
        history: PropTypes.object,
        location: PropTypes.object,
    };

    static defaultProps = {
        mediaBucket: null,
        searchQueryString: '',
        history: null,
        location: null,
    };

    constructor(props) {
        super(props);
        this.searchMediaForQueryString = debounce(this.searchMediaForQueryString, 450);
        this.setSearchRoute = debounce(this.setSearchRoute, 450);
    }

    state = {
        searchQueryString: this.props.searchQueryString,
        areSearchResultsValid: true,
        prevPropMediaBucket: null, // eslint-disable-line react/no-unused-state
        prevPropLocation: null, // eslint-disable-line react/no-unused-state
        orderByValue: 'relevance',
        filterByValue: 'reset',
    };

    static getDerivedStateFromProps(props, state) {
        const {mediaBucket, location} = props;
        if (mediaBucket !== state.prevPropMediaBucket) {
            return {
                ...state,
                areSearchResultsValid: true,
                prevPropMediaBucket: mediaBucket,
                ...(location !== state.prevPropLocation ? {
                    prevPropLocation: location,
                    orderByValue: 'relevance',
                    filterByValue: 'reset',
                } : {}),
            };
        }
        return null;
    }

    setSearchQueryString = (searchQueryString = '') => {
        this.setState({
            searchQueryString: searchQueryString,
            areSearchResultsValid: false,
        }, () => {
            const {isSearchRouteActive} = this.props;
            const {searchQueryString} = this.state;

            if (isSearchRouteActive && searchQueryString && searchQueryString.length >= minimumSearchQueryLength) {
                this.setSearchRoute();
            }

            if (!isSearchRouteActive && searchQueryString && searchQueryString.length >= minimumSearchQueryLength) {
                this.searchMediaForQueryString();
            }
        });
    };

    loadMoreResults = () => this.searchMediaForQueryString(true);

    setSearchRoute = () => {
        const {history} = this.props;
        const {searchQueryString} = this.state;

        history.replace(resolveRoute(routePaths.MEDIA_SEARCH, {searchQueryString}));
    };

    getWhereQuery = () => {
        const {mediaGenres} = this.props;
        const {filterByValue} = this.state;

        return [
            filterByValue
            && filterByValue !== 'reset'
            && mediaGenres.get(filterByValue)
            && `mediaGenreId:eq:${mediaGenres.get(filterByValue).name}`,
            `classification:in:${mediaItemClassification.MOVIE},${mediaItemClassification.TV_SHOW}`,
        ].filter(Boolean);
    };

    searchMediaForQueryString = (loadMoreResults = false) => {
        const {mediaBucket, searchMedia} = this.props;
        const {searchQueryString, orderByValue} = this.state;
        const orderByOption = orderByOptions.find(option => option.value === orderByValue);

        searchMedia({
            requestParams: {
                searchQueryString,
                where: this.getWhereQuery(),
                start: loadMoreResults ? mediaBucket.mediaIds.size : 0,
                ...(orderByOption && orderByOption.value !== 'relevance'
                    ? {sort: orderByOption.query} : {}),
                limit: 24,
            },
            mediaBucketKey: mediaBucket.key,
            invalidateCurrentSet: !loadMoreResults,
        });
    };

    setGenreFilterValue = value => {
        this.setState({
            filterByValue: value,
        }, () => {
            this.searchMediaForQueryString();
        });
    };

    setOrderByValue = value => {
        this.setState({
            orderByValue: value,
        }, () => {
            this.searchMediaForQueryString();
        });
    };

    render() {
        const {children, mediaBucket, mediaGenres, isSearchRouteActive} = this.props;
        const {searchQueryString, areSearchResultsValid, orderByValue, filterByValue} = this.state;

        return (
            <MediaSearchContext.Provider
                value={{
                    mediaBucket,
                    mediaGenres,
                    isSearchRouteActive,
                    searchQueryString,
                    setSearchQueryString: this.setSearchQueryString,
                    loadMoreResults: this.loadMoreResults,
                    setGenreFilterValue: this.setGenreFilterValue,
                    setOrderByValue: this.setOrderByValue,
                    areSearchResultsValid,
                    orderByValue,
                    filterByValue,
                }}
            >
                {children}
            </MediaSearchContext.Provider>
        );
    }
}

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaSearchViewBucketSelector();
    return (state, props) => ({
        mediaGenres: state.media.mediaGenres,
        mediaBucket: mediaBucketSelector(state, props),
        isSearchRouteActive: selectors.isSearchRouteActive(state),
        searchQueryString: selectors.getSearchQueryString(state),
    });
};

const mapDispatchToProps = dispatch => ({
    searchMedia: payload => dispatch(mediaActions.searchMedia(payload)),
});

export default connect(
    createMapStateToProps,
    mapDispatchToProps,
)(MediaSearchProvider);
