import React from 'react';
import './Footer.scss';
import LogoFooter from './LogoFooter';
import NavigationFooter from './NavigationFooter';
import Copyright from './Copyright';

const Footer = () => (
    <div className="vub-c-footer">
        <LogoFooter />
        <NavigationFooter />
        <Copyright />
    </div>
);

export default Footer;
