import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './MediaItemViewSynopsis.scss';

export default class MediaItemViewSynopsis extends PureComponent {
    static propTypes = {
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        mediaItem: null,
    };

    render() {
        const {mediaItem} = this.props;

        return (
            <div className="vub-c-media-item-view-synopsis">
                <h3 className="vub-c-media-item-view-synopsis__title">
                    Overview
                </h3>
                <p className="vub-c-media-item-view-synopsis__text">
                    {mediaItem.synopsisLong}
                </p>
            </div>
        );
    }
}
