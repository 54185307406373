import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './MediaCarouselStandard.scss';
import Carousel, {CarouselSlideTray, CarouselControls, CarouselPagination} from '../../ui-kit/Carousel';

const MediaCarouselStandard = ({title, subtitle, linkTo, children, hasPagination}) => (
    <div className="vub-c-media-carousel-standard-row">
        <div className="vub-c-media-carousel-standard-row__header">
            <div className="vub-c-media-carousel-standard-row__title">
                {linkTo ? (
                    <Link
                        className="vub-c-media-carousel-standard-row__title-link"
                        to={linkTo}
                    >
                        {title}
                    </Link>
                ) : title}
            </div>
            <div className="vub-c-media-carousel-standard-row__subtitle">
                {linkTo ? (
                    <Link
                        className="vub-c-media-carousel-standard-row__title-link"
                        to={linkTo}
                    >
                        {subtitle}
                    </Link>
                ) : subtitle}
            </div>
        </div>
        <Carousel
            blockName="vub-c-media-carousel-standard"
            className={hasPagination ? 'vub-c-media-carousel-standard--with-pagination' : ''}
        >
            <CarouselPagination />
            <CarouselSlideTray>
                {children}
            </CarouselSlideTray>
            <CarouselControls />
        </Carousel>
    </div>
);

MediaCarouselStandard.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    linkTo: PropTypes.object, // TODO conditional rendering of Link component & react-router location prop-type
    hasPagination: PropTypes.bool, // TODO hasPagination is temporary field until content rows approach is clear
};

MediaCarouselStandard.defaultProps = {
    title: null,
    subtitle: null,
    linkTo: null,
    hasPagination: false,
};

export default MediaCarouselStandard;
