import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
    withMediaItemPopover,
    withMediaItemPopoverPropTypes,
    withMediaItemPopoverDefaultProps,
} from '../media-item-popover/withMediaItemPopover';
import './MediaGalleryTilePortraitExtendable.scss';

@withMediaItemPopover
class MediaGalleryTilePortraitExtendable extends Component {
    static propTypes = {
        ...withMediaItemPopoverPropTypes,
        mediaItem: PropTypes.object,
        linkTo: PropTypes.object,
        additionalContent: PropTypes.node,
        title: PropTypes.string,
    };

    static defaultProps = {
        ...withMediaItemPopoverDefaultProps,
        mediaItem: null,
        linkTo: {pathname: ''},
        additionalContent: null,
        title: null,
    };

    componentWillUnmount() {
        this.props.toggleMediaItemPopover(false);
    }

    onMouseEnter = () => this.props.toggleMediaItemPopover(true, this.props.mediaItem);

    onMouseOut = () => this.props.toggleMediaItemPopover(false);

    createMarkup = content => ({__html: content});

    render() {
        const {mediaItem, popoverCallerRef, linkTo, additionalContent, title} = this.props;
        const imageUrl = mediaItem.imageBoxCover ? `${mediaItem.imageBoxCover}&q=70&w=200` : null;

        return (
            <div className="vub-c-media-gallery-tile-portrait-extendable">
                <div
                    className="vub-c-media-gallery-tile-portrait-extendable__content"
                    onMouseEnter={this.onMouseEnter}
                    onMouseOut={this.onMouseOut}
                    ref={popoverCallerRef}
                >
                    <div className="vub-c-media-gallery-tile-portrait-extendable__image-placeholder">
                        {mediaItem ? (
                            <Link
                                className="vub-c-media-gallery-tile-portrait-extendable__link"
                                to={linkTo}
                            >
                                <img
                                    className="vub-c-media-gallery-tile-portrait-extendable__image"
                                    src={imageUrl}
                                    alt="box cover"
                                />
                            </Link>
                        ) : null}
                    </div>
                </div>
                <div className="vub-c-media-gallery-tile-portrait-extendable__title">({title})</div>
                <div
                    className="vub-c-media-gallery-tile-portrait-extendable__content-extended"
                    dangerouslySetInnerHTML={this.createMarkup(additionalContent)}
                />
            </div>
        );
    }
}

export default MediaGalleryTilePortraitExtendable;
