import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
    BEMClassNameResolver,
    BEMClassNameResolverPropTypes,
    BEMClassNameResolverDefaultProps,
} from '../../ui-kit/BEMClassNameResolver';
import {
    withMediaItemPopover,
    withMediaItemPopoverPropTypes,
    withMediaItemPopoverDefaultProps,
} from '../media-item-popover/withMediaItemPopover';
import './MediaCarouselSlideDouble.scss';
import CarouselSlide from '../../ui-kit/CarouselSlide';

const SingleMediaItemTile = withMediaItemPopover(props => {
    const {mediaItem, linkTo, resolveClassName} = props;
    const {popoverCallerRef, toggleMediaItemPopover} = props;

    const imageUrl = mediaItem && mediaItem.imageThumbnail ? `${mediaItem.imageThumbnail}&q=70&w=300` : null;
    const onMouseEnter = () => toggleMediaItemPopover(true, mediaItem);
    const onMouseOut = () => toggleMediaItemPopover(false);

    return (
        <div
            className={resolveClassName('__media-item')}
            onMouseEnter={onMouseEnter}
            onMouseOut={onMouseOut}
            ref={popoverCallerRef}
        >
            <div className={resolveClassName('__image-placeholder')}>
                {mediaItem ? (
                    <Link
                        className="vub-c-media-gallery-tile-portrait__link"
                        to={linkTo}
                    >
                        <img
                            className={resolveClassName('__image')}
                            src={imageUrl}
                            alt="thumbnail"
                        />
                    </Link>
                ) : null}
            </div>
        </div>
    );
});

SingleMediaItemTile.propTypes = {
    ...withMediaItemPopoverPropTypes,
    mediaItem: PropTypes.object,
    linkTo: PropTypes.object,
    resolveClassName: PropTypes.func.isRequired,
};

SingleMediaItemTile.defaultProps = {
    ...withMediaItemPopoverDefaultProps,
    mediaItem: null,
    linkTo: {pathname: ''},
};

@BEMClassNameResolver('vub-c-media-carousel-slide-double')
class MediaCarouselSlideDouble extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
        mediaItemA: PropTypes.object,
        linkMediaItemATo: PropTypes.object,
        mediaItemB: PropTypes.object,
        linkMediaItemBTo: PropTypes.object,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
        mediaItemA: null,
        linkMediaItemATo: {pathname: ''},
        mediaItemB: null,
        linkMediaItemBTo: {pathname: ''},
    };

    render() {
        const {resolveClassName} = this.props;
        const {mediaItemA, linkMediaItemATo, mediaItemB, linkMediaItemBTo} = this.props;

        return (
            <CarouselSlide blockName={resolveClassName()}>
                <div className={resolveClassName('__content')}>
                    <SingleMediaItemTile
                        mediaItem={mediaItemA}
                        linkTo={linkMediaItemATo}
                        resolveClassName={resolveClassName}
                    />
                    <SingleMediaItemTile
                        mediaItem={mediaItemB}
                        linkTo={linkMediaItemBTo}
                        resolveClassName={resolveClassName}
                    />
                </div>

            </CarouselSlide>
        );
    }
}

export default MediaCarouselSlideDouble;
