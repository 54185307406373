import React from 'react';
import config from '../../config';
import './Copyright.scss';

const Copyright = () => (
    <div className="vub-c-copyright">
        <div className="vub-c-copyright__description">
            <span className="vub-c-copyright__description-span">
                &#169; 2018 PlayVU
            </span>
            <span className="vub-c-copyright__description-span">
                All rights reserved.
            </span>
        </div>
        <div className="vub-c-copyright__version">
            Version: {config.VERSION} build: {config.BUILD}
        </div>
    </div>
);

export default Copyright;
