import {put} from 'redux-saga/effects';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import * as actionTypes from '../media/mediaActionTypes';

export const loadMediaForMediaClassificationView = function* loadMediaForMediaClassificationView({payload}) {
    const {classification} = payload;
    const mediaBucketKey = `MEDIA_CLASSIFICATION_VIEW['${classification}']`;
    const mapClassification = {
        'movies': mediaItemClassification.MOVIE,
        'tv-shows': mediaItemClassification.TV_SHOW,
    };
    const getClassificationQuery = classification => mapClassification[classification];
    const getWhereQuery = query => (
        query ? [`classification:in:${query}`] : []
    );

    yield put({
        type: actionTypes.FETCH_MEDIA_COLLECTION_MEDIA,
        payload: {
            requestParams: {
                mediaCollectionId: null,
                where: getWhereQuery(getClassificationQuery(classification)),
                sort: [
                    'titleLong:ASC',
                ],
                limit: 24,
            },
            mediaBucketKey,
            invalidateCurrentSet: true,
        },
    });
};
