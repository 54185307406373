/**
 * Formats basketItemDTO
 */
export const formatBasketItemDTO = function formatBasketItemDTO(basketItemDTO) {
    return {
        id: basketItemDTO.BasketItemId,
        offerId: basketItemDTO.OfferId,
        mediaItemId: basketItemDTO.SupplierRef,
        price: basketItemDTO.Price,
        message: basketItemDTO.Message,
    };
};

/**
 * Formats entitlementDTO
 */
export const formatEntitlementDTO = function formatEntitlementDTO(entitlementDTO) {
    return {
        id: entitlementDTO.Id,
        purchaseOrderId: entitlementDTO.PurchaseOrderId,
        offerId: entitlementDTO.OfferId,
        mediaItemId: entitlementDTO.SupplierRef,
        rentalExpiryTime: entitlementDTO.RentalExpiryTime,
        temp: entitlementDTO, // TODO remove when finished
    };
};
