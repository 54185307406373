import moment from 'moment';

/**
 * Delay result
 */
export const delayResult = (result, timeout = 1200) => new Promise(resolve => {
    setTimeout(() => resolve(result), timeout);
});

/**
 * Formats basketItemDTO
 */
export const createBasketItemDTO = function createBasketItemDTO(offer) {
    return {
        id: offer.id,
        offerId: offer.id,
        mediaItemId: offer.mediaItemId,
        price: offer.price,
        message: '',
    };
};

/**
 * Formats entitlementDTO
 */
export const createEntitlementDTO = function createEntitlementDTO({offer, accountId, purchaseOrderId}) {
    purchaseOrderId = purchaseOrderId || moment().unix();

    return {
        id: `${offer.id}_${purchaseOrderId}`,
        purchaseOrderId: purchaseOrderId,
        offerId: offer.id,
        mediaItemId: offer.mediaItemId,
        rentalExpiryTime: moment.unix(purchaseOrderId).add(30, 'days').toISOString(),
    };
};
