import Immutable from 'immutable';

/**
 * MediaGenre definition
 *
 * @typedef {Immutable.Record} MediaGenre
 * @property {?string} id
 * @property {?string} name
 * @property {?string} slug
 * @property {?string} searchQuery
 * @property {?number} movieCount
 * @property {?number} tvShowCount
 * @property {?number} seasonCount
 * @property {?number} episodeCount
 */

/**
 * MediaGenre record
 *
 * @type {MediaGenre}
 */
class MediaGenre extends Immutable.Record({
    id: null,
    name: null,
    slug: null,
    searchQuery: null,
    movieCount: null,
    tvShowCount: null,
    seasonCount: null,
    episodeCount: null,
}) {}

export default MediaGenre;
