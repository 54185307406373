import React, {PureComponent} from 'react';
import Slider from 'rc-slider';
import CTAButton from '../../ui-elements/CTAButton';
import './SliderContainer.scss';

class SliderContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: 30,
        };
    }

    onSliderChange = value => {
        this.setState({
            value,
        });
    };

    onAfterChange = value => {
        console.log(value); //eslint-disable-line
    };

    reset = () => {
        console.log('reset value') // eslint-disable-line
        this.setState({value: null});
    };

    render() {
        return (
            <div className="vub-c-slider-container">
                <div className="vub-c-slider-container__title">Progress bars</div>
                <CTAButton onClick={this.reset}>Reset</CTAButton>
                <Slider
                    value={this.state.value}
                    onChange={this.onSliderChange}
                    onAfterChange={this.onAfterChange}
                />
            </div>
        );
    }
}

export default SliderContainer;
