import React, {PureComponent} from 'react';
import OverlayContext from './OverlayContext';

const withOverlay = BaseComponent => (
    class ComposeComponent extends PureComponent {
        static propTypes = {
            ...BaseComponent.propTypes,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
        };

        render() {
            return (
                <OverlayContext.Consumer>
                    {context => {
                        const props = {
                            ...this.props,
                            ...context,
                        };
                        return <BaseComponent {...props} />;
                    }}
                </OverlayContext.Consumer>
            );
        }
    }
);

export default withOverlay;
