import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MobileDetect from 'mobile-detect';
import config from '../config';
import * as mediaEntitlementActions from './mediaEntitlementActions';
import * as authActions from '../auth/authActions';
import BaseElement from '../ui-kit/BaseElement';
import withPopUp from '../ui-elements/pop-up/withPopUp';
import DownloadMobileAppPopUp from '../ui-elements/pop-up/pop-up-content/DownloadMobileAppPopUp';
import DisablePlaybackContent from '../ui-elements/pop-up/pop-up-content/DisablePlaybackContent';

// TODO - move to config
const GOOGLE_PLAY_STORE_APP_URL = '';
const APPLE_STORE_APP_URL = '';

const withMediaCTA = BaseComponent => {
    @withPopUp
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
            isUserSignedIn: PropTypes.bool,
            userAccount: PropTypes.object,
            isFastCheckoutInProgress: PropTypes.bool,
            basket: PropTypes.object,
            currentEntitlementsByMediaItem: PropTypes.object,
            signInUser: PropTypes.func.isRequired,
            fastCheckoutMediaItemOffer: PropTypes.func.isRequired,
            startMediaItemPreviewPlayback: PropTypes.func.isRequired,
            startMediaItemOfferPlayback: PropTypes.func.isRequired,
            setPopUp: PropTypes.func,
            closePopUp: PropTypes.func,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
            isUserSignedIn: false,
            userAccount: null,
            setPopUp: null,
            closePopUp: null,
        };

        fastCheckoutMediaItemOffer = offer => {
            const {fastCheckoutMediaItemOffer, isUserSignedIn} = this.props;
            if (!isUserSignedIn || !offer) return;

            fastCheckoutMediaItemOffer({offer});
        };

        startMediaItemOfferPlayback = offer => {
            const {startMediaItemOfferPlayback, isUserSignedIn} = this.props;
            const {closePopUp, setPopUp} = this.props;
            if ((offer.termsType !== 'Preview' && !isUserSignedIn) || !offer) return;

            // disable playback it is defined in config
            if (offer.termsType !== 'Preview' && config.TEMP_DISABLE_PLAYBACK) {
                setPopUp(
                    <DisablePlaybackContent closePopUp={closePopUp} />
                );
                return;
            }

            // show pop up with native app download option if user on mobile
            const md = new MobileDetect(window.navigator.userAgent);
            if (offer.termsType !== 'Preview'
                && (md.os() === 'AndroidOS' || md.os() === 'iOS')) {
                const appURI = md.os() === 'AndroidOS' ? GOOGLE_PLAY_STORE_APP_URL : APPLE_STORE_APP_URL;
                setPopUp(
                    <DownloadMobileAppPopUp
                        closePopUp={closePopUp}
                        appURI={appURI}
                        content={md.os() === 'AndroidOS' ? 'Android' : 'IOS'}
                    />
                );
                return;
            }

            startMediaItemOfferPlayback({offer});
        };

        render() {
            return (
                <BaseComponent
                    {...this.props}
                    fastCheckoutMediaItemOffer={this.fastCheckoutMediaItemOffer}
                    startMediaItemOfferPlayback={this.startMediaItemOfferPlayback}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        isUserSignedIn: state.auth.isUserSignedIn,
        userAccount: state.auth.userAccount,
        isFastCheckoutInProgress: state.mediaEntitlement.isFastCheckoutInProgress,
        basket: state.mediaEntitlement.basket,
        currentEntitlementsByMediaItem: state.mediaEntitlement.currentEntitlementsByMediaItem,
    });

    const mapDispatchToProps = dispatch => ({
        signInUser: payload => dispatch(
            authActions.signInUser(payload),
        ),
        fastCheckoutMediaItemOffer: payload => dispatch(
            mediaEntitlementActions.fastCheckoutMediaItemOffer(payload),
        ),
        startMediaItemPreviewPlayback: payload => dispatch(
            mediaEntitlementActions.startMediaItemPreviewPlayback(payload),
        ),
        startMediaItemOfferPlayback: payload => dispatch(
            mediaEntitlementActions.startMediaItemOfferPlayback(payload)
        ),
        startMediaItemSVODOfferPlayback: payload => dispatch(
            mediaEntitlementActions.startMediaItemSVODOfferPlayback(payload)
        ),
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ComposedComponent);
};

const {withMediaCTAPropTypes, withMediaCTADefaultProps} = withMediaCTA(BaseElement);

export {
    withMediaCTA,
    withMediaCTAPropTypes,
    withMediaCTADefaultProps,
};
