import React, {PureComponent} from 'react';
import './StandardIcon.scss';
import Icon from '../ui-kit/Icon';

class StandardIcon extends PureComponent {
    static propTypes = {
        ...Icon.propTypes,
    };

    static defaultProps = {
        ...Icon.defaultProps,
    };

    render() {
        return (
            <Icon {...this.props} blockName="vub-c-icon" />
        );
    }
}

export default StandardIcon;
