import moment from 'moment';

/**
 * Formats user account & auth token response
 */
export const formatAccountResponse = function formatAccountResponse(params) {
    const {
        userAccountDTO,
        accountEntitlements = [],
        accountPaymentMethods = [],
        authToken,
    } = params;

    return {
        userAccount: {
            id: userAccountDTO.id,
            email: userAccountDTO.emailAddress,
            termsAndConditionsAccepted: true,
            name: userAccountDTO.name || null,
            hasOptedInToReceiveUpdates: false,
            subscriptions: (accountEntitlements && accountEntitlements.length > 0 && accountEntitlements)
                || (userAccountDTO.entitlements
                && userAccountDTO.entitlements.length > 0
                && userAccountDTO.entitlements)
                || [],
            paymentMethods: (accountPaymentMethods && accountPaymentMethods.length > 0 && accountPaymentMethods)
                || (userAccountDTO.paymentMethods
                && userAccountDTO.paymentMethods.length > 0
                && userAccountDTO.paymentMethods)
                || [],
        },
        ...(authToken ? {authToken} : {}),
    };
};

/**
 * Formats FFAPI auth token
 */
export const formatFFAPIAuthTokenObject = function formatFFAPIAuthTokenObject({validateIdentityResultDTO}) {
    return {
        expiration: moment().add(24, 'h').format(),
        token: btoa(validateIdentityResultDTO.UniqueIdentifier + ':' + validateIdentityResultDTO.Token),
    };
};
