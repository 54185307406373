import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import {
    BEMClassNameResolver,
    BEMClassNameResolverPropTypes,
    BEMClassNameResolverDefaultProps,
} from '../../ui-kit/BEMClassNameResolver';
import './MediaCarouselSlideCTADetailed.scss';
import CarouselSlide from '../../ui-kit/CarouselSlide';
import CTAIconButton from '../CTAIconButton';
import {
    iconPlaySolid,
    iconMore,
} from '../icons';

@BEMClassNameResolver('vub-c-media-carousel-slide-cta-detailed')
class MediaCarouselSlideCTADetailed extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
        mediaItem: PropTypes.object,
        linkTo: PropTypes.object,
        onPlayIconClick: PropTypes.func,
        isUserSignedIn: PropTypes.bool,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
        mediaItem: null,
        linkTo: {pathname: ''},
        onPlayIconClick: null,
        isUserSignedIn: null,
    };

    handleMoreClick = linkTo => {
        this.props.history.push(linkTo);
    };

    render() {
        const {resolveClassName, linkTo, onPlayIconClick, isUserSignedIn} = this.props;
        const {mediaItem} = this.props;
        const imageUrl = mediaItem.imageThumbnail ? `${mediaItem.imageThumbnail}&q=70&w=400` : null;

        let mediaItemTitle = mediaItem ? mediaItem.title : null;
        if (mediaItem && mediaItem.classification === mediaItemClassification.EPISODE) {
            mediaItemTitle = `${mediaItem.siblingIndex}. ${mediaItem.title}`;
        }

        return (
            <CarouselSlide blockName={resolveClassName()}>
                {mediaItem ? (
                    <div
                        className={resolveClassName('__content')}
                    >
                        <div className={resolveClassName('__image-placeholder')}>
                            <Link
                                className="vub-c-media-gallery-tile-portrait__link"
                                to={linkTo}
                            >
                                <img
                                    className={resolveClassName('__image')}
                                    src={imageUrl}
                                    alt="box cover"
                                />
                            </Link>
                        </div>
                        <div className={resolveClassName('__description')}>
                            <h3 className={resolveClassName('__description-title')}>{mediaItemTitle}</h3>
                            <h3 className={resolveClassName('__description-duration')}>{mediaItem.durationMinutes}</h3>
                            <p className={resolveClassName('__description-text')}>
                                {mediaItem.synopsis}
                            </p>
                        </div>
                        <div className={resolveClassName('__buttons')}>
                            {isUserSignedIn && (
                                <CTAIconButton
                                    className="vub-c-cta-icon-button--primary"
                                    icon={iconPlaySolid}
                                    label="play"
                                    type="large"
                                    onClick={onPlayIconClick}
                                />
                            )}
                            <CTAIconButton
                                className="vub-c-cta-icon-button--secondary"
                                icon={iconMore}
                                label="More"
                                type="large"
                                onClick={() => this.handleMoreClick(linkTo)}
                            />
                        </div>
                    </div>
                ) : null}
            </CarouselSlide>
        );
    }
}

export default MediaCarouselSlideCTADetailed;
