import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setDisplayName, wrapDisplayName} from 'recompose';
import MediaSearchContext from './MediaSearchContext';

export const withMediaSearch = BaseComponent => {
    @setDisplayName(wrapDisplayName(BaseComponent, 'withMediaSearch'))
    class ComposedComponent extends Component {
        static propTypes = {
            ...BaseComponent.propTypes,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
        };

        render() {
            return (
                <MediaSearchContext.Consumer>
                    {context => {
                        const {mediaBucket, mediaGenres, areSearchResultsValid, isSearchRouteActive} = context;
                        const {searchQueryString, setSearchQueryString, loadMoreResults} = context;
                        const {setGenreFilterValue, setOrderByValue, orderByValue, filterByValue} = context;

                        return (
                            <BaseComponent
                                {...this.props}
                                mediaBucket={mediaBucket}
                                mediaGenres={mediaGenres}
                                isSearchRouteActive={isSearchRouteActive}
                                searchQueryString={searchQueryString}
                                setSearchQueryString={setSearchQueryString}
                                loadMoreResults={loadMoreResults}
                                setGenreFilterValue={setGenreFilterValue}
                                setOrderByValue={setOrderByValue}
                                areSearchResultsValid={areSearchResultsValid}
                                orderByValue={orderByValue}
                                filterByValue={filterByValue}
                            />
                        );
                    }}
                </MediaSearchContext.Consumer>
            );
        }
    }

    return ComposedComponent;
};

export const withMediaSearchPropTypes = {
    mediaBucket: PropTypes.object,
    mediaGenres: PropTypes.object,
    isSearchRouteActive: PropTypes.bool,
    searchQueryString: PropTypes.string.isRequired,
    setSearchQueryString: PropTypes.func.isRequired,
    loadMoreResults: PropTypes.func.isRequired,
    setGenreFilterValue: PropTypes.func.isRequired,
    setOrderByValue: PropTypes.func.isRequired,
    areSearchResultsValid: PropTypes.bool,
    orderByValue: PropTypes.func,
    filterByValue: PropTypes.func,
};

export const withMediaSearchDefaultProps = {
    mediaBucket: null,
    mediaGenres: null,
    isSearchRouteActive: false,
    searchQueryString: '',
    setSearchQueryString: () => {},
    searchMedia: () => {},
    loadMoreResults: () => {},
    setGenreFilterValue: () => {},
    setOrderByValue: () => {},
    areSearchResultsValid: true,
    orderByValue: null,
    filterByValue: null,
};
