import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {withMediaCTA, withMediaCTAPropTypes, withMediaCTADefaultProps} from '../media-entitlement/withMediaCTA';
import './MediaItemPreviewButtonView.scss';

@withRouter
@withMediaCTA
class MediaItemPreviewButtonView extends Component {
    static propTypes = {
        ...withMediaCTAPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaCTADefaultProps,
        mediaItem: null,
    };

    render() {
        const {mediaItem, startMediaItemOfferPlayback} = this.props;
        const playPreview = e => {
            e.stopPropagation();
            startMediaItemOfferPlayback(mediaItem.preview);
        };

        return mediaItem && mediaItem.preview ? (
            <div
                className="vub-c-media-item-preview-button-view"
                onClick={playPreview}
            >
                <span className="vub-c-media-item-preview-button-view__logo" />
                <span className="vub-c-media-item-preview-button-view__descriptor">
                    trailer
                </span>
            </div>
        ) : null;
    }
}

export default MediaItemPreviewButtonView;
