/**
 * FFAPI resource endpoints
 */
export default {
    // GET /api/Devices/{affiliateId}/Get
    DEVICES_GET: '/api/Devices/%s/Get',

    // POST /api/Devices/{affiliateId}/Register
    DEVICES_REGISTER: '/api/Devices/%s/Register',

    // PUT /api/Devices/{affiliateId}/Unregister/{deviceId}
    DEVICES_UNREGISTER: '/api/Devices/%s/Unregister/%s',

    // PUT /api/Devices/{affiliateId}/ChangeName/{deviceId}
    DEVICES_CHANGE_NAME: '/api/Devices/%s/ChangeName/%s',

    // DELETE/GET/POST /api/Basket/{affiliateId}
    BASKET: '/api/Basket/%s',

    // POST /api/Purchase/{affiliateId}/DEBUG/GetToken
    PURCHASE_GET_DEBUG_PAYMENT_TOKEN: '/api/Purchase/%s/DEBUG/GetToken',

    // POST /api/Purchase/{affiliateId}/ChargeCustomerBasket
    PURCHASE_CHARGE_CUSTOMER_BASKET: '/api/Purchase/%s/ChargeCustomerBasket',

    // POST /api/Purchase/{affiliateId}/PurchaseOrderItem/SVOD/{offerId}
    PURCHASE_ORDER_ITEM_SVOD: '/api/Purchase/%s/PurchaseOrderItem/SVOD/%s',

    // GET /api/Entitlement/Current/{affiliateId}
    ENTITLEMENTS_CURRENT: '/api/Entitlement/Current/%s',
};
