import React from 'react';
import PropTypes from 'prop-types';
import MediaHeroBannerPreview from '../../ui-elements/media-hero-banner-preview/MediaHeroBannerPreview';
import {
    withLayoutControls,
    withLayoutControlsPropTypes,
    withLayoutControlsDefaultProps,
} from '../../layout/withLayoutControls';

const MediaHeroBannerPreviewRow = ({storefrontRow, setHeaderTransparency, isPlaybackEnabled}) => {
    if (!storefrontRow) return null;

    const {mediaItem, mediaItemPreviews} = storefrontRow.toJS().data;
    const {playbackDelay} = storefrontRow.templateConfiguration;
    return mediaItem && mediaItemPreviews ? (
        <MediaHeroBannerPreview
            mediaItem={mediaItem}
            mediaItemPreviews={mediaItemPreviews}
            playbackDelay={playbackDelay}
            setHeaderTransparency={setHeaderTransparency}
            isWithImage={true}
            isPlaybackEnabled={isPlaybackEnabled}
        />
    ) : null;
};

MediaHeroBannerPreviewRow.propTypes = {
    ...withLayoutControlsPropTypes,
    storefrontRow: PropTypes.object,
    isPlaybackEnabled: PropTypes.bool,
};

MediaHeroBannerPreviewRow.defaultProps = {
    ...withLayoutControlsDefaultProps,
    storefrontRow: null,
    isPlaybackEnabled: true,
};

export default withLayoutControls(MediaHeroBannerPreviewRow);
