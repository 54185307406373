import * as ccIdentityAPIResourceMethods from './cc-identity-api/resourceMethods';
import * as ccAPIResourceMethods from './cc-api/resourceMethods';
import * as ffAPIResourceMethods from './ff-api/resourceMethods';
import * as responseHelpers from './responseHelpers';

/**
 * Injects service parameters into API resource method
 */
const injectServiceParameters = (apiResourceMethod, serviceParameters) => params => apiResourceMethod({
    ...serviceParameters,
    ...params,
});

/**
 * Vubiquity WLS media entitlement provider for FilmFlex API
 */
export default class MediaEntitlementProviderFilmFlex {
    /**
     * @param {Object} serviceParameters
     * @returns {this}
     */
    constructor(serviceParameters = {}) {
        this.authToken = {token: null};
        this.ccIdentityToken = null; // TODO token saving and validation

        this.serviceParameters = serviceParameters;

        /**
         * @type {Object}
         * @property {function} getCCIdentityToken
         * @property {function} resolvePlaybackSource
         * @property {function} getBasketItems
         * @property {function} addMediaItemOfferToBasket
         * @property {function} removeBasketItem
         * @property {function} removeAllBasketItems
         * @property {function} getDebugPaymentToken
         * @property {function} chargeBasketWithTemporaryCardToken
         * @property {function} createPurchaseOrderItemSVOD
         * @property {function} getCurrentEntitlements
         */
        this.resources = [
            ccIdentityAPIResourceMethods,
            ccAPIResourceMethods,
            ffAPIResourceMethods,
        ].reduce((resources, apiResourceMethods) => {
            const apiResources = Object.keys(apiResourceMethods).reduce((apiResources, methodKey) => {
                apiResources[methodKey] = injectServiceParameters(
                    apiResourceMethods[methodKey],
                    {
                        ...serviceParameters,
                        authToken: this.authToken,
                    },
                );
                return apiResources;
            }, {});

            return {
                ...resources,
                ...apiResources,
            };
        }, {});
    }

    setAuthToken(authToken) {
        if (!authToken) {
            this.authToken = null;
            return;
        }

        this.authToken.token = authToken.token;
        this.authToken.expiration = authToken.expiration;
    }

    checkoutSingleMediaItem(params) {
        const resources = this.resources;

        return resources.removeAllBasketItems(params)
            .then(() => {
                return resources.addMediaItemOfferToBasket(params)
                    .then(basketItemDTOs => basketItemDTOs
                        .find(basketItemDTO => basketItemDTO.OfferId === params.offerId))
                    .then(responseHelpers.formatBasketItemDTO);
            });
    }

    chargeBasket(params) {
        const resources = this.resources;

        return resources.getDebugPaymentToken(params)
            .then(temporaryCardTokenDTO => {
                return resources.chargeBasketWithTemporaryCardToken({
                    ...params,
                    temporaryCardToken: temporaryCardTokenDTO.TokenId,
                }).then(chargeBasketResultDTO => {
                    return resources.getCurrentEntitlements(params)
                        .then(entitlementDTOs => entitlementDTOs.map(responseHelpers.formatEntitlementDTO))
                        .then(entitlementDTOs => entitlementDTOs.filter(entitlementDTO => {
                            return entitlementDTO.purchaseOrderId === chargeBasketResultDTO.PurchaseOrderId;
                        }));
                });
            });
    }

    createPurchaseOrderItemSVOD(params) {
        const resources = this.resources;

        return resources.createPurchaseOrderItemSVOD(params);
    }

    getCurrentEntitlements(params) {
        const resources = this.resources;

        return resources.getCurrentEntitlements(params)
            .then(entitlementDTOs => entitlementDTOs.map(responseHelpers.formatEntitlementDTO))
            .then(entitlementDTOs => entitlementDTOs.sort((entitlementDTO1, entitlementDTO2) => {
                return entitlementDTO1.purchaseOrderId - entitlementDTO2.purchaseOrderId;
            }));
    }

    getMediaItemPlaybackSource(params) {
        const resources = this.resources;

        return resources.getCCIdentityToken(params)
            .then(ccIdentityTokenDTO => {
                return resources.resolvePlaybackSource({
                    assetId: params.assetId,
                    assetOfferId: params.assetOfferId,
                    ccIdentityToken: ccIdentityTokenDTO.access_token,
                })
                    .then(playbackSourceDTO => ({
                        url: playbackSourceDTO.Url,
                        type: 'application/vnd.ms-sstr+xml',
                        protectionInfo: playbackSourceDTO.ProtectionInfo,
                    }));
            });
    }
}
