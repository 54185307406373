import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import navigationReducer from './navigation/navigationReducer';
import mediaReducer from './media/mediaReducer';
import mediaEntitlementReducer from './media-entitlement/mediaEntitlementReducer';
import authReducer from './auth/authReducer';

// combine application reducers
const createRootReducer = history => combineReducers({
    router: connectRouter(history),
    navigation: navigationReducer,
    media: mediaReducer,
    mediaEntitlement: mediaEntitlementReducer,
    auth: authReducer,
});

export default createRootReducer;
