import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import {getMediaItem, getMediaItemChildren} from '../../media/mediaBucketHelpers';
import './MediaItemViewTitle.scss';

export default class MediaItemViewTitle extends PureComponent {
    static propTypes = {
        mediaItem: PropTypes.object.isRequired,
        mediaBucket: PropTypes.object.isRequired,
    };

    render() {
        const {mediaItem, mediaBucket} = this.props;
        let hasHDOffers = false;
        let mediaItemTitle = mediaItem.title;

        if (mediaItem.classification === mediaItemClassification.SEASON) {
            const mediaItemParent = getMediaItem(mediaBucket, mediaItem.parentId);
            mediaItemTitle = `${mediaItemParent.title} ${mediaItem.title}`;
        }

        if (mediaItem.classification === mediaItemClassification.EPISODE) {
            mediaItemTitle = `Episode ${mediaItem.siblingIndex} - ${mediaItem.title}`;
        }

        let tvShowMediaItemSeasons;
        if (mediaItem.classification === mediaItemClassification.TV_SHOW) {
            tvShowMediaItemSeasons = getMediaItemChildren(mediaBucket, mediaItem);
            const allTVSHowOffers = tvShowMediaItemSeasons
                .reduce((allTVSHowOffers, tvShowMediaItemSeason) => {
                    const seasonMediaItemEpisodes = getMediaItemChildren(mediaBucket, tvShowMediaItemSeason);
                    return [
                        ...allTVSHowOffers,
                        ...(seasonMediaItemEpisodes
                            .reduce((allSeasonOffers, mediaItem) => [...allSeasonOffers, ...mediaItem.offers], [])),
                    ];
                }, []);
            hasHDOffers = !!allTVSHowOffers.find(offer => offer.resolution === '1080p');
        }

        if (mediaItem.classification === mediaItemClassification.SEASON) {
            const seasonMediaItemEpisodes = getMediaItemChildren(mediaBucket, mediaItem);
            const allSeasonOffers = seasonMediaItemEpisodes
                .reduce((allSeasonOffers, mediaItem) => [...allSeasonOffers, ...mediaItem.offers], []);
            hasHDOffers = !!allSeasonOffers.find(offer => offer.resolution === '1080p');
        }

        if (mediaItem.classification === mediaItemClassification.EPISODE
            || mediaItem.classification === mediaItemClassification.MOVIE) {
            hasHDOffers = mediaItem && mediaItem.offers.find(offer => offer.resolution === '1080p');
        }

        const formatTag = hasHDOffers ? (
            <sup className="vub-c-media-item-view-title__format-hd">HD</sup>
        ) : null;

        return (
            <div className="vub-c-media-item-view-title">
                {mediaItemTitle}&nbsp;{formatTag}
                {mediaItem.classification === mediaItemClassification.TV_SHOW ? (
                    <p className="vub-c-media-item-view-title__season-information">
                        {tvShowMediaItemSeasons.size > 1
                            ? `Season ${tvShowMediaItemSeasons.first().siblingIndex} - ${tvShowMediaItemSeasons.last().siblingIndex}`
                            : `Season ${tvShowMediaItemSeasons.first().siblingIndex}`}
                    </p>
                ) : null}
            </div>
        );
    }
}
