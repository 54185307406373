import moment from 'moment';

/**
 * Formats user account & auth token response
 */
export const formatAccountResponse = function formatAccountResponse({
    userProfileDTO,
    userProfilePreferencesDTO,
    authToken,
}) {
    return {
        userAccount: {
            id: userProfileDTO.UserName,
            email: userProfileDTO.EmailAddress,
            termsAndConditionsAccepted: userProfileDTO.AcceptedTandCs,
            name: userProfileDTO.GivenName || null,
            hasOptedInToReceiveUpdates: userProfilePreferencesDTO.commsAffiliateEmail === 'True'
            && userProfilePreferencesDTO.commsAffiliatePost === 'True'
            && userProfilePreferencesDTO.commsAffiliateSMS === 'True',
            preferenceUpdateDate: userProfilePreferencesDTO.preferenceUpdateDate,
        },
        ...(authToken ? {authToken} : {}),
    };
};

/**
 * Formats auth token
 */
export const formatAuthTokenObject = function formatAuthTokenObject({validateIdentityResultDTO}) {
    return {
        expiration: moment().add(24, 'h').format(),
        token: btoa(validateIdentityResultDTO.UniqueIdentifier + ':' + validateIdentityResultDTO.Token),
    };
};
