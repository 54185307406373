import React from 'react';

export default React.createContext({
    mediaBucket: null,
    mediaGenres: null,
    isSearchRouteActive: false,
    searchQueryString: '',
    setSearchQueryString: () => {},
    searchMedia: () => {},
    loadMoreResults: () => {},
    setGenreFilterValue: () => {},
    setOrderByValue: () => {},
    areSearchResultsValid: true,
    orderByValue: null,
    filterByValue: null,
});
