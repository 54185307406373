import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './MediaItemViewContentInfo.scss';

export default class MediaItemViewContentInfo extends PureComponent {
    static propTypes = {
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        mediaItem: null,
    };

    render() {
        const {mediaItem} = this.props;

        return (
            <div className="vub-c-media-item-view-content-info">
                <p className="vub-c-media-item-view-content-info__row">
                    <span className="vub-c-media-item-view-content-info__title">
                        Parental Advisory:
                    </span>
                    <span className="vub-c-media-item-view-content-info__text">
                        {mediaItem.parentalAdvisory}
                    </span>
                </p>
                <p className="vub-c-media-item-view-content-info__row">
                    <span className="vub-c-media-item-view-content-info__title">
                        Genre:
                    </span>
                    <span className="vub-c-media-item-view-content-info__text">
                        {mediaItem.mediaGenreId}
                    </span>
                </p>
            </div>
        );
    }
}
