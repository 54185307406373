import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import pluralize from 'pluralize';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import * as selectors from './accountLibraryViewSelectors';
import './AccountLibraryView.scss';
import ContentRow from '../layout/ContentRow';
import MediaGallery from '../ui-elements/media-gallery/MediaGallery';
import MediaGalleryTilePortrait from '../ui-elements/media-gallery-tile/MediaGalleryTilePortrait';
import BackToTop from '../ui-elements/BackToTop';

/*
TODO temporary fix for library title here
Full solution should use ContentRow where we use MediaGallery/MediaCarousel while moving
headers into separate implementation, allowing for more versatile layouts that involve these two components
 */

const AccountLibraryView = ({mediaBucket, resolveMediaItemRoute}) => (
    <div className="vub-c-account-library-view">
        <ContentRow>
            <div className="vub-c-account-library-view__title">My Library</div>
        </ContentRow>

        {mediaBucket && mediaBucket.media.size > 0 && (
            <MediaGallery
                title="Rented videos"
                subtitle={`(${mediaBucket.mediaTotalCount} ${pluralize('title', mediaBucket.mediaTotalCount)})`}
            >
                {mediaBucket.media
                    .toArray()
                    .map(mediaItem => (
                        <MediaGalleryTilePortrait
                            key={mediaItem.id}
                            mediaItem={mediaItem}
                            linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                        />
                    ))}
            </MediaGallery>
        )}

        {mediaBucket && !mediaBucket.isUpdatePending && mediaBucket.media.size === 0 && (
            <ContentRow>
                <div className="vub-c-account-library-view__no-media">
                    Currently you have no media in your library.
                </div>
            </ContentRow>
        )}

        <BackToTop
            title="Back to top"
            screenMultiplicator={2}
        />
    </div>
);

AccountLibraryView.propTypes = {
    ...withMediaRouteHelpersPropTypes,
    mediaBucket: PropTypes.object,
};

AccountLibraryView.defaultProps = {
    ...withMediaRouteHelpersDefaultProps,
    mediaBucket: null,
};

export {AccountLibraryView};

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createAccountLibraryViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
    });
};

export default connect(
    createMapStateToProps,
)(withMediaRouteHelpers({mediaRouteResolver})(AccountLibraryView));
