import {sprintf} from 'sprintf-js';
import resourceEndpoints from './resourceEndpoints';
import handleResponse from '../handleResponse';

/**
 * Returns an endpoint and protections for playing an asset based on provider, assetId, assetOfferId and token.
 */
export const resolvePlaybackSource = function resolvePlaybackSource(params) {
    const queryParams = new URLSearchParams();

    const resourceEndpoint = sprintf(
        resourceEndpoints.RESOLVE_PLAYBACK_INFO,
        params.ccAPI.provider,
        params.assetId,
    );

    queryParams.append('OfferId', params.assetOfferId);

    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.ccAPI.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${params.ccIdentityToken}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};
