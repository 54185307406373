import {all, call, fork, put, take, takeEvery, select} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import bowser from 'bowser';
import * as authActionTypes from '../auth/authActionTypes';
import * as authSelectors from '../auth/authSelectors';
import * as mediaSelectors from '../media/mediaSelectors';
import * as actionTypes from './mediaEntitlementActionTypes';
import * as mediaActionTypes from '../media/mediaActionTypes';
import * as mediaEntitlementSelectors from './mediaEntitlementSelectors';
import {mediaEntitlementService, mediaRouteResolver} from '../services';

/**
 * Fetch current entitlements
 */
export const fetchCurrentEntitlements = function* fetchCurrentEntitlements() {
    try {
        yield put({
            type: actionTypes.FETCH_CURRENT_ENTITLEMENTS_REQUEST,
            payload: {},
        });

        const response = yield call(mediaEntitlementService.getCurrentEntitlements);
        yield put({
            type: actionTypes.FETCH_CURRENT_ENTITLEMENTS_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_CURRENT_ENTITLEMENTS_ERROR, error: true, payload: error});
    }
};

/**
 * Load user's current entitlements
 */
export const loadCurrentEntitlements = function* loadCurrentEntitlements() {
    const isUserSignedIn = yield select(authSelectors.getUserSignedInStatus);
    if (!isUserSignedIn) return null;

    const currentEntitlements = yield select(mediaEntitlementSelectors.getCurrentEntitlements);
    if (currentEntitlements) return null;

    yield fork(fetchCurrentEntitlements);
    const resultAction = yield take([
        actionTypes.FETCH_CURRENT_ENTITLEMENTS_SUCCESS,
        actionTypes.FETCH_CURRENT_ENTITLEMENTS_ERROR,
    ]);

    if (!resultAction.error) {
        const {response: entitlementDTOs} = resultAction.payload;
        yield put({
            type: actionTypes.STORE_CURRENT_ENTITLEMENTS,
            payload: {entitlementDTOs},
        });
    }
};

/**
 * Fetch mediaItemPlaybackSource request
 */
export const fetchMediaItemPlaybackSourceRequest = function* fetchMediaItemPlaybackSourceRequest(params) {
    try {
        yield put({
            type: actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_REQUEST,
            payload: params,
        });

        const response = yield call(mediaEntitlementService.getMediaItemPlaybackSource, params);
        yield put({
            type: actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_ERROR, error: true, payload: error});
    }
};

/**
 * Checkout single mediaItem offer
 */
const checkoutSingleMediaItemOffer = function* checkoutSingleMediaItemOffer(params) {
    try {
        yield put({type: actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_REQUEST, payload: params});

        const response = yield call(mediaEntitlementService.checkoutSingleMediaItem, params);
        yield put({
            type: actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_ERROR, error: true, payload: error});
    }
};

/**
 * Charge basket
 */
const chargeBasket = function* chargeBasket(params) {
    try {
        yield put({type: actionTypes.CHARGE_BASKET_REQUEST, payload: params});

        const response = yield call(mediaEntitlementService.chargeBasket, params);
        yield put({
            type: actionTypes.CHARGE_BASKET_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.CHARGE_BASKET_ERROR, error: true, payload: error});
    }
};

/**
 * Create purchase order item SVOD
 */
const createPurchaseOrderItemSVOD = function* createPurchaseOrderItemSVOD(params) {
    try {
        yield put({type: actionTypes.CREATE_PURCHASE_ORDER_ITEM_SVOD_REQUEST, payload: params});

        const response = yield call(mediaEntitlementService.createPurchaseOrderItemSVOD, params);
        yield put({
            type: actionTypes.CREATE_PURCHASE_ORDER_ITEM_SVOD_SUCCESS,
            payload: {response},
        });
    } catch (error) {
        yield put({type: actionTypes.CREATE_PURCHASE_ORDER_ITEM_SVOD_ERROR, error: true, payload: error});
    }
};

/**
 * Fast checkout mediaItem offer flow
 */
const fastCheckoutMediaItemOfferFlow = function* fastCheckoutMediaItemOfferFlow() {
    while (true) {
        const {payload} = yield take(actionTypes.FAST_CHECKOUT_MEDIA_ITEM_OFFER);
        yield put({type: actionTypes.STORE_FAST_CHECKOUT_STATUS, payload: {isFastCheckoutInProgress: true}});

        const {offer} = payload;
        yield put({
            type: actionTypes.STORE_BASKET_ITEM,
            payload: {offerId: offer.id, mediaItemId: offer.mediaItemId, updatePending: true},
        });
        yield fork(checkoutSingleMediaItemOffer, {offerId: offer.id});
        const checkoutSingleMediaItemOfferResultAction = yield take([
            actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_SUCCESS,
            actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_ERROR,
        ]);

        if (checkoutSingleMediaItemOfferResultAction.type === actionTypes.CHECKOUT_SINGLE_MEDIA_ITEM_OFFER_ERROR) {
            yield put({
                type: actionTypes.STORE_FAST_CHECKOUT_STATUS,
                payload: {isFastCheckoutInProgress: false},
            });
            continue;
        }

        const {response: basketItemDTO} = checkoutSingleMediaItemOfferResultAction.payload;
        yield put({
            type: actionTypes.STORE_BASKET_ITEM,
            payload: {basketItemDTO},
        });

        const fastCheckoutResultAction = yield take([
            actionTypes.CANCEL_MEDIA_ITEM_OFFER_FAST_CHECKOUT,
            actionTypes.CHARGE_BASKET,
        ]);

        if (fastCheckoutResultAction.type === actionTypes.CANCEL_MEDIA_ITEM_OFFER_FAST_CHECKOUT) {
            yield put({type: actionTypes.STORE_FAST_CHECKOUT_STATUS, payload: {isFastCheckoutInProgress: false}});
            continue;
        }

        yield fork(chargeBasket, {amountDue: offer.price, currencyCode: offer.currency});
        yield put({type: actionTypes.STORE_BASKET_CHARGING_STATUS, payload: {isBasketChargingInProgress: true}});

        const chargeBasketResultAction = yield take([
            actionTypes.CHARGE_BASKET_SUCCESS,
            actionTypes.CHARGE_BASKET_ERROR,
        ]);

        yield put({type: actionTypes.STORE_BASKET_CHARGING_STATUS, payload: {isBasketChargingInProgress: false}});
        yield put({type: actionTypes.STORE_FAST_CHECKOUT_STATUS, payload: {isFastCheckoutInProgress: false}});

        if (chargeBasketResultAction.type === actionTypes.CHARGE_BASKET_ERROR) continue;

        const {response: entitlementDTOs} = chargeBasketResultAction.payload;
        yield put({
            type: actionTypes.STORE_CURRENT_ENTITLEMENTS,
            payload: {entitlementDTOs},
        });
        yield put({type: actionTypes.REMOVE_BASKET_ITEM, payload: {offerId: offer.id}});

        const mediaItems = yield select(mediaSelectors.getMediaItems);
        const mediaItem = mediaItems.get(offer.mediaItemId);
        if (mediaItem) {
            const media = yield select(mediaSelectors.getMediaItems);
            mediaRouteResolver.setMedia(media);
            yield put(push(mediaRouteResolver.resolveMediaItemRoute({mediaItemId: mediaItem.id})));
        }
    }
};

/**
 * Start mediaItem playback flow
 */
export const startMediaItemOfferPlaybackFlow = function* startMediaItemOfferPlaybackFlow() {
    while (true) {
        const {payload} = yield take(actionTypes.START_MEDIA_ITEM_OFFER_PLAYBACK);

        const {offer} = payload;
        if (offer.termsType === 'Recurring') {
            yield fork(createPurchaseOrderItemSVOD, {
                offerId: offer.id,
            });
        }

        const browser = bowser.getParser(window.navigator.userAgent);
        const browserName = browser.getBrowserName();
        yield fork(fetchMediaItemPlaybackSourceRequest, {
            assetId: offer.assetId,
            assetOfferId: offer.assetOfferId,
            scope: 'resolve:asset',
            // over-ride HD rent offer with SD values
            // http://redmine.computerrock.com/issues/30743
            // resolution: `private:${offer.resolution === '1080p' ? 'HD' : 'SD'}`,
            resolution: 'private:SD',
            platformClass: 'WEB',
            platformSubclass: `private:${browserName === 'Internet Explorer' || browserName === 'Microsoft Edge'
                ? 'edge' : browserName.toLowerCase()}`,
            delivery: `${browserName === 'Safari' ? 'hls' : 'dash'}`,
            region: 'uk',
        });
        const fetchMediaItemPlaybackSourceResult = yield take([
            actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_SUCCESS,
            actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_ERROR,
        ]);

        if (fetchMediaItemPlaybackSourceResult.type === actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_ERROR) continue;

        const {response: mediaItemPlaybackSourceDTO} = fetchMediaItemPlaybackSourceResult.payload;
        yield put({
            type: actionTypes.STORE_MEDIA_ITEM_PLAYBACK_SOURCE,
            payload: {mediaItemPlaybackSourceDTO, playbackMediaItemId: offer.mediaItemId},
        });

        yield take(actionTypes.STOP_MEDIA_ITEM_PLAYBACK);
        yield put({type: actionTypes.STORE_MEDIA_ITEM_PLAYBACK_SOURCE, payload: {mediaItemPlaybackSourceDTO: null}});
    }
};

/**
 * Fetch and store mediaItem preview playbackSource
 */
export const fetchAndStoreMediaItemPreviewPlaybackSource = function* fetchAndStoreMediaItemPreviewPlaybackSource(
    offer
) {
    const browser = bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName();
    yield fork(fetchMediaItemPlaybackSourceRequest, {
        assetId: offer.assetId,
        assetOfferId: offer.assetOfferId,
        scope: 'resolve:asset',
        resolution: `private:${offer.resolution === '1080p' ? 'HD' : 'SD'}`,
        platformClass: 'WEB',
        platformSubclass: `private:${browserName === 'Internet Explorer' || browserName === 'Microsoft Edge'
            ? 'edge' : browserName.toLowerCase()}`,
        delivery: `${browserName === 'Safari' ? 'hls' : 'dash'}`,
        region: 'uk',
    });
    const fetchMediaItemPlaybackSourceResult = yield take([
        actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_SUCCESS,
        actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_ERROR,
    ]);

    if (fetchMediaItemPlaybackSourceResult.type === actionTypes.FETCH_MEDIA_ITEM_PLAYBACK_INFO_ERROR) return null;

    const {response: mediaItemPlaybackSourceDTO} = fetchMediaItemPlaybackSourceResult.payload;
    yield put({
        type: actionTypes.STORE_MEDIA_ITEM_PREVIEW_PLAYBACK_SOURCE,
        payload: {mediaItemPlaybackSourceDTO, mediaItemId: offer.mediaItemId},
    });
};

/**
 * Fetch and store media preview playbackSources flow
 */
export const fetchAndStoreMediaPreviewPlaybackSources = function* fetchAndStoreMediaPreviewPlaybackSources({payload}) {
    while (true) {
        const {mediaBucketKey} = payload;
        const fetchMediaResultAction = yield take([
            mediaActionTypes.FETCH_MEDIA_SUCCESS,
            mediaActionTypes.FETCH_MEDIA_ERROR,
        ]);
        if (typeof fetchMediaResultAction.meta.mediaBucketKey !== 'undefined'
            && fetchMediaResultAction.meta.mediaBucketKey !== mediaBucketKey) continue;

        while (true) {
            const {payload: mediaBucketPayload} = yield take(mediaActionTypes.MEDIA_BUCKET_MEDIA_STORED);
            if (mediaBucketPayload.mediaBucketKey !== mediaBucketKey) continue;

            const mediaBuckets = yield select(mediaSelectors.getMediaBuckets);
            const mediaItems = yield select(mediaSelectors.getMediaItems);
            const mediaBucket = mediaBuckets.get(mediaBucketKey);
            if (mediaBucket) {
                yield all(mediaBucket.mediaIds.toArray().map(mediaItemId => {
                    const mediaItem = mediaItems.get(mediaItemId);
                    const offer = mediaItem.preview;

                    return call(fetchAndStoreMediaItemPreviewPlaybackSource, offer);
                }));
            }
        }
    }
};

/**
 * Set entitlement-service auth token
 */
export const setEntitlementServiceAuthToken = function* setEntitlementServiceAuthToken({payload}) {
    const {authToken} = payload;
    yield call(mediaEntitlementService.setAuthToken, authToken);
};

/**
 * Invalidate user session data
 */
export const invalidateUserSessionData = function* invalidateUserSessionData() {
    yield put({type: actionTypes.RESET_CURRENT_ENTITLEMENTS, payload: {}});
    yield put({type: actionTypes.RESET_BASKET, payload: {}});
};

/**
 * Media entitlement watcher saga
 */
export const mediaEntitlementWatcher = function* mediaEntitlementWatcher() {
    yield all([
        fork(fastCheckoutMediaItemOfferFlow),
        fork(startMediaItemOfferPlaybackFlow),
        takeEvery(mediaActionTypes.FETCH_MEDIA_AND_PREVIEW_PLAYBACK_SOURCES, fetchAndStoreMediaPreviewPlaybackSources),
        takeEvery(authActionTypes.USER_SESSION_STARTED, setEntitlementServiceAuthToken),
        takeEvery(authActionTypes.USER_SESSION_ENDED, invalidateUserSessionData),
    ]);
};
