import React, {Component} from 'react';
import {BEMClassNameResolver, BEMClassNameResolverPropTypes, BEMClassNameResolverDefaultProps} from './BEMClassNameResolver';
import './CarouselSlide.scss';

@BEMClassNameResolver('cr-c-carousel-slide')
class CarouselSlide extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
    };

    render() {
        const {resolveClassName} = this.props;
        const {children} = this.props;

        return (
            <div className={resolveClassName('js-carousel-slide')}>
                {children}
            </div>
        );
    }
}

export default CarouselSlide;
