import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './PopUp.scss';
import withPopUp from './withPopUp';
import withOverlay from '../overlay/withOverlay';

@withOverlay
@withPopUp
class PopUp extends Component {
    static propTypes = {
        isWithOverlay: PropTypes.bool,
        position: PropTypes.string,
        content: PropTypes.node,
        openOverlay: PropTypes.func,
        closeOverlay: PropTypes.func,
    };

    static defaultProps = {
        isWithOverlay: false,
        position: null,
        content: null,
        openOverlay: null,
        closeOverlay: null,
    };

    componentDidMount() {
        this.props.isWithOverlay && this.props.openOverlay();
    }

    componentWillUnmount() {
        this.props.isWithOverlay && this.props.closeOverlay();
    }

    render() {
        const {content, position} = this.props;
        return (
            <div className={`vub-c-pop-up-layer ${position ? `vub-c-pop-up-layer--${position}` : ''}`}>
                <div className="vub-c-pop-up-layer__pop-up">
                    {content}
                </div>
            </div>
        );
    }
}

export default PopUp;
