import React, {PureComponent} from 'react';
import Icon from '../ui-kit/Icon';
import './BackToTopIcon.scss';

export default class BackToTopIcon extends PureComponent {
    static propTypes = {
        ...Icon.propTypes,
    };

    static defaultProps = {
        ...Icon.defaultProps,
    };

    render() {
        return (
            <Icon {...this.props} blockName="vub-c-back-to-top-icon" />
        );
    }
}
