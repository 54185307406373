import store from 'store';
import moment from 'moment';
import {providerAuthenticationType, providerAuthenticationProtocol} from '../vub-wls-auth-service';
import * as resourceMethods from './resourceMethods';
import * as responseHelpers from './responseHelpers';

/**
 * Injects service parameters into API resource method
 */
const injectServiceParameters = (apiResourceMethod, serviceParameters) => params => apiResourceMethod({
    ...serviceParameters,
    ...params,
});

/**
 * Vubiquity WLS auth provider for FilmFlex API
 */
export default class AuthProviderFilmFlex {
    /**
     * @param {Object} serviceParameters
     * @returns {this}
     */
    constructor(serviceParameters = {}) {
        this.serviceParameters = serviceParameters;
        this.resources = {
            validateIdentity: injectServiceParameters(resourceMethods.validateIdentity, serviceParameters),
            createAccount: injectServiceParameters(resourceMethods.createAccount, serviceParameters),
            getProfile: injectServiceParameters(resourceMethods.getProfile, serviceParameters),
            updateProfile: injectServiceParameters(resourceMethods.updateProfile, serviceParameters),
            getProfilePreferences: injectServiceParameters(resourceMethods.getProfilePreferences, serviceParameters),
            updateProfilePreferences: injectServiceParameters(
                resourceMethods.updateProfilePreferences,
                serviceParameters,
            ),
            changePassword: injectServiceParameters(resourceMethods.changePassword, serviceParameters),
            sendResetPasswordEmail: injectServiceParameters(resourceMethods.sendResetPasswordEmail, serviceParameters),
            resetPassword: injectServiceParameters(resourceMethods.resetPassword, serviceParameters),
        };
        this.authToken = null;
        this.userSessionTimeout = null;
    }

    authenticationType = providerAuthenticationType.BUILT_IN;

    authenticationProtocol = providerAuthenticationProtocol.TOKEN_BASED_AUTHENTICATION;

    getOAuth2AuthorizationUri(params) {
        // no-op
    }

    getAccountSettingsUri(params) {
        // no-op
    }

    createUserAccount(params) {
        const resources = this.resources;

        return resources.createAccount({
            email: params.email,
            password: params.password,
            name: params.name,
            termsAndConditionsAccepted: params.termsAndConditionsAccepted,
        });
    }

    authenticateUser(params) {
        const resources = this.resources;

        return resources.validateIdentity({
            email: params.email,
            password: params.password,
        })
            .then(validateIdentityResultDTO => {
                if (validateIdentityResultDTO.AffiliateStatus === 0) {
                    return Promise.reject(new Error('User not found'));
                }

                if (validateIdentityResultDTO.AffiliateStatus !== 1) {
                    return Promise.reject(new Error('Account not active'));
                }

                const authToken = responseHelpers.formatAuthTokenObject({validateIdentityResultDTO});
                this.startUserSession(authToken);

                return Promise.all([
                    resources.getProfile({authToken: this.authToken}).catch(() => null),
                    resources.getProfilePreferences({authToken: this.authToken}).catch(() => null),
                ]).then(results => {
                    const userProfileDTO = results[0];
                    const userProfilePreferencesDTO = results[1];

                    return responseHelpers.formatAccountResponse({
                        userProfileDTO,
                        userProfilePreferencesDTO,
                        authToken: this.authToken,
                    });
                });
            });
    }

    startUserSession(authToken) {
        store.set('wls_ff_api_auth_token', JSON.stringify(authToken));
        this.authToken = authToken;

        // set userSession timeout
        const tokenExpiration = moment(this.authToken.expiration);
        const tokenDuration = moment.duration(tokenExpiration.diff(moment()));
        this.userSessionTimeout = new Promise(resolve => {
            const timeout = setTimeout(() => {
                this.endUserSession();
                clearTimeout(timeout);
                resolve(true);
            }, tokenDuration.asMilliseconds());
        });
    }

    hasUserSessionExpired() {
        return this.userSessionTimeout;
    }

    restoreUserSession() {
        const storedAuthTokenDTO = store.get('wls_ff_api_auth_token');
        if (!storedAuthTokenDTO) return null;

        const authToken = JSON.parse(storedAuthTokenDTO);
        if (!authToken) return null;

        if (moment() > moment(authToken.expiration)) {
            store.set('wls_ff_api_auth_token', JSON.stringify(null));
            return null;
        }

        this.authToken = authToken;

        // set userSession timeout
        const tokenExpiration = moment(this.authToken.expiration);
        const tokenDuration = moment.duration(tokenExpiration.diff(moment()));
        this.userSessionTimeout = new Promise(resolve => {
            const timeout = setTimeout(() => {
                this.endUserSession();
                clearTimeout(timeout);
                resolve(true);
            }, tokenDuration.asMilliseconds());
        });

        return this.authToken;
    }

    endUserSession() {
        store.set('wls_ff_api_auth_token', JSON.stringify(null));
        this.authToken = null;
    }

    getUserAccount() {
        const resources = this.resources;

        return Promise.all([
            resources.getProfile({authToken: this.authToken}).catch(() => null),
            resources.getProfilePreferences({authToken: this.authToken}).catch(() => null),
        ]).then(results => {
            const userProfileDTO = results[0];
            const userProfilePreferencesDTO = results[1];

            return responseHelpers.formatAccountResponse({
                userProfileDTO,
                userProfilePreferencesDTO,
            });
        });
    }

    updateUserAccount(params) {
        const resources = this.resources;

        return Promise.all([
            resources.updateProfile({
                ...params,
                authToken: this.authToken,
            }).catch(() => null),
            resources.updateProfilePreferences({
                // TODO move logic to resourceMethods
                profilePreferences: {
                    ...(typeof params.hasOptedInToReceiveUpdates !== 'undefined'
                        ? {
                            commsAffiliateEmail: params.hasOptedInToReceiveUpdates ? 'True' : 'False',
                            commsAffiliatePost: params.hasOptedInToReceiveUpdates ? 'True' : 'False',
                            commsAffiliateSMS: params.hasOptedInToReceiveUpdates ? 'True' : 'False',
                            preferenceUpdateDate: moment().format(),
                        } : {}
                    ),
                },
                authToken: this.authToken,
            }).catch(() => null),
        ])
            .then(results => {
                const userProfileDTO = results[0];
                const userProfilePreferencesDTO = results[1];

                if (params.oldPassword) {
                    return resources.changePassword({...params, authToken: this.authToken})
                        .then(() => {
                            return resources.validateIdentity({
                                email: params.email,
                                password: params.newPassword,
                            })
                                .then(validateIdentityResultDTO => {
                                    const authToken = responseHelpers
                                        .formatAuthTokenObject({validateIdentityResultDTO});
                                    this.startUserSession(authToken);

                                    return responseHelpers.formatAccountResponse({
                                        userProfileDTO,
                                        userProfilePreferencesDTO,
                                        authToken: this.authToken,
                                    });
                                });
                        });
                }

                return responseHelpers.formatAccountResponse({
                    userProfileDTO,
                    userProfilePreferencesDTO,
                });
            });
    }

    requestPasswordReset(params) {
        const resources = this.resources;

        return resources.sendResetPasswordEmail({
            email: params.email,
        });
    }

    resetPassword(params) {
        const resources = this.resources;

        return resources.resetPassword({
            token: params.token,
            newPassword: params.newPassword,
        });
    }
}
