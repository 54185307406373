import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './CTAButton.scss';
import Button from '../ui-kit/Button';

class CTAButton extends Component {
    static propTypes = {
        ...Button.propTypes,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        ...Button.defaultProps,
        onClick: null,
    };

    onClick = () => {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
    };

    render() {
        return (
            <Button
                {...this.props}
                blockName="vub-c-cta-button"
                onClick={this.onClick}
            />
        );
    }
}

export default CTAButton;

