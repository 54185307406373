import React, {Component, Fragment, createRef} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import routePaths from '../../routePaths';
import resolveRoute from '../../navigation/resolveRoute';
import {combineClassNames} from '../../ui-kit/utils';
import * as authActions from '../../auth/authActions';
import NavigationSideItem from './navigation-side/NavigationSideItem';
import NavigationSideSubmenu from './navigation-side/NavigationSideSubmenu';
import {iconBurger, iconMenuSignOut, iconNavUser, iconMenuLibrary} from '../../ui-elements/icons';
import './NavigationSide.scss';
import NavigationIconButton from './NavigationIconButton';
import NavigationIcon from './NavigationIcon';
import {
    withLayoutControls,
    withLayoutControlsPropTypes,
    withLayoutControlsDefaultProps,
} from '../withLayoutControls';
import {
    withOutsideClick,
    withOutsideClickPropTypes,
    withOutsideClickDefaultProps,
} from '../../ui-elements/outside-click/withOutsideClick';
import withOverlay from '../../ui-elements/overlay/withOverlay';
import * as menuTypes from '../../navigation/menuTypes';
import menuItemTypes from '../../navigation/menuItemTypes';
import SubscriptionLabel from '../../ui-elements/subscription-label/SubscriptionLabel';

@withLayoutControls
@withOverlay
@withOutsideClick
class NavigationSide extends Component {
    static propTypes = {
        ...withLayoutControlsPropTypes,
        ...withOutsideClickPropTypes,
        headerMenu: PropTypes.array,
        footerMenu: PropTypes.array,
        isUserSignedIn: PropTypes.bool,
        userAccount: PropTypes.object,
        location: PropTypes.object,
        signInUser: PropTypes.func.isRequired,
        signOutUser: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
        // openOverlay: PropTypes.func,
        closeOverlay: PropTypes.func,
    };

    static defaultProps = {
        ...withLayoutControlsDefaultProps,
        ...withOutsideClickDefaultProps,
        headerMenu: null,
        footerMenu: null,
        userAccount: null,
        location: null,
        isUserSignedIn: false,
        // openOverlay: null,
        closeOverlay: null,
    };

    state = {
        isMenuOpened: false,
        isSecondaryMenuOpened: false,
        section: null,
    };

    componentDidMount() {
        this.props.subscribeToClickOutside(this.sideNavRef, this.onClickOutside);
    }

    componentWillUnmount() {
        this.props.unsubscribeFromClickOutside(this.sideNavRef, this.onClickOutside);
    }

    onClickOutside = () => {
        this.setState({
            isMenuOpened: false,
        });
    };

    toggleMenu = () => {
        this.setState(state => ({
            isMenuOpened: !state.isMenuOpened,
        }), () => {
            this.props.setHeaderInUseState(this.state.isMenuOpened);
            // toggle overlay - TODO disable until better solution is found for overlay z-index positioning
            // this.state.isMenuOpened ? this.props.openOverlay() : this.props.closeOverlay();
        });
    };

    toggleSecondaryMenu = section => {
        this.setState(state => ({
            isSecondaryMenuOpened: !state.isSecondaryMenuOpened,
            section,
        }));
    };

    sideNavRef = createRef();

    handleClickOutside = e => {
        if (!this.sideNav.current.contains(e.target)) {
            this.setState({
                isMenuOpened: false,
            }, () => {
                this.props.closeOverlay();
                // this prop disables nav side closing animation on mobile devices
                this.props.setHeaderInUseState(this.state.isMenuOpened);
            });
        }
    };

    signInUser = () => {
        this.props.signInUser();
        this.toggleMenu();
    };

    signOutUser = () => {
        this.props.signOutUser();
        this.toggleMenu();
    };

    updateUser = () => {
        this.props.updateUser();
        this.toggleMenu();
    };

    render() {
        const {headerMenu, footerMenu, isUserSignedIn, userAccount, location} = this.props;
        const {isSecondaryMenuOpened, section} = this.state;

        return (
            <nav
                className={combineClassNames(
                    'vub-c-navigation-side',
                    this.state.isMenuOpened ? 'cr-is-opened' : 'cr-is-closed',
                )}
                ref={this.sideNavRef}
            >
                <NavigationIconButton
                    icon={iconBurger}
                    isToggleSwitch
                    isSwitchedOn={this.state.isMenuOpened}
                    onClick={this.toggleMenu}
                />
                <div className="vub-c-navigation-side__content">
                    <ul
                        className={combineClassNames(
                            'vub-c-navigation-side__menu',
                            !this.state.isSecondaryMenuOpened && 'cr-is-opened',
                        )}
                    >
                        {isUserSignedIn && (
                            <Fragment>
                                <NavigationSideItem
                                    key="account-settings"
                                    isSelected={false}
                                >
                                    <span className="vub-u-text-color">
                                        Hello {userAccount && userAccount.name.slice(0, 10)}
                                    </span>
                                    {userAccount.primarySubscription && (
                                        <SubscriptionLabel>
                                            {userAccount.primarySubscription.type}
                                        </SubscriptionLabel>
                                    )}
                                </NavigationSideItem>
                                <hr className="vub-c-navigation-side__horizontal-rule" />
                            </Fragment>
                        )}
                        {isUserSignedIn && (
                            <NavigationSideItem
                                key="account-settings"
                                isSelected={location.pathname === routePaths.ACCOUNT_SETTINGS}
                                onClick={this.updateUser}
                            >
                                Account
                                {userAccount.primarySubscription && (
                                    <SubscriptionLabel>
                                        {userAccount.primarySubscription.type}
                                    </SubscriptionLabel>
                                )}
                                <NavigationIcon icon={iconNavUser} />
                            </NavigationSideItem>
                        )}
                        {isUserSignedIn
                        && (
                            <NavigationSideItem
                                key="account-library"
                                linkTo={resolveRoute(routePaths.ACCOUNT_LIBRARY)}
                                isSelected={location.pathname === routePaths.ACCOUNT_LIBRARY}
                                onClick={this.toggleMenu}
                            >
                                Library
                                <NavigationIcon icon={iconMenuLibrary} />
                            </NavigationSideItem>
                        )}
                        {isUserSignedIn
                        && (
                            <NavigationSideItem
                                key="sign-out"
                                linkTo={resolveRoute(routePaths.STOREFRONT)}
                                isSelected={false}
                                onClick={this.signOutUser}
                            >
                                Sign Out
                                <NavigationIcon icon={iconMenuSignOut} />
                            </NavigationSideItem>
                        )}
                        {!isUserSignedIn
                        && (
                            <NavigationSideItem
                                key="sign-in"
                                isSelected={location.pathname === routePaths.SIGN_IN}
                                onClick={this.signInUser}
                            >
                                Sign In
                                <NavigationIcon icon={iconMenuSignOut} />
                            </NavigationSideItem>
                        )}

                        <hr className="vub-c-navigation-side__horizontal-rule" />

                        {headerMenu ? headerMenu.map(menuItem => {
                            if (menuItem.display
                                && menuItem.type === menuItemTypes.CONTAINER
                                && menuItem.children.length !== 0) {
                                return (
                                    <NavigationSideSubmenu
                                        key={menuItem.id}
                                        menuItem={menuItem}
                                        toggleSecondaryMenu={this.toggleSecondaryMenu}
                                        toggleMenu={this.toggleMenu}
                                        isSecondaryMenuOpened={isSecondaryMenuOpened}
                                        section={section}
                                        location={location}
                                    />
                                );
                            }

                            return (
                                <NavigationSideItem
                                    key={menuItem.id}
                                    linkTo={menuItem.url}
                                >
                                    {menuItem.title}
                                </NavigationSideItem>
                            );
                        }) : null}

                        <hr className="vub-c-navigation-side__horizontal-rule" />

                        {footerMenu ? footerMenu.map(menuItem => menuItem.display && (
                            <NavigationSideItem
                                key={menuItem.title}
                                linkTo={menuItem.url}
                                isSelected={location.pathname === menuItem.url.pathname}
                                onClick={this.toggleMenu}
                            >
                                {menuItem.title}
                            </NavigationSideItem>
                        )) : null}
                    </ul>
                </div>
            </nav>
        );
    }
}

export {NavigationSide};

// connect component to redux store and actions
const mapStateToProps = state => ({
    headerMenu: state.navigation.menus[menuTypes.HEADER_MENU],
    footerMenu: state.navigation.menus[menuTypes.FOOTER_MENU],
    isUserSignedIn: state.auth.isUserSignedIn,
    userAccount: state.auth.userAccount,
    location: state.router.location,
});

const mapDispatchToProps = dispatch => ({
    signInUser: payload => dispatch(authActions.signInUser(payload)),
    signOutUser: payload => dispatch(authActions.signOutUser(payload)),
    updateUser: payload => dispatch(authActions.updateUser(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavigationSide);
