import React, {PureComponent} from 'react';
import PopUpContext from './PopUpContext';

const withPopUp = BaseComponent => (
    class ComposeComponent extends PureComponent {
        static propTypes = {
            ...BaseComponent.propTypes,
        };

        static defaultProps = {
            ...BaseComponent.defaultProps,
        };

        render() {
            return (
                <PopUpContext.Consumer>
                    {({setContent, setPopUp, openPopUp, closePopUp}) => {
                        const props = {
                            ...this.props,
                            setContent,
                            openPopUp,
                            closePopUp,
                            setPopUp,
                        };
                        return <BaseComponent {...props} />;
                    }}
                </PopUpContext.Consumer>
            );
        }
    }
);

export default withPopUp;
