/**
 * App config
 */
export default {
    // General
    VERSION: process.env.VERSION,
    BUILD: process.env.BUILD,
    ENVIRONMENT: '',
    ROOT: '/',
    BASE_URL: process.env.BASE_URL,
    LOCALE: process.env.LOCALE || 'en',
    TEMP_USE_SSO_AUTH_PROVIDER: process.env.TEMP_USE_SSO_AUTH_PROVIDER || 0,
    TEMP_DISABLE_PLAYBACK: process.env.TEMP_DISABLE_PLAYBACK || 0,

    // Service: AppConfigAPI
    WLS_CONFIGURATION_API_URI: process.env.VUBIQUITY_WLS_CONFIGURATION_API_URI,

    // Services: FFAPI
    FF_API_URI: process.env.VUBIQUITY_FF_API_URI,
    FF_API_VERSION: process.env.VUBIQUITY_FF_API_VERSION,
    FF_API_AFFILIATE_ID: process.env.VUBIQUITY_FF_API_AFFILIATE_ID,
    FF_API_TRUSTED_APPLICATION_TOKEN: process.env.VUBIQUITY_FF_API_TRUSTED_APPLICATION_TOKEN,
    FF_API_TRUSTED_APPLICATION_NAME: process.env.VUBIQUITY_FF_API_TRUSTED_APPLICATION_NAME,

    // Services: CCIdentityAPI
    CC_IDENTITY_API_URI: process.env.VUBIQUITY_CC_IDENTITY_API_URI,
    CC_IDENTITY_API_CLIENT_ID: process.env.VUBIQUITY_CC_IDENTITY_API_CLIENT_ID,
    CC_IDENTITY_API_CLIENT_SECRET: process.env.VUBIQUITY_CC_IDENTITY_API_CLIENT_SECRET,
    UXP_IDENTITY_URI: process.env.VUBIQUITY_UXP_IDENTITY_URI,

    // Services: SMPAccountAPI
    SMC_ACCOUNT_API_URI: process.env.VUBIQUITY_SMC_ACCOUNT_API_URI,

    // Services: CCAPI
    CC_API_URI: process.env.VUBIQUITY_CC_API_URI,
    CC_API_PROVIDER_ID: process.env.VUBIQUITY_CC_API_PROVIDER_ID,
    CC_API_PROVIDER: process.env.VUBIQUITY_CC_API_PROVIDER,

    // Services: CRAPI
    CR_API_URI: process.env.VUBIQUITY_CR_API_URI,
    CR_API_APP_ID: process.env.VUBIQUITY_CR_API_APP_ID,

    // Services: CCSearchAPI
    CC_SEARCH_API_URI: process.env.VUBIQUITY_CC_SEARCH_API_URI,
    CC_SEARCH_API_KEY: process.env.VUBIQUITY_CC_SEARCH_API_KEY,
    CC_SEARCH_API_VERSION: process.env.VUBIQUITY_CC_SEARCH_API_VERSION,
    CC_SEARCH_API_PROVIDER_ID: process.env.VUBIQUITY_CC_SEARCH_API_PROVIDER_ID,
    CC_SEARCH_API_PROVIDER: process.env.VUBIQUITY_CC_SEARCH_API_PROVIDER,

    // Google
    GA_TRACKING_ID: process.env.GA_TRACKING_ID,
    RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,

    // Azure media player
    AZURE_MEDIA_PLAYER_CDN_URL: `//amp.azure.net/libs/amp/2.2.2/azuremediaplayer.min.js`,

    // Debug popover
    DEBUG_POPOVER: false,
};
