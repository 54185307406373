import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import pluralize from 'pluralize';
import throttle from 'lodash.throttle';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import * as selectors from './mediaClassificationViewSelectors';
import * as mediaActions from '../media/mediaActions';
import './MediaClassificationTVShowsView.scss';
import MediaGallery from '../ui-elements/media-gallery/MediaGallery';
import MediaGalleryTilePortrait from '../ui-elements/media-gallery-tile/MediaGalleryTilePortrait';
import BackToTop from '../ui-elements/BackToTop';
import ContentRow from '../layout/ContentRow';
import PullDownContent from '../ui-elements/pull-down-content/PullDownContent';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';
import orderByOptions from '../static-data/orderByOptions';
import CustomSelect from '../ui-elements/custom-select/CustomSelect';
import Option from '../ui-elements/custom-select/Option';

@withMediaRouteHelpers({mediaRouteResolver})
class MediaClassificationTVShowsView extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        mediaBucket: PropTypes.object,
        mediaGenres: PropTypes.object.isRequired,
        fetchMediaCollectionMedia: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        mediaBucket: null,
    };

    constructor(props) {
        super(props);
        this.areSearchResultsScrolledToBottom = throttle(this.areSearchResultsScrolledToBottom, 200);
    }

    state = {
        filterByValue: null,
        orderByValue: null,
        prevPropLocation: null, // eslint-disable-line react/no-unused-state
    };

    // TODO anti pattern, refactor out when more time
    static getDerivedStateFromProps(props, state) {
        const {location} = props;
        if (location !== state.prevPropLocation) {
            return {
                filterByValue: 'reset',
                orderByValue: orderByOptions[0].value,
                prevPropLocation: location,
            };
        }
        return null;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.areSearchResultsScrolledToBottom, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.areSearchResultsScrolledToBottom, false);
    }

    componentRef = React.createRef();

    getWhereQuery = () => {
        const classificationQuery = mediaItemClassification.TV_SHOW;
        return classificationQuery ? [`classification:in:${classificationQuery}`] : [];
    };

    navigateToGenre = value => {
        if (value !== 'reset') {
            const {history, resolveMediaGenreRoute} = this.props;
            history.push(resolveMediaGenreRoute({
                mediaGenreId: value,
                classification: 'tv-shows',
            }));
        }
    };

    onOrderBySelect = value => {
        this.setState({
            orderByValue: value,
        }, () => {
            const {mediaBucket, fetchMediaCollectionMedia} = this.props;
            const {orderByValue} = this.state;
            const orderByOption = orderByOptions.find(option => option.value === orderByValue);

            fetchMediaCollectionMedia({
                requestParams: {
                    mediaCollectionId: null,
                    where: this.getWhereQuery(),
                    sort: orderByOption ? orderByOption.query : undefined,
                    limit: 24, // TODO move somewhere else
                },
                mediaBucketKey: mediaBucket.key,
                invalidateCurrentSet: true,
            });
        });
    };

    areSearchResultsScrolledToBottom = () => {
        const componentNode = this.componentRef.current;
        if (!componentNode) return;

        const {mediaBucket} = this.props;
        const componentRect = componentNode.getBoundingClientRect();
        if (mediaBucket
            && !mediaBucket.isLoadingMedia
            && !mediaBucket.isUpdatePending
            && mediaBucket.media.size < mediaBucket.mediaTotalCount
            && window.innerHeight + window.pageYOffset >= componentRect.height
        ) {
            this.loadMoreMedia();
        }
    };

    loadMoreMedia = () => {
        const {mediaBucket, fetchMediaCollectionMedia} = this.props;
        const {orderByValue} = this.state;
        const orderByOption = orderByOptions.find(option => option.value === orderByValue);

        fetchMediaCollectionMedia({
            requestParams: {
                mediaCollectionId: null,
                where: this.getWhereQuery(),
                sort: orderByOption ? orderByOption.query : undefined,
                start: mediaBucket.mediaIds.size,
                limit: 24, // TODO move somewhere else
            },
            mediaBucketKey: mediaBucket.key,
        });
    };

    render() {
        const {mediaBucket, mediaGenres, resolveMediaItemRoute} = this.props;
        const title = 'all series';
        const {filterByValue, orderByValue} = this.state;
        const filterOptions = [
            {
                id: 'reset',
                name: 'All Genres',
            },
            ...mediaGenres.toArray().filter(mediaGenre => mediaGenre.tvShowCount),
        ];

        return (
            <div className="vub-c-media-classification-tv-shows-view" ref={this.componentRef}>
                <ContentRow>
                    <PullDownContent>
                        <div className="vub-c-media-classification-tv-shows-view__filters">
                            <CustomSelect
                                label="Genres"
                                value={filterByValue}
                                onChange={this.navigateToGenre}
                            >
                                {filterOptions.map(option => (
                                    <Option
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.name}
                                    </Option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                label="Sort by"
                                value={orderByValue}
                                onChange={this.onOrderBySelect}
                            >
                                {orderByOptions.map(option => (
                                    <Option
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </Option>
                                ))}
                            </CustomSelect>
                        </div>
                    </PullDownContent>
                </ContentRow>

                {mediaBucket && mediaBucket.media.size > 0 ? (
                    <MediaGallery
                        title={title}
                        subtitle={`(${mediaBucket.mediaTotalCount} ${pluralize('title', mediaBucket.mediaTotalCount)})`}
                    >
                        {mediaBucket.media
                            .toArray()
                            .map(mediaItem => (
                                <MediaGalleryTilePortrait
                                    key={mediaItem.id}
                                    mediaItem={mediaItem}
                                    linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                />
                            ))}
                    </MediaGallery>
                ) : null}

                {mediaBucket && mediaBucket.isLoadingMedia ? (
                    <div className="vub-c-media-classification-tv-shows-view__spinner">
                        <SpinnerStandard className="vub-c-standard-spinner--small" />
                    </div>
                ) : null}

                <BackToTop
                    title="Back to top"
                    screenMultiplicator={2}
                />
            </div>
        );
    }
}

export {MediaClassificationTVShowsView};

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaClassificationTVShowsViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
        mediaGenres: state.media.mediaGenres,
    });
};

const mapDispatchToProps = dispatch => ({
    fetchMediaCollectionMedia: payload => dispatch(mediaActions.fetchMediaCollectionMedia(payload)),
});

export default connect(
    createMapStateToProps,
    mapDispatchToProps,
)(MediaClassificationTVShowsView);
