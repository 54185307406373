import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MobileDetect from 'mobile-detect';
import './CustomSelect.scss';
import CustomDropDownTouchDevice from './CustomDropDownTouchDevice';
import withOverlay from '../overlay/withOverlay';
import {
    withOutsideClick,
    withOutsideClickPropTypes,
    withOutsideClickDefaultProps,
} from '../outside-click/withOutsideClick';

@withOverlay
@withOutsideClick
class CustomSelect extends Component {
    static propTypes = {
        ...withOutsideClickPropTypes,
        label: PropTypes.string,
        value: PropTypes.string,
        children: PropTypes.node,
        onChange: PropTypes.func,
        isTouchDeviceCustomDropDownDisabled: PropTypes.bool,
    };

    static defaultProps = {
        ...withOutsideClickDefaultProps,
        label: null,
        value: null,
        children: null,
        onChange: null,
        isTouchDeviceCustomDropDownDisabled: false,
    };

    state = {
        isActive: false,
    };

    componentDidMount() {
        this.props.subscribeToClickOutside(this.clickOutsideRef, this.onClickOutside);
    }

    componentWillUnmount() {
        this.props.unsubscribeFromClickOutside(this.clickOutsideRef, this.onClickOutside);
    }

    onClickOutside = () => {
        if (!this.props.isOverlayOpened) {
            this.setState({
                isActive: false,
            });
        }
        return false;
    };

    toggleDropDown = () => {
        this.setState(state => ({
            isActive: !state.isActive,
        }));
    };

    // TODO move this logic to option ?
    renderSelectedContent = (options = [], selectedValue, prop = 'value') => {
        const selectedOption = options.find(el => el.props[prop] === selectedValue);
        return selectedOption ? selectedOption.props.children : selectedValue;
    };

    setSelectedValue = (value, options = [], prop = 'value') => value || (options.length && options[0].props[prop]);

    clickOutsideRef = React.createRef();

    render() {
        const {
            label,
            children,
            value,
            onChange,
            className,
            closeOverlay,
            openOverlay,
            isTouchDeviceCustomDropDownDisabled,
        } = this.props;
        const options = React.Children.toArray(children);
        // val equal to value or to the first item value from the options
        const selectedValue = this.setSelectedValue(value, options);
        const updatedChildren = React.Children.map(
            children,
            child => React.cloneElement(child, {onChange, selectedValue})
        );
        // show pop up with native app download option if user on mobile
        const md = new MobileDetect(window.navigator.userAgent);
        const isTouchDevice = md.os() === 'AndroidOS' || md.os() === 'iOS';
        return (
            <div
                onClick={this.toggleDropDown}
                className={`vub-c-custom-select ${className || ''}`}
                ref={this.clickOutsideRef}
            >
                {label && <label className="vub-c-custom-select__label">{label}</label>}
                <div className="vub-c-custom-select__field">
                    <span className="vub-c-custom-select__field-value">
                        {this.renderSelectedContent(options, selectedValue)}
                    </span>
                    <div className="vub-c-custom-select__icon" />
                </div>
                {(this.state.isActive) && (
                    isTouchDevice && !isTouchDeviceCustomDropDownDisabled ? (
                        <CustomDropDownTouchDevice
                            openOverlay={openOverlay}
                            closeOverlay={closeOverlay}
                        >
                            {updatedChildren}
                        </CustomDropDownTouchDevice>
                    ) : (
                        <ul className="vub-c-custom-select__drop-down">
                            {updatedChildren}
                        </ul>
                    ))}
            </div>
        );
    }
}

export default CustomSelect;

