import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import * as selectors from './mediaItemViewSelectors';
import {withMediaCTA, withMediaCTAPropTypes, withMediaCTADefaultProps} from '../media-entitlement/withMediaCTA';
import {getMediaItem, getMediaItemChildren} from '../media/mediaBucketHelpers';
import './MediaItemSeasonView.scss';
import ContentRow from '../layout/ContentRow';
import MediaItemHeroImage from './elements/MediaItemHeroImage';
import MediaItemViewTitle from './elements/MediaItemViewTitle';
import MediaItemViewBoxCover from './elements/MediaItemViewBoxCover';
import MediaItemViewPrimaryInfo from './elements/MediaItemViewPrimaryInfo';
import MediaItemViewContentInfo from './elements/MediaItemViewContentInfo';
import MediaItemViewSynopsis from './elements/MediaItemViewSynopsis';
import MediaItemViewCredits from './elements/MediaItemViewCredits';
import MediaCarouselStandard from '../ui-elements/media-carousel/MediaCarouselStandard';
import MediaCarouselSlideCTADetailed from '../ui-elements/media-carousel-slide/MediaCarouselSlideCTADetailed';
import MediaAccordion from '../ui-elements/media-accordion/MediaAccordion';
import MediaAccordionItem from '../ui-elements/media-accordion/MediaAccordionItem';
import CustomSelect from '../ui-elements/custom-select/CustomSelect';
import Option from '../ui-elements/custom-select/Option';
import MediaItemCTAButtonBarView from '../media-item-cta-button-bar-view/MediaItemCTAButtonBarView';
import MediaItemPreviewButtonView from '../media-item-preview-button-view/MediaItemPreviewButtonView';

@withRouter
@withMediaCTA
@withMediaRouteHelpers({mediaRouteResolver})
class MediaItemSeasonView extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        ...withMediaCTAPropTypes,
        mediaBucket: PropTypes.object,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        ...withMediaCTADefaultProps,
        mediaBucket: null,
    };

    onSeasonSelect = value => {
        const {history, match, resolveMediaItemRoute} = this.props;
        const {mediaItemId, seasonMediaItemId} = match.params;
        if (value === seasonMediaItemId) return;

        if (value === 'all') {
            history.push(resolveMediaItemRoute({mediaItemId}));
            return;
        }

        history.push(resolveMediaItemRoute({mediaItemId: value}));
    };

    render() {
        const {mediaBucket, startMediaItemOfferPlayback, isUserSignedIn} = this.props;
        const {match, history, resolveMediaItemRoute} = this.props;
        const {mediaItemId, seasonMediaItemId} = match.params;
        const tvShowMediaItem = mediaBucket && mediaItemId ? getMediaItem(mediaBucket, mediaItemId) : null;
        const seasonMediaItem = mediaBucket && seasonMediaItemId ? getMediaItem(mediaBucket, seasonMediaItemId) : null;
        const tvShowMediaItemSeasons = tvShowMediaItem ? getMediaItemChildren(mediaBucket, tvShowMediaItem) : null;
        const seasonMediaItemEpisodes = seasonMediaItem ? getMediaItemChildren(mediaBucket, seasonMediaItem) : null;

        const mediaItem = seasonMediaItem;
        const seasonSelectOptions = tvShowMediaItemSeasons ? [
            {id: 'all', value: 'all', name: 'All Seasons'},
            ...tvShowMediaItemSeasons.toArray().map(seasonMediaItem => ({
                id: seasonMediaItem.id,
                value: seasonMediaItem.id,
                name: seasonMediaItem.title,
            })),
        ] : [];

        return mediaItem ? (
            <Fragment>
                <MediaItemHeroImage mediaItem={mediaItem}>
                    <ContentRow>
                        <div className="vub-c-media-item-season-view">
                            <div className="vub-c-media-item-season-view__box-cover">
                                <MediaItemViewBoxCover mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-season-view__title">
                                <MediaItemViewTitle mediaItem={mediaItem} mediaBucket={mediaBucket} />
                            </div>
                            <div className="vub-c-media-item-season-view__main">
                                <MediaItemViewPrimaryInfo mediaItem={mediaItem} />
                                <MediaItemCTAButtonBarView mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-movie-view__preview-button">
                                <MediaItemPreviewButtonView mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-season-view__season-select">
                                <CustomSelect
                                    value={seasonMediaItemId}
                                    onChange={this.onSeasonSelect}
                                >
                                    {seasonSelectOptions.map(option => (
                                        <Option
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </Option>
                                    ))}
                                </CustomSelect>
                            </div>
                            <div className="vub-c-media-item-season-view__content-info">
                                <MediaItemViewContentInfo mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-season-view__synopsis">
                                <MediaItemViewSynopsis mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-season-view__credits">
                                <MediaItemViewCredits mediaItem={mediaItem} />
                            </div>
                        </div>
                    </ContentRow>
                </MediaItemHeroImage>

                {seasonMediaItemEpisodes ? (
                    <Fragment>
                        {/* Carousel display for tablet and desktop view */}
                        <div className="vub-c-media-item-season-view__episodes-carousel">
                            <MediaCarouselStandard
                                title={seasonMediaItem.title}
                                subtitle={`(${seasonMediaItemEpisodes.size} episodes)`}
                                hasPagination
                            >
                                {seasonMediaItemEpisodes.toArray().map(mediaItem => {
                                    const offer = mediaItem.offers.length && mediaItem.offers[0];
                                    const startOfferPlayback = () => startMediaItemOfferPlayback(offer);

                                    return (
                                        <MediaCarouselSlideCTADetailed
                                            key={mediaItem.id}
                                            mediaItem={mediaItem}
                                            linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                            history={history}
                                            onPlayIconClick={startOfferPlayback}
                                            isUserSignedIn={isUserSignedIn}
                                        />
                                    );
                                })}
                            </MediaCarouselStandard>
                        </div>
                        {/* Accordion display for mobile view */}
                        <div className="vub-c-media-item-season-view__episodes-accordion">
                            <MediaAccordion
                                title={seasonMediaItem.title}
                                subtitle={`(${seasonMediaItemEpisodes.size} episodes)`}
                            >
                                {seasonMediaItemEpisodes.toArray().map(mediaItem => {
                                    const startOfferPlayback = () => startMediaItemOfferPlayback(mediaItem);

                                    return (
                                        <MediaAccordionItem
                                            key={mediaItem.id}
                                            mediaItem={mediaItem}
                                            linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                            history={history}
                                            onPlayIconClick={startOfferPlayback}
                                            isUserSignedIn={isUserSignedIn}
                                        />
                                    );
                                })}
                            </MediaAccordion>
                        </div>
                    </Fragment>
                ) : null}
            </Fragment>
        ) : null;
    }
}

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaItemViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
    });
};

export default connect(
    createMapStateToProps,
)(MediaItemSeasonView);
