import {sprintf} from 'sprintf-js';
import resourceEndpoints from './resourceEndpoints';
import handleResponse from '../handleResponse';

/**
 * Create account
 */
export const createAccount = function createAccount(params) {
    const requestEndpoint = `${params.smpAccountAPI.serviceURL}${resourceEndpoints.CREATE_ACCOUNT}`;

    const requestBody = {
        'externalId': params.externalId,
        'emailAddress': params.emailAddress,
        'name': params.name,
        'identityId': params.identityId,
        'sub': params.sub,
        'paymentMethods': [],
    };

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${params.authToken}`,
            },
            method: 'POST',
            body: JSON.stringify(requestBody),
        },
    )
        .then(handleResponse);
};

/**
 * Get account
 */
export const getAccount = function getAccount(params) {
    const resourceEndpoint = sprintf(
        resourceEndpoints.GET_ACCOUNT,
        params.accountId,
    );
    const requestEndpoint = `${params.smpAccountAPI.serviceURL}${resourceEndpoint}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${params.authToken}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};

/**
 * Get account entitlements
 */
export const getAccountEntitlements = function getAccountEntitlements(params) {
    const resourceEndpoint = sprintf(
        resourceEndpoints.GET_ACCOUNT_ENTITLEMENTS,
        params.accountId,
    );
    const requestEndpoint = `${params.smpAccountAPI.serviceURL}${resourceEndpoint}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${params.authToken}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};

/**
 * Get account payment methods
 */
export const getAccountPaymentMethods = function getAccountPaymentMethods(params) {
    const resourceEndpoint = sprintf(
        resourceEndpoints.GET_ACCOUNT_PAYMENT_METHODS,
        params.accountId,
    );
    const requestEndpoint = `${params.smpAccountAPI.serviceURL}${resourceEndpoint}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${params.authToken}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};
