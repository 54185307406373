import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {renderRoutes} from 'react-router-config';
import createHistory from 'history/createBrowserHistory';
import routes from './routes';
import configureStore from './store';
import rootSaga from './saga';
import './styles/index.scss';
import registerServiceWorker from './application/registerServiceWorker';

// initialize history
const history = createHistory();

// initialize store
const store = configureStore(window.__PRELOADED_STATE__ || {}, history); // eslint-disable-line
store.runSaga(rootSaga);

// enable initial state garbage collection
delete window.__PRELOADED_STATE__; // eslint-disable-line

// Index component
const Index = () => (
    <AppContainer>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                {renderRoutes(routes)}
            </ConnectedRouter>
        </Provider>
    </AppContainer>
);

if (!process.env.SSR_ENABLED) {
    ReactDOM.render(<Index />, document.getElementById('root'));
} else {
    ReactDOM.hydrate(<Index />, document.getElementById('root'));
}

// webpack hot module replacement for ./routes configuration,
// ./saga configuration and state is not hot reloaded but needs to
// be accepted so HMR can work properly
if (module.hot) {
    module.hot.accept(['./routes', './saga'], () => {
        ReactDOM.render(
            (
                <AppContainer>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            {renderRoutes(routes)}
                        </ConnectedRouter>
                    </Provider>
                </AppContainer>
            ),
            document.getElementById('root')
        );
    });
}

registerServiceWorker();
