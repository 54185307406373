import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import * as selectors from './mediaItemViewSelectors';
import {getMediaItem} from '../media/mediaBucketHelpers';
import './MediaItemMovieView.scss';
import ContentRow from '../layout/ContentRow';
import MediaItemHeroImage from './elements/MediaItemHeroImage';
import MediaItemViewTitle from './elements/MediaItemViewTitle';
import MediaItemViewBoxCover from './elements/MediaItemViewBoxCover';
import MediaItemViewPrimaryInfo from './elements/MediaItemViewPrimaryInfo';
import MediaItemViewContentInfo from './elements/MediaItemViewContentInfo';
import MediaItemViewSynopsis from './elements/MediaItemViewSynopsis';
import MediaItemViewCredits from './elements/MediaItemViewCredits';
import MediaGallery from '../ui-elements/media-gallery/MediaGallery';
import MediaGalleryTilePortrait from '../ui-elements/media-gallery-tile/MediaGalleryTilePortrait';
import MediaItemCTAButtonBarView from '../media-item-cta-button-bar-view/MediaItemCTAButtonBarView';
import MediaItemPreviewButtonView from '../media-item-preview-button-view/MediaItemPreviewButtonView';

@withRouter
@withMediaRouteHelpers({mediaRouteResolver})
class MediaItemMovieView extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        mediaBucket: PropTypes.object,
        recommendationsMediaBucket: PropTypes.object,
        match: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        mediaBucket: null,
        recommendationsMediaBucket: null,
    };

    render() {
        const {mediaBucket, recommendationsMediaBucket, match, resolveMediaItemRoute} = this.props;
        const {mediaItemId} = match.params;
        const mediaItem = mediaBucket && mediaItemId ? getMediaItem(mediaBucket, mediaItemId) : null;

        return mediaItem ? (
            <Fragment>
                <MediaItemHeroImage mediaItem={mediaItem}>
                    <ContentRow>
                        <div className="vub-c-media-item-movie-view">
                            <div className="vub-c-media-item-movie-view__box-cover">
                                <MediaItemViewBoxCover mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-movie-view__title">
                                <MediaItemViewTitle mediaItem={mediaItem} mediaBucket={mediaBucket} />
                            </div>
                            <div className="vub-c-media-item-movie-view__main">
                                <MediaItemViewPrimaryInfo mediaItem={mediaItem} />
                                <MediaItemCTAButtonBarView mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-movie-view__preview-button">
                                <MediaItemPreviewButtonView mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-movie-view__content-info">
                                <MediaItemViewContentInfo mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-movie-view__synopsis">
                                <MediaItemViewSynopsis mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-movie-view__credits">
                                <MediaItemViewCredits mediaItem={mediaItem} />
                            </div>
                        </div>
                    </ContentRow>
                </MediaItemHeroImage>

                {recommendationsMediaBucket && recommendationsMediaBucket.media.size > 0 ? (
                    <MediaGallery title="You might also like">
                        {recommendationsMediaBucket.media
                            .toArray()
                            .map(mediaItem => (
                                <MediaGalleryTilePortrait
                                    key={mediaItem.id}
                                    mediaItem={mediaItem}
                                    linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                />
                            ))}
                    </MediaGallery>
                ) : null}
            </Fragment>
        ) : null;
    }
}

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaItemViewBucketSelector();
    const recommendationsMediaBucketSelector = selectors.createMediaItemRecommendationsViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
        recommendationsMediaBucket: recommendationsMediaBucketSelector(state, props),
    });
};

export default connect(
    createMapStateToProps,
)(MediaItemMovieView);
