import jwtDecode from 'jwt-decode';
import store from 'store';
import * as ccIdentityAPIResourceMethods from './cc-identity-api/resourceMethods';
import * as ccAPIResourceMethods from './cc-api/resourceMethods';
import * as responseHelpers from './responseHelpers';

/**
 * Injects service parameters into API resource method
 */
const injectServiceParameters = (apiResourceMethod, serviceParameters) => params => apiResourceMethod({
    ...serviceParameters,
    ...params,
});

/**
 * Vubiquity WLS media entitlement provider for SMP identity API
 */
export default class MediaEntitlementProviderSMP {
    /**
     * @param {Object} serviceParameters
     * @returns {this}
     */
    constructor(serviceParameters = {}) {
        this.authToken = null;
        this.accountId = null;
        this.accountBasket = [];

        /**
         * @type {Object}
         * @property {function} getCCIdentityToken
         * @property {function} resolvePlaybackSource
         */
        this.resources = [
            ccIdentityAPIResourceMethods,
            ccAPIResourceMethods,
        ].reduce((resources, apiResourceMethods) => {
            const apiResources = Object.keys(apiResourceMethods).reduce((apiResources, methodKey) => {
                apiResources[methodKey] = injectServiceParameters(
                    apiResourceMethods[methodKey],
                    {
                        ...serviceParameters,
                        authToken: this.authToken,
                    },
                );
                return apiResources;
            }, {});

            return {
                ...resources,
                ...apiResources,
            };
        }, {});

        const {mediaProviderCCSearchInMemory} = serviceParameters;
        this.mediaIndexPromise = mediaProviderCCSearchInMemory.mediaIndexPromise;
        this.offerHash = null;
    }

    createOfferHash(mediaIndex) {
        if (this.offerHash) return this.offerHash;

        this.offerHash = mediaIndex.reduce((offerHash, mediaItem) => {
            const mediaItemOfferHash = mediaItem.offers.reduce((mediaItemOfferHash, offer) => {
                mediaItemOfferHash[offer.id] = offer;
                return mediaItemOfferHash;
            }, {});

            return {
                ...offerHash,
                ...mediaItemOfferHash,
            };
        }, {});

        return this.offerHash;
    }

    persistEntitlements(entitlementDTOs) {
        let storedAccountMediaEntitlementIds = store.get(`wls_smp_media_entitlements_${this.accountId}`);
        if (!storedAccountMediaEntitlementIds) {
            storedAccountMediaEntitlementIds = JSON.stringify([]);
            store.set(`wls_smp_media_entitlements_${this.accountId}`, storedAccountMediaEntitlementIds);
        }

        const accountMediaEntitlementIds = [
            ...JSON.parse(storedAccountMediaEntitlementIds),
            ...entitlementDTOs.map(entitlementDTO => entitlementDTO.id),
        ];
        store.set(`wls_smp_media_entitlements_${this.accountId}`, JSON.stringify(accountMediaEntitlementIds));
    }

    setAuthToken(authToken) {
        if (!authToken) {
            this.authToken = null;
            this.accountId = null;
            this.accountBasket = [];
            return;
        }

        const jwt = jwtDecode(authToken);
        const {account_id: accountId} = jwt;
        this.authToken = authToken;
        this.accountId = accountId;
        this.accountBasket = [];
    }

    checkoutSingleMediaItem = async function checkoutSingleMediaItem(params) {
        const {mediaIndex} = await this.mediaIndexPromise;
        const offerHash = this.createOfferHash(mediaIndex);
        const offer = offerHash[params.offerId];

        const basketItemDTO = responseHelpers.createBasketItemDTO(offer);
        this.accountBasket = [basketItemDTO];

        return responseHelpers.delayResult(basketItemDTO);
    };

    chargeBasket = async function chargeBasket() {
        const {mediaIndex} = await this.mediaIndexPromise;
        const offerHash = this.createOfferHash(mediaIndex);
        const entitlementDTOs = [];

        this.accountBasket.forEach(basketItemDTO => {
            const offer = offerHash[basketItemDTO.offerId];
            const entitlementDTO = responseHelpers.createEntitlementDTO({offer, accountId: this.accountId});
            entitlementDTOs.push(entitlementDTO);
        });
        this.persistEntitlements(entitlementDTOs);

        return responseHelpers.delayResult(entitlementDTOs);
    };

    createPurchaseOrderItemSVOD() {
        // no-op
    }

    getCurrentEntitlements = async function getCurrentEntitlements() {
        const {mediaIndex} = await this.mediaIndexPromise;
        const offerHash = this.createOfferHash(mediaIndex);

        let storedAccountMediaEntitlementIds = store.get(`wls_smp_media_entitlements_${this.accountId}`);
        if (!storedAccountMediaEntitlementIds) {
            storedAccountMediaEntitlementIds = JSON.stringify([]);
            store.set(`wls_smp_media_entitlements_${this.accountId}`, storedAccountMediaEntitlementIds);
        }

        const accountMediaEntitlementIds = JSON.parse(storedAccountMediaEntitlementIds);
        const mediaEntitlementDTOs = accountMediaEntitlementIds.map(mediaEntitlementId => {
            const [offerId, purchaseOrderId] = mediaEntitlementId.split('_');
            const offer = offerHash[offerId];

            return responseHelpers.createEntitlementDTO({offer, accountId: this.accountId, purchaseOrderId});
        });

        return responseHelpers.delayResult(mediaEntitlementDTOs);
    };

    getMediaItemPlaybackSource(params) {
        const resources = this.resources;

        return resources.getCCIdentityToken(params)
            .then(ccIdentityTokenDTO => {
                return resources.resolvePlaybackSource({
                    assetId: params.assetId,
                    assetOfferId: params.assetOfferId,
                    ccIdentityToken: ccIdentityTokenDTO.access_token,
                })
                    .then(playbackSourceDTO => ({
                        url: playbackSourceDTO.Url,
                        type: 'application/vnd.ms-sstr+xml',
                        protectionInfo: playbackSourceDTO.ProtectionInfo,
                    }));
            });
    }
}
