import {createSelector} from 'reselect';
import Immutable from 'immutable';
import * as navigationSelectors from '../navigation/navigationSelectors';
import storefrontRowTypes from '../navigation/storefrontRowTypes';
import * as mediaSelectors from '../media/mediaSelectors';
import * as mediaEntitlementSelectors from '../media-entitlement/mediaEntitlementSelectors';
import MediaItemPlaybackSource from '../media-entitlement/records/MediaItemPlaybackSource';
import mediaPreviewImageCreative from '../assets/images/mwc_placeholder_creative_white.jpg';
import mediaPreviewImageNewYear from '../assets/images/mwc_placeholder_new_year.png';

const getMediaBucketMedia = function getMediaBucketMedia(mediaBucketKey, mediaBuckets, mediaItems) {
    const mediaBucket = mediaBuckets.get(mediaBucketKey);
    let media = Immutable.OrderedMap();
    if (mediaBucket) {
        mediaBucket.mediaIds.forEach(mediaItemId => {
            media = media.set(mediaItemId, mediaItems.get(mediaItemId));
        });
    }

    return media;
};

export const createStorefrontRowsSelector = () => createSelector(
    [
        navigationSelectors.getStorefrontRows,
        mediaSelectors.getMediaBuckets,
        mediaSelectors.getMediaItems,
        mediaEntitlementSelectors.getMediaPreviewPlaybackSources,
    ],
    (storefrontRows, mediaBuckets, mediaItems, mediaPreviewPlaybackSources) => {
        let storefrontRowsWithData = Immutable.OrderedMap();
        storefrontRows.forEach(storefrontRow => {
            if (storefrontRow.type === storefrontRowTypes.MEDIA_GENRE) {
                const {id: rowId, value: mediaGenreId} = storefrontRow;
                const mediaBucketKey = `STOREFRONT_VIEW_MEDIA_GENRE['${rowId}']['${mediaGenreId}']`;
                const media = getMediaBucketMedia(mediaBucketKey, mediaBuckets, mediaItems);

                storefrontRowsWithData = storefrontRowsWithData.set(storefrontRow.id, storefrontRow.merge({
                    data: media,
                }));
            }

            if (storefrontRow.type === storefrontRowTypes.MEDIA_COLLECTION) {
                const {id: rowId, value: mediaCollectionId} = storefrontRow;
                const mediaBucketKey = `STOREFRONT_VIEW_MEDIA_COLLECTION['${rowId}']['${mediaCollectionId}']`;
                const media = getMediaBucketMedia(mediaBucketKey, mediaBuckets, mediaItems);

                storefrontRowsWithData = storefrontRowsWithData.set(storefrontRow.id, storefrontRow.merge({
                    data: media,
                }));
            }

            if (storefrontRow.type === storefrontRowTypes.MEDIA_SET) {
                const {id: rowId} = storefrontRow;
                const mediaBucketKey = `STOREFRONT_VIEW_MEDIA_SET['${rowId}']`;
                const media = getMediaBucketMedia(mediaBucketKey, mediaBuckets, mediaItems);
                const mediaItem = media.first();
                // TODO custom video link should be loaded from admin-panel
                // const mediaItemPreviewPlaybackSource = mediaItem
                //     ? mediaPreviewPlaybackSources.get(mediaItem.id) : null;
                const playbackSources = [
                    `//computerrockmedia-euwe.streaming.media.azure.net/f2d13b83-ccd9-40cb-934a-a81a2aa8daf8/CReative1920x760.ism/manifest`,
                    `//computerrockmedia-euwe.streaming.media.azure.net/32664030-1033-4d6d-8cf1-fde183b71e59/CELL_New_Year_1920x760.ism/manifest`,
                ];
                const boxCoverImages = [mediaPreviewImageCreative, mediaPreviewImageNewYear];
                const mediaItemPreviews = playbackSources.map((url, index) => {
                    return {
                        source: new MediaItemPlaybackSource({
                            url,
                            type: 'application/vnd.ms-sstr+xml',
                        }),
                        imageBoxCover: boxCoverImages[index],
                        playbackStartAt: index === 0 ? 1000 : 0,
                    };
                });
                storefrontRowsWithData = storefrontRowsWithData.set(storefrontRow.id, storefrontRow.merge({
                    data: {
                        mediaItem,
                        mediaItemPreviews,
                    },
                }));
            }
        });

        return storefrontRowsWithData;
    },
);
