import React from 'react';
import PropTypes from 'prop-types';
import './BackgroundPlayerControls.scss';

const BackgroundPlayerControls = ({
    mediaItem,
    isPaused,
    isMuted,
    toggleMute,
    togglePlayback,
    closePlayer,
    isMobileDevice,
}) => (
    <div className="vub-c-player-controls-wrapper">
        {isMobileDevice ? (
            <div
                className="vub-c-player-controls__playback-field"
                onClick={togglePlayback}
            />
        ) : (
            <div className="vub-c-player-controls">
                <div
                    className={`vub-c-player-controls__playback vub-c-player-controls__playback--${isPaused ? 'play' : 'pause'}`}
                    onClick={togglePlayback}
                />
                <div className="vub-c-player-controls__sound-wrapper">
                    <div className="vub-c-player-controls__text">sound</div>
                    <div
                        className={`vub-c-player-controls__sound vub-c-player-controls__sound--${isMuted ? 'muted' : 'unmuted'}`}
                        onClick={toggleMute}
                    />
                </div>
            </div>
        )}
    </div>
);


BackgroundPlayerControls.propTypes = {
    mediaItem: PropTypes.object,
    isPaused: PropTypes.bool,
    isMuted: PropTypes.bool,
    isMobileDevice: PropTypes.bool,
    toggleMute: PropTypes.func,
    togglePlayback: PropTypes.func,
    closePlayer: PropTypes.func,
};

BackgroundPlayerControls.defaultProps = {
    mediaItem: null,
    isPaused: null,
    isMuted: null,
    isMobileDevice: null,
    toggleMute: null,
    togglePlayback: null,
    closePlayer: null,
};

export default BackgroundPlayerControls;

