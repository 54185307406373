import React from 'react';
import {Link} from 'react-router-dom';
import routePaths from '../../routePaths';
import resolveRoute from '../../navigation/resolveRoute';
import './LogoHeader.scss';

const LogoHeader = () => (
    <Link to={resolveRoute(routePaths.STOREFRONT)} className="vub-c-logo-header" />
);

export default LogoHeader;
