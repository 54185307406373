import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import {createStorefrontRowsSelector} from './storefrontViewSelectors';
import MediaHeroBannerStandardRow from './elements/MediaHeroBannerStandardRow';
import MediaHeroBannerPreviewRow from './elements/MediaHeroBannerPreviewRow';
import MediaCarouselStandardRow from './elements/MediaCarouselStandardRow';
import MediaStripBannerStandardRow from './elements/MediaStripBannerStandardRow';
import BackToTop from '../ui-elements/BackToTop';
// import LoadMore from '../ui-elements/LoadMore';

const StorefrontView = ({storefrontRows, resolveMediaItemRoute, mediaItemPlaybackSource}) => (
    <div className="vub-c-storefront-view">
        {storefrontRows.toArray().map(storefrontRow => {
            // Media hero banner
            if (storefrontRow.template === 'MediaHeroBannerStandard') {
                return (
                    <MediaHeroBannerStandardRow
                        key={storefrontRow.id}
                        storefrontRow={storefrontRow}
                        resolveMediaItemRoute={resolveMediaItemRoute}
                    />
                );
            }

            // Media hero banner preview
            if (storefrontRow.template === 'MediaHeroBannerPreview') {
                return (
                    <MediaHeroBannerPreviewRow
                        key={storefrontRow.id}
                        storefrontRow={storefrontRow}
                        isPlaybackEnabled={!mediaItemPlaybackSource}
                    />
                );
            }

            // Media carousel
            if (storefrontRow.template === 'MediaCarouselStandard') {
                return (
                    <MediaCarouselStandardRow
                        key={storefrontRow.id}
                        storefrontRow={storefrontRow}
                        resolveMediaItemRoute={resolveMediaItemRoute}
                    />
                );
            }

            // Media strip banner
            if (storefrontRow.template === 'MediaStripBannerStandard') {
                return (
                    <MediaStripBannerStandardRow key={storefrontRow.id} storefrontRow={storefrontRow} />
                );
            }

            return null;
        })}

        {/* <LoadMore /> */}
        <BackToTop
            title="Back to top"
            screenMultiplicator={2}
        />
    </div>
);

StorefrontView.propTypes = {
    ...withMediaRouteHelpersPropTypes,
    storefrontRows: PropTypes.object,
    mediaItemPlaybackSource: PropTypes.object,
};

StorefrontView.defaultProps = {
    ...withMediaRouteHelpersDefaultProps,
    storefrontRows: Immutable.OrderedMap(),
    mediaItemPlaybackSource: null,
};

export {StorefrontView};

const createMapStateToProps = () => {
    const storefrontRowsSelector = createStorefrontRowsSelector();
    return (state, props) => ({
        storefrontRows: storefrontRowsSelector(state, props),
        mediaItemPlaybackSource: state.mediaEntitlement.mediaItemPlaybackSource,
    });
};

export default connect(
    createMapStateToProps,
)(withMediaRouteHelpers({mediaRouteResolver})(StorefrontView));
