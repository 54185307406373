import Immutable from 'immutable';
import moment from 'moment';

/**
 * MediaBucket definition
 *
 * @typedef {Immutable.Record} MediaBucket
 * @property {string} key
 * @property {?number} mediaTotalCount
 * @property {Array<string>} mediaIds - Ids of all media items
 * @property {Array<MediaItem>} media - mediaItems, set by selector before passing through props
 * @property {Array<string>} primaryMediaIds - Ids of primary media items
 * @property {boolean} isUpdatePending
 * @property {boolean} isLoadingMedia
 */

/**
 * MediaBucket record
 *
 * @type {MediaBucket}
 */
class MediaBucket extends Immutable.Record({
    key: null,
    mediaTotalCount: null,
    mediaIds: Immutable.List(),
    media: Immutable.OrderedMap(),
    primaryMediaIds: Immutable.List(), // TODO should be returned from MediaService
    isUpdatePending: false,
    isLoadingMedia: false,
    createTime: moment(),
    updateTime: moment(),
}) {}

export default MediaBucket;
