import {put, take, call} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {providerAuthenticationType, providerAuthenticationProtocol} from '../../vub-wls-auth-service';
import * as actionTypes from '../authActionTypes';
import routePaths from '../../routePaths';
import {authService} from '../../services';
import config from '../../config';

/**
 * Update user flow
 */
const updateUserFlow = function* updateUserFlow() {
    while (true) {
        yield take(actionTypes.UPDATE_USER);

        if (authService.authenticationType === providerAuthenticationType.BUILT_IN) {
            yield put(push(routePaths.ACCOUNT_SETTINGS));
        }

        if (authService.authenticationType === providerAuthenticationType.SINGLE_SIGN_ON) {
            switch (authService.authenticationProtocol) {
                case providerAuthenticationProtocol.OAUTH2:
                    if (config.BASE_URL) {
                        const accountSettingUri = authService.getAccountSettingsUri();
                        yield call(() => window.location.replace(accountSettingUri));
                    }
                    break;

                default:
                // no-op
            }
        }
    }
};

export default updateUserFlow;
