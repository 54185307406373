import {call, put, race, take} from 'redux-saga/effects';
import * as actionTypes from '../authActionTypes';
import {authService} from '../../services';

const userSessionFlow = function* userSessionFlow(authToken) {
    yield put({
        type: actionTypes.USER_SESSION_STARTED,
        payload: {authToken},
    });

    const {userSessionExpired, signOutAction} = yield race({
        userSessionExpired: call(authService.hasUserSessionExpired),
        signOutAction: take(actionTypes.SIGN_OUT_USER),
        userSessionStartedAction: take(actionTypes.USER_SESSION_STARTED),
    });

    if (signOutAction) {
        yield call(authService.endUserSession);
    }

    if (userSessionExpired || signOutAction) {
        yield put({
            type: actionTypes.USER_SESSION_ENDED,
            payload: {},
        });
    }
};

export default userSessionFlow;
