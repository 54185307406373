import React from 'react';
import PropTypes from 'prop-types';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import './MediaAccordionItem.scss';
import StandardIcon from '../StandardIcon';
import {
    iconPlaySolid,
    iconMore,
} from '../icons';

const MediaAccordionItem = ({
    mediaItem,
    activeItemId,
    handleItemClick,
    onPlayIconClick,
    linkTo,
    history,
    isUserSignedIn,
}) => {
    const handleClick = id => {
        handleItemClick(id);
    };

    const handleMoreClick = linkTo => {
        history.push(linkTo);
    };

    let mediaItemTitle = mediaItem ? mediaItem.title : null;
    if (mediaItem && mediaItem.classification === mediaItemClassification.EPISODE) {
        mediaItemTitle = `${mediaItem.siblingIndex}. ${mediaItem.title}`;
    }

    return (
        <div className={`vub-c-media-accordion-item ${activeItemId === mediaItem.id ? 'vub-c-media-accordion-item--active' : ''}`} onClick={() => handleClick(mediaItem.id)}>
            <div className="vub-c-media-accordion-item__info">
                <span className="vub-c-media-accordion-item__info-title">{mediaItemTitle}</span>
                <div className="vub-c-media-accordion-item__buttons">
                    {isUserSignedIn && (
                        <StandardIcon
                            className="vub-c-icon--medium vub-c-media-accordion-item__icon-play"
                            icon={iconPlaySolid}
                            onClick={onPlayIconClick}
                        />
                    )}
                    <StandardIcon
                        className="vub-c-icon--medium vub-c-media-accordion-item__icon-more"
                        icon={iconMore}
                        onClick={() => handleMoreClick(linkTo)}
                    />
                </div>
            </div>
            {activeItemId === mediaItem.id
               && (
               <div className="vub-c-media-accordion-item__more-info">
                   <p className="vub-c-media-accordion-item__more-info-time">{mediaItem.durationMinutes}</p>
                   <span className="vub-c-media-accordion-item__more-info-text">{mediaItem.synopsis}</span>
               </div>
               )
           }
        </div>
    );
};

MediaAccordionItem.propTypes = {
    history: PropTypes.object.isRequired,
    mediaItem: PropTypes.object,
    activeItemId: PropTypes.string,
    handleItemClick: PropTypes.func,
    onPlayIconClick: PropTypes.func,
    linkTo: PropTypes.object,
    isUserSignedIn: PropTypes.bool,
};

MediaAccordionItem.defaultProps = {
    mediaItem: null,
    activeItemId: null,
    handleItemClick: null,
    onPlayIconClick: null,
    linkTo: {pathname: ''},
    isUserSignedIn: null,
};

export default MediaAccordionItem;
