import {put} from 'redux-saga/effects';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import * as actionTypes from './mediaActionTypes';

/**
 * Load media search provider media
 *
 * @param {FluxStandardAction} action
 */
export const loadMediaForMediaSearchView = function* loadMediaForMediaSearchView({payload}) {
    const {match} = payload;
    const {searchQueryString} = match.params;
    const mediaBucketKey = `MEDIA_SEARCH_VIEW`;

    yield put({
        type: actionTypes.SEARCH_MEDIA,
        payload: {
            requestParams: {
                searchQueryString,
                where: [
                    `classification:in:${mediaItemClassification.MOVIE},${mediaItemClassification.TV_SHOW}`,
                ],
                limit: 24,
            },
            mediaBucketKey,
            invalidateCurrentSet: true,
        },
    });
};
