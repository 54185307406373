import React from 'react';
import PropTypes from 'prop-types';
import './DisablePlaybackContent.scss';
import CTAButton from '../../CTAButton';
import withPopUp from '../withPopUp';

const title = 'please note';
const description = 'Playback has been disabled in this service.';

const DisablePlaybackContent = ({closePopUp}) => (
    <div className="vub-c-disable-playback-content">
        <div className="vub-c-disable-playback-content__header">
            <div className="vub-c-disable-playback-content__title">{title}</div>
            <div className="vub-c-disable-playback-content__close" onClick={closePopUp} />
        </div>
        <div className="vub-c-disable-playback-content__body">
            <div className="vub-c-disable-playback-content__description">
                {description}
            </div>
            <div className="vub-c-disable-playback-content__buttons">
                <CTAButton
                    onClick={closePopUp}
                >
                    Ok
                </CTAButton>
            </div>
        </div>
    </div>
);

DisablePlaybackContent.propTypes = {
    closePopUp: PropTypes.func,
};

DisablePlaybackContent.defaultProps = {
    closePopUp: null,
};

export default withPopUp(DisablePlaybackContent);
