import React, {Component, Children} from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import {BEMClassNameResolver, BEMClassNameResolverPropTypes, BEMClassNameResolverDefaultProps} from './BEMClassNameResolver';
import './Carousel.scss';

const CarouselControls = ({
    children,
    currentPage,
    carouselPages,
    rotateCarouselLeft,
    rotateCarouselRight,
    goToPage,
    resolveClassName,
}) => {
    children = Children.map(children, child => (typeof child === 'string' ? child // TODO check if safety needed here
        : React.cloneElement(child, {
            currentPage: currentPage,
            carouselPages: carouselPages,
            goToPage: goToPage,
            resolveClassName: resolveClassName,
        })));

    return (
        <div className={resolveClassName('__controls')}>
            <button
                type="button"
                className={resolveClassName(
                    '__button-left',
                    currentPage === 0 && 'cr-is-disabled'
                )}
                disabled={currentPage === 0}
                onClick={rotateCarouselLeft}
            >
                left
            </button>
            {children}
            <button
                type="button"
                className={resolveClassName(
                    '__button-right',
                    (currentPage === carouselPages.length - 1 || carouselPages.length === 0) && 'cr-is-disabled'
                )}
                disabled={currentPage === carouselPages.length - 1}
                onClick={rotateCarouselRight}
            >
                right
            </button>
        </div>
    );
};

CarouselControls.propTypes = {
    currentPage: PropTypes.number,
    carouselPages: PropTypes.array,
    rotateCarouselLeft: PropTypes.func,
    rotateCarouselRight: PropTypes.func,
    goToPage: PropTypes.func,
    resolveClassName: PropTypes.func,
};

CarouselControls.defaultProps = {
    currentPage: 0,
    carouselPages: [],
    rotateCarouselLeft: null,
    rotateCarouselRight: null,
    goToPage: null,
    resolveClassName: null,
};

const CarouselPagination = ({currentPage, carouselPages, goToPage, resolveClassName}) => (
    <div className={resolveClassName('__pagination')}>
        {carouselPages.map(page => {
            const goToSelectedPage = () => goToPage(page);
            return (
                <button
                    key={page.id}
                    type="button"
                    className={resolveClassName(
                        '__button-page',
                        currentPage === page.id && 'cr-is-disabled'
                    )}
                    disabled={currentPage === page.id}
                    onClick={goToSelectedPage}
                >
                    {page.id}
                </button>
            );
        })}
    </div>
);

CarouselPagination.propTypes = {
    currentPage: PropTypes.number,
    carouselPages: PropTypes.array,
    goToPage: PropTypes.func,
    resolveClassName: PropTypes.func,
};

CarouselPagination.defaultProps = {
    currentPage: 0,
    carouselPages: [],
    goToPage: null,
    resolveClassName: null,
};

@BEMClassNameResolver('cr-c-carousel')
class Carousel extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
    };

    constructor(props) {
        super(props);
        this.onWindowResize = throttle(this.onWindowResize, 350);
    }

    state = {
        slideTrayOffset: 0,
        currentPage: 0,
    };

    componentDidMount() {
        this.windowWidth = window.innerWidth;
        window.addEventListener('resize', this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    viewingFrameRef = null;

    /**
     * Carousel pages
     *
     * @type {Array<Object>}
     */
    carouselPages = [];

    /**
     * Sets ref to viewingFrame DOM node
     *
     * @param element
     */
    setViewingFrameRef = element => {
        if (element) {
            this.viewingFrameRef = element;
            this.setCarouselPages();
            // TODO ugly setTimeout hack
            // setTimeout(() => this.setCarouselPages(), 0);

            // TODO refactor touch
            this.viewingFrameRef.addEventListener('touchstart', this.handleTouchStart);
            this.viewingFrameRef.addEventListener('touchmove', this.handleTouchMove);
            this.viewingFrameRef.addEventListener('touchend', this.handleTouchEnd);
        } else {
            this.viewingFrameRef.removeEventListener('touchstart', this.handleTouchStart);
            this.viewingFrameRef.removeEventListener('touchmove', this.handleTouchMove);
            this.viewingFrameRef.removeEventListener('touchend', this.handleTouchEnd);

            this.viewingFrameRef = element;
            this.carouselPages = [];
        }
    };

    previousTouchEvent = {};

    windowWidth = null;

    handleTouchStart = event => {
        this.previousTouchEvent = event;
    };

    handleTouchMove = event => {
        if (this.previousTouchEvent.changedTouches[0].clientX - event.changedTouches[0].clientX > 100) {
            this.rotateCarouselRight();
            this.previousTouchEvent = event;
        }
        if (event.changedTouches[0].clientX - this.previousTouchEvent.changedTouches[0].clientX > 100) {
            this.rotateCarouselLeft();
            this.previousTouchEvent = event;
        }
    };

    handleTouchEnd = () => {
        this.previousTouchEvent = {};
    };

    /**
     * Calculates carousel pages for given viewing frame and set of slides and their widths
     */
    setCarouselPages = () => {
        if (!this.viewingFrameRef) return;

        const slideNodeList = this.viewingFrameRef.querySelectorAll('.js-carousel-slide-tray .js-carousel-slide');
        const viewingFrameRect = this.viewingFrameRef.getBoundingClientRect();
        const slideNodeRectList = [];
        Array.prototype.forEach.call(slideNodeList, node => slideNodeRectList.push(node.getBoundingClientRect()));
        const slideTrayWidth = slideNodeRectList
            .reduce((slideTrayWidth, slideNodeRect) => slideTrayWidth + slideNodeRect.width, 0);

        this.carouselPages = slideNodeRectList.reduce((carouselPages, slideNodeRect, index) => {
            if (carouselPages.length === 0) carouselPages.push({id: 0, x: 0, slidesWidth: 0});

            const page = carouselPages[carouselPages.length - 1];

            if (page.slidesWidth + slideNodeRect.width <= viewingFrameRect.width) {
                page.slidesWidth += slideNodeRect.width;
            } else {
                const newPage = {
                    id: page.id + 1,
                    x: page.x + page.slidesWidth,
                    slidesWidth: slideNodeRect.width,
                };
                carouselPages.push(newPage);
            }

            if (index === slideNodeRectList.length - 1) {
                const lastPage = carouselPages[carouselPages.length - 1];
                carouselPages[carouselPages.length - 1] = {
                    id: lastPage.id,
                    x: slideTrayWidth - viewingFrameRect.width,
                    slidesWidth: lastPage.slidesWidth,
                };
            }

            return carouselPages;
        }, []);
    };

    onWindowResize = () => {
        if (this.windowWidth !== window.innerWidth) {
            this.windowWidth = window.innerWidth;
            this.resetCarousel();
        }
    };

    resetCarousel = () => {
        this.setCarouselPages();
        this.setState({
            slideTrayOffset: 0,
            currentPage: 0,
        });
    };

    /**
     * Rotates carousel one page left
     */
    rotateCarouselLeft = () => {
        if (this.state.currentPage === 0) return;

        this.setState(state => ({
            currentPage: state.currentPage - 1,
            slideTrayOffset: this.carouselPages[state.currentPage - 1].x * -1,
        }));
    };

    /**
     * Rotates carousel one page right
     */
    rotateCarouselRight = () => {
        if (this.state.currentPage === this.carouselPages.length - 1) return;

        this.setState(state => ({
            currentPage: state.currentPage + 1,
            slideTrayOffset: this.carouselPages[state.currentPage + 1].x * -1,
        }));
    };

    /**
     * Rotates carousel to given page
     *
     * @param {Object} page
     */
    goToPage = page => {
        const index = page.id;
        if (this.state.currentPage === index || !this.carouselPages[index]) return;

        this.setState({
            currentPage: index,
            slideTrayOffset: this.carouselPages[index].x * -1,
        });
    };

    render() {
        const {resolveClassName} = this.props;
        const children = Children.map(this.props.children, child => (typeof child === 'string' ? child
            : React.cloneElement(child, {
                slideTrayOffset: this.state.slideTrayOffset,
                currentPage: this.state.currentPage,
                rotateCarouselLeft: this.rotateCarouselLeft,
                rotateCarouselRight: this.rotateCarouselRight,
                goToPage: this.goToPage,
                setViewingFrameRef: this.setViewingFrameRef,
                carouselPages: this.carouselPages,
                resolveClassName: resolveClassName,
            })));

        return (
            <div className={resolveClassName()}>
                {children}
            </div>
        );
    }
}

// we must use class as refs are not working with functional components
// eslint-disable-next-line react/no-multi-comp
class CarouselSlideTray extends Component {
    static propTypes = {
        slideTrayOffset: PropTypes.number,
        setViewingFrameRef: PropTypes.func,
        resolveClassName: PropTypes.func,
    };

    static defaultProps = {
        slideTrayOffset: 0,
        setViewingFrameRef: null,
        resolveClassName: null,
    };

    render() {
        const {children} = this.props;
        const {resolveClassName} = this.props;
        const {slideTrayOffset, setViewingFrameRef} = this.props;
        const slideTrayStyle = {
            left: `${slideTrayOffset}px`,
        };

        return (
            <div className={resolveClassName('__viewing-frame')} ref={setViewingFrameRef}>
                <div className={resolveClassName('__slide-tray', 'js-carousel-slide-tray')} style={slideTrayStyle}>
                    {children}
                </div>
            </div>
        );
    }
}

export {Carousel as default, Carousel, CarouselSlideTray, CarouselControls, CarouselPagination};
