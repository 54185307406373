/**
 * FFAPI resource endpoints
 */
export default {
    // GET /api/Identity/Validate/{affiliateId}
    IDENTITY_VALIDATE: '/api/Identity/Validate/%s',

    // POST /api/Account/{affiliateId}
    ACCOUNT: '/api/Account/%s/',

    // PUT /api/Account/ChangePassword/{affiliateId}
    ACCOUNT_CHANGE_PASSWORD: '/api/Account/ChangePassword/%s',

    // GET /api/Account/SendResetPasswordEmail/{affiliateId}
    ACCOUNT_SEND_PASSWORD_RESET_EMAIL: '/api/Account/SendResetPasswordEmail/%s',

    // PUT /api/Account/ResetPassword/{affiliateId}
    ACCOUNT_RESET_PASSWORD: '/api/Account/ResetPassword/%s',

    // GET|PUT /api/Profile/{affiliateId}
    PROFILE: '/api/Profile/%s',

    // GET|PUT /api/Profile/Preferences/{affiliateId}
    PROFILE_PREFERENCES: '/api/Profile/Preferences/%s',
};
