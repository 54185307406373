/**
 * Media action types
 */
export const FETCH_MEDIA_GENRES_REQUEST = 'FETCH_MEDIA_GENRES_REQUEST';
export const FETCH_MEDIA_GENRES_SUCCESS = 'FETCH_MEDIA_GENRES_SUCCESS';
export const FETCH_MEDIA_GENRES_ERROR = 'FETCH_MEDIA_GENRES_ERROR';

export const FETCH_MEDIA_REQUEST = 'FETCH_MEDIA_REQUEST';
export const FETCH_MEDIA_SUCCESS = 'FETCH_MEDIA_SUCCESS';
export const FETCH_MEDIA_ERROR = 'FETCH_MEDIA_ERROR';

export const FETCH_MEDIA_GENRE_MEDIA = 'FETCH_MEDIA_GENRE_MEDIA';
export const FETCH_MEDIA_COLLECTION_MEDIA = 'FETCH_MEDIA_COLLECTION_MEDIA';
export const FETCH_MEDIA_ITEM = 'FETCH_MEDIA_ITEM';
export const FETCH_MEDIA = 'FETCH_MEDIA';
export const FETCH_MEDIA_AND_PREVIEW_PLAYBACK_SOURCES = 'FETCH_MEDIA_AND_PREVIEW_PLAYBACK_SOURCES';
export const FETCH_RECOMMENDATIONS_MEDIA = 'FETCH_RECOMMENDATIONS_MEDIA';
export const SEARCH_MEDIA = 'SEARCH_MEDIA';

export const STORE_MEDIA_GENRES = 'STORE_MEDIA_GENRES';
export const CREATE_MEDIA_BUCKET = 'CREATE_MEDIA_BUCKET';
export const UPDATE_MEDIA_BUCKET = 'UPDATE_MEDIA_BUCKET';
export const STORE_MEDIA_BUCKET_MEDIA = 'STORE_MEDIA_BUCKET_MEDIA';
export const MEDIA_BUCKET_MEDIA_STORED = 'MEDIA_BUCKET_MEDIA_STORED';
export const MEDIA_BUCKET_IN_STORE = 'MEDIA_BUCKET_IN_STORE';
