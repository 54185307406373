import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MobileDetect from 'mobile-detect';
import './MediaHeroBannerPreview.scss';
import {
    BEMClassNameResolver,
    BEMClassNameResolverPropTypes,
    BEMClassNameResolverDefaultProps,
} from '../../ui-kit/BEMClassNameResolver';
import BackgroundPlayer from './BackgroundPlayer';
import BackgroundPlayerControls from './BackgroundPlayerControls';
import mobileBgImage from '../../assets/images/mwc_placeholder_creative.jpg';

@BEMClassNameResolver('vub-c-media-hero-banner-preview')
class MediaHeroBannerPreview extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
        mediaItem: PropTypes.object,
        mediaItemPreviews: PropTypes.array,
        playbackDelay: PropTypes.number,
        setHeaderTransparency: PropTypes.func.isRequired,
        isWithImage: PropTypes.bool,
        isPlaybackEnabled: PropTypes.bool,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
        mediaItem: null,
        mediaItemPreviews: null,
        playbackDelay: null,
        isWithImage: false,
        isPlaybackEnabled: true,
    };

    constructor(props) {
        super(props);
        const isMobilePortrait = this.isMobileDevice() && (window.innerHeight > window.innerWidth);
        this.state = {
            isMuted: !this.isMobileDevice(),
            activeMediaItemPreviewIndex: 0,
            isMobilePlaybackCollectionStarted: false,
            isMobilePortrait,
        };
    }

    componentDidMount() {
        if ('onorientationchange' in window) {
            window.addEventListener('orientationchange', this.onOrientationChange);
        }
    }

    componentWillUnmount() {
        this.props.setHeaderTransparency(false);
        window.removeEventListener('orientationchange', this.onOrientationChange);
    }

    isMobileDevice = () => {
        const md = new MobileDetect(window.navigator.userAgent);
        return !!md.phone();
    };

    onOrientationChange = () => {
        const isMobilePortrait = window.orientation === 0;
        this.setState({
            isMobilePortrait,
        });
    };

    onMediaPlaybackStart = () => {
        this.props.setHeaderTransparency(true);
    };

    onMediaPlaybackToggle = isPlaybackPaused => {
        this.props.setHeaderTransparency(!isPlaybackPaused);
    };

    onMediaMuteToggle = isMuted => {
        this.setState({
            isMuted,
        });
    };

    onMediaPlaybackStop = () => {
        this.props.setHeaderTransparency(false);
        this.setState(state => ({
            activeMediaItemPreviewIndex: state.activeMediaItemPreviewIndex === this.props.mediaItemPreviews.length - 1
                ? 0 : state.activeMediaItemPreviewIndex + 1,
            isMobilePlaybackCollectionStarted:
                state.activeMediaItemPreviewIndex === this.props.mediaItemPreviews.length - 1
                    ? false
                    : state.isMobilePlaybackCollectionStarted,
        }));
    };

    playPreview = () => {
        this.setState({
            isMobilePlaybackCollectionStarted: true,
        });
    };

    isMultiPlaybackReady = currentPlaybackSource => {
        const {
            isMobilePlaybackCollectionStarted,
            isMobilePortrait,
        } = this.state;
        if (isMobilePortrait) {
            if (isMobilePlaybackCollectionStarted) {
                return currentPlaybackSource && currentPlaybackSource.url;
            }
            return null;
        }
        return currentPlaybackSource && currentPlaybackSource.url;
    };

    render() {
        const {
            resolveClassName,
            mediaItem,
            playbackDelay,
            isWithImage,
            mediaItemPreviews,
            isPlaybackEnabled,
        } = this.props;
        const {
            activeMediaItemPreviewIndex,
            isMuted,
            isMobilePlaybackCollectionStarted,
            isMobilePortrait,
        } = this.state;
        const currentPlaybackSource = mediaItemPreviews
            && mediaItemPreviews[activeMediaItemPreviewIndex].source;
        const currentBoxCoverImage = mediaItemPreviews && mediaItemPreviews[activeMediaItemPreviewIndex].imageBoxCover;
        const currentMediaPlaybackStartAt = mediaItemPreviews
            && mediaItemPreviews[activeMediaItemPreviewIndex].playbackStartAt;
        const mobilePortraitPreviewStyle = {
            backgroundImage: `url(${activeMediaItemPreviewIndex === 0 ? mobileBgImage : currentBoxCoverImage})`,
        };
        const previewStyle = isWithImage ? {backgroundImage: `url(${currentBoxCoverImage})`} : {};
        return (
            <div
                className={resolveClassName()}
                style={isMobilePortrait ? mobilePortraitPreviewStyle : previewStyle}
            >
                {!isMobilePlaybackCollectionStarted && isMobilePortrait && currentPlaybackSource && (
                    <div
                        className={resolveClassName(`__button-view`)}
                        onClick={this.playPreview}
                    >
                        <span className={resolveClassName(`__button-view-logo`)} />
                        <span className={resolveClassName(`__button-view-descriptor`)}>
                            trailer
                        </span>
                    </div>
                )}
                {this.isMultiPlaybackReady(currentPlaybackSource) && (
                    <BackgroundPlayer
                        onMediaPlaybackStart={this.onMediaPlaybackStart}
                        onMediaPlaybackToggle={this.onMediaPlaybackToggle}
                        onMediaPlaybackStop={this.onMediaPlaybackStop}
                        onMediaMuteToggle={this.onMediaMuteToggle}
                        playbackSource={currentPlaybackSource}
                        playbackStartAt={currentMediaPlaybackStartAt}
                        playbackDelay={playbackDelay}
                        isWithSpinner={!isWithImage}
                        isMuted={isMuted}
                        isPlaybackEnabled={isPlaybackEnabled}
                    >
                        <BackgroundPlayerControls
                            isMobileDevice={this.isMobileDevice()}
                            mediaItem={mediaItem}
                        />
                    </BackgroundPlayer>
                )}
            </div>
        );
    }
}

export default MediaHeroBannerPreview;
