import React, {Component, createRef} from 'react';
import throttle from 'lodash.throttle';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import {markSearchQueryString} from './mediaSearchUtils';
import {
    withMediaSearch,
    withMediaSearchPropTypes,
    withMediaSearchDefaultProps,
} from '../media/withMediaSearch';
import './MediaSearchView.scss';
import MediaGallery from '../ui-elements/media-gallery/MediaGallery';
import MediaGalleryTilePortraitExtendable from '../ui-elements/media-gallery-tile/MediaGalleryTilePortraitExtendable';
import BackToTop from '../ui-elements/BackToTop';
import SpinnerStandard from '../ui-elements/spinner/SpinnerStandard';
import ContentRow from '../layout/ContentRow';
import PullDownContent from '../ui-elements/pull-down-content/PullDownContent';
import defaultOrderByOptions from '../static-data/orderByOptions';
import CustomSelect from '../ui-elements/custom-select/CustomSelect';
import Option from '../ui-elements/custom-select/Option';

const minimumSearchQueryLength = 3;

@withMediaSearch
@withMediaRouteHelpers({mediaRouteResolver})
class MediaSearchView extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        ...withMediaSearchPropTypes,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        ...withMediaSearchDefaultProps,
    };

    constructor(props) {
        super(props);
        this.areSearchResultsScrolledToBottom = throttle(this.areSearchResultsScrolledToBottom, 200);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.areSearchResultsScrolledToBottom, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.areSearchResultsScrolledToBottom, false);
    }

    componentRef = createRef();

    areSearchResultsScrolledToBottom = () => {
        const componentNode = this.componentRef.current;
        if (!componentNode) return;
        const {loadMoreResults} = this.props;

        const {mediaBucket} = this.props;
        const componentRect = componentNode.getBoundingClientRect();
        if (mediaBucket
            && !mediaBucket.isLoadingMedia
            && !mediaBucket.isUpdatePending
            && mediaBucket.media.size < mediaBucket.mediaTotalCount
            && window.innerHeight + window.pageYOffset >= componentRect.height
        ) {
            loadMoreResults();
        }
    };

    onGenreFilterSelect = value => this.props.setGenreFilterValue(value);

    onOrderBySelect = value => this.props.setOrderByValue(value);

    render() {
        const {mediaBucket, mediaGenres, searchQueryString, areSearchResultsValid, resolveMediaItemRoute} = this.props;
        const {orderByValue, filterByValue} = this.props;
        const filterOptions = [
            {
                id: 'reset',
                name: 'All Genres',
            },
            ...mediaGenres.toArray(),
        ];

        const orderByOptions = [
            {
                id: 3,
                value: 'relevance',
                name: 'Relevance',
                query: null,
            },
            ...defaultOrderByOptions,
        ];

        return (
            <div className="vub-c-media-search-view" ref={this.componentRef}>
                {searchQueryString.length >= minimumSearchQueryLength
                && areSearchResultsValid
                && mediaBucket
                && !mediaBucket.isUpdatePending
                && (
                    <ContentRow>
                        <PullDownContent>
                            <div className="vub-c-media-search-view__filters">
                                <CustomSelect
                                    label="Genres"
                                    value={filterByValue}
                                    onChange={this.onGenreFilterSelect}
                                >
                                    {filterOptions.map(option => (
                                        <Option
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </Option>
                                    ))}
                                </CustomSelect>
                                <CustomSelect
                                    label="Sort by"
                                    value={orderByValue}
                                    onChange={this.onOrderBySelect}
                                >
                                    {orderByOptions.map(option => (
                                        <Option
                                            key={option.id}
                                            value={option.value}
                                        >
                                            {option.name}
                                        </Option>
                                    ))}
                                </CustomSelect>
                            </div>
                        </PullDownContent>
                    </ContentRow>
                )}

                {searchQueryString.length < minimumSearchQueryLength && (
                    <ContentRow>
                        <div className="vub-c-media-search-view__search-term-length">
                            Search term must be at least 3 characters in length.
                        </div>
                    </ContentRow>
                )}

                {searchQueryString.length >= minimumSearchQueryLength
                && areSearchResultsValid
                && mediaBucket
                && !mediaBucket.isUpdatePending
                && mediaBucket.media.size === 0
                && (
                    <ContentRow>
                        <div className="vub-c-media-search-view__without-media">
                            No media available for your search parameters.
                        </div>
                    </ContentRow>
                )}

                {searchQueryString.length >= minimumSearchQueryLength
                && areSearchResultsValid
                && mediaBucket
                && mediaBucket.media.size > 0
                && (
                    <MediaGallery
                        title={(
                            <div className="vub-c-media-search-view__header">
                                {mediaBucket.mediaTotalCount} Search Results for the Term:
                                <span className="vub-c-media-search-view__query-string"> {searchQueryString}</span>
                            </div>
                        )}
                    >
                        {mediaBucket.media.size > 0 && mediaBucket.media
                            .toArray()
                            .map(mediaItem => (
                                <MediaGalleryTilePortraitExtendable
                                    key={mediaItem.id}
                                    mediaItem={mediaItem}
                                    linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                    additionalContent={
                                        markSearchQueryString(mediaItem.toJS(), searchQueryString).node
                                    }
                                    title={markSearchQueryString(mediaItem.toJS(), searchQueryString).meta}
                                />
                            ))}
                    </MediaGallery>
                )}

                {mediaBucket && mediaBucket.isLoadingMedia ? (
                    <div className="vub-c-media-search-view__spinner">
                        <SpinnerStandard className="vub-c-standard-spinner--small" />
                    </div>
                ) : null}

                <BackToTop
                    title="Back to top"
                    screenMultiplicator={2}
                />
            </div>
        );
    }
}

export default MediaSearchView;
