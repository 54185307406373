import {Record} from 'immutable';
import moment from 'moment';

/**
 * Subscription type
 */
export const SubscriptionType = {
    STANDARD: 'Standard',
    PREMIUM: 'Premium',
    ULTRA: 'Ultra',
};

const parseSubscriptionType = subscriptionDescription => {
    subscriptionDescription = subscriptionDescription.toLowerCase();

    if (subscriptionDescription.includes('basic')) return SubscriptionType.STANDARD;
    if (subscriptionDescription.includes('premium')) return SubscriptionType.PREMIUM;
    if (subscriptionDescription.includes('ultra')) return SubscriptionType.ULTRA;

    return null;
};

/**
 * Subscription definition
 *
 * @typedef {Immutable.Record} Subscription
 * @property {?string} id
 * @property {?boolean} active
 * @property {?string} subscriptionId
 * @property {?string} description
 * @property {?Object} startDate
 * @property {?string} type
 */

/**
 * Subscription record
 *
 * @type {Subscription}
 */
class Subscription extends Record({
    id: null,
    active: null,
    type: null,
    subscriptionId: null,
    description: null,
    startDate: null,
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {Subscription}
     */
    fromDTO(dataTransferObject) {
        return new Subscription({
            ...this.toObject(),
            ...dataTransferObject,
            startDate: dataTransferObject.startDate ? moment(dataTransferObject.startDate) : this.startDate,
            type: dataTransferObject.description
                ? parseSubscriptionType(dataTransferObject.description) : this.type,
        });
    }
}

export default Subscription;
