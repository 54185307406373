import * as actionTypes from './mediaEntitlementActionTypes';

/**
 * Fast checkout mediaItem offer
 */
export const fastCheckoutMediaItemOffer = (payload = {}) => ({
    type: actionTypes.FAST_CHECKOUT_MEDIA_ITEM_OFFER,
    payload,
});


/**
 * Cancel mediaItem offer fast checkout
 */
export const cancelMediaItemOfferFastCheckout = (payload = {}) => ({
    type: actionTypes.CANCEL_MEDIA_ITEM_OFFER_FAST_CHECKOUT,
    payload,
});

/**
 * Charge basket
 */
export const chargeBasket = (payload = {}) => ({
    type: actionTypes.CHARGE_BASKET,
    payload,
});

/**
 * Start mediaItem preview playback
 */
export const startMediaItemPreviewPlayback = (payload = {}) => ({
    type: actionTypes.START_MEDIA_ITEM_PREVIEW_PLAYBACK,
    payload,
});

/**
 * Start mediaItem offer playback
 */
export const startMediaItemOfferPlayback = (payload = {}) => ({
    type: actionTypes.START_MEDIA_ITEM_OFFER_PLAYBACK,
    payload,
});

/**
 * Start mediaItem offer playback
 */
export const startMediaItemSVODOfferPlayback = (payload = {}) => ({
    type: actionTypes.START_MEDIA_ITEM_SVOD_OFFER_PLAYBACK,
    payload,
});

/**
 * Stop mediaItem playback
 */
export const stopMediaItemPlayback = (payload = {}) => ({
    type: actionTypes.STOP_MEDIA_ITEM_PLAYBACK,
    payload,
});
