import {sprintf} from 'sprintf-js';
import resourceEndpoints from './resourceEndpoints';
import handleResponse from './handleResponse';

/**
 * Validates an identity with an affiliates own identity provider and authorization system
 */
export const validateIdentity = function validateIdentity(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', params.apiVersion);

    const resourceEndpoint = sprintf(
        resourceEndpoints.IDENTITY_VALIDATE,
        params.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    const requestBody = {
        'ActionType': 'UserIdentity',
        'ParametersDictionary': {
            'PortalCredentials': params.email + ':' + params.password,
        },
    };

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(requestBody),
        },
    )
        .then(handleResponse);
};

/**
 * Create user and user profile.
 */
export const createAccount = function createAccount(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', params.apiVersion);

    const resourceEndpoint = sprintf(
        resourceEndpoints.ACCOUNT,
        params.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    const requestBody = {
        'Title': '',
        'GivenName': params.name,
        'LastName': '',
        'Password': params.password,
        'Dob': '2018-02-12T09:00:45.876Z',
        'Address1': '',
        'Address2': '',
        'City': '',
        'Country': '',
        'PostCode': '',
        'DayTimeTelephone': '',
        'MobileTelephone': '',
        'FirmName': '',
        'Gender': 'm',
        'AdditionalData': {},
        'EmailAddress': params.email,
        'AcceptedTandCs': typeof params.termsAndConditionsAccepted !== 'undefined'
            ? params.termsAndConditionsAccepted : false,
    };

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${params.trustedApplicationToken}`,
            },
            method: 'POST',
            body: JSON.stringify(requestBody),
        },
    )
        .then(handleResponse);
};

/**
 * Returns user profile
 */
export const getProfile = function getProfile(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', '2'); // because issues with v3

    const resourceEndpoint = sprintf(
        resourceEndpoints.PROFILE,
        params.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Basic ${params.authToken.token}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};

/**
 * Updates user profile
 */
export const updateProfile = function updateProfile(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', '2');

    const resourceEndpoint = sprintf(
        resourceEndpoints.PROFILE,
        params.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    return getProfile(params)
        .then(userProfileDTO => {
            const requestBody = {
                'Title': params.title || userProfileDTO.Title,
                'GivenName': params.name || userProfileDTO.GivenName,
                'LastName': params.lastName || userProfileDTO.LastName,
                'DateOfBirth': params.dateOfBirth || userProfileDTO.Dob,
                'Address1': params.address1 || userProfileDTO.Address1,
                'Address2': params.address2 || userProfileDTO.Address2,
                'City': params.city || userProfileDTO.City,
                'Country': params.country || userProfileDTO.Country,
                'PostCode': params.postCode || userProfileDTO.PostCode,
                'CorrespondenceEmail': params.correspondenceEmail || userProfileDTO.EmailAddress,
                'DayTimeTelephone': params.telephone || userProfileDTO.DayTimeTelephone,
                'MobileTelephone': params.cellphone || userProfileDTO.MobileTelephone,
                'FirmName': params.companyName || userProfileDTO.FirmName,
                'Gender': params.gender || userProfileDTO.Gender,
            };

            return fetch(
                requestEndpoint,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Basic ${params.authToken.token}`,
                    },
                    method: 'PUT',
                    body: JSON.stringify(requestBody),
                },
            )
                .then(handleResponse)
                .then(() => ({
                    ...userProfileDTO,
                    ...requestBody,
                    'Dob': requestBody.DateOfBirth,
                    'EmailAddress': requestBody.CorrespondenceEmail,
                }));
        });
};

/**
 * Returns user profile preferences
 */
export const getProfilePreferences = function getProfilePreferences(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', '2');

    const resourceEndpoint = sprintf(
        resourceEndpoints.PROFILE_PREFERENCES,
        params.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Basic ${params.authToken.token}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};

/**
 * Updates user profile preferences
 */
export const updateProfilePreferences = function updateProfilePreferences(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', '2');

    const resourceEndpoint = sprintf(
        resourceEndpoints.PROFILE_PREFERENCES,
        params.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    return getProfilePreferences(params)
        .then(serverProfilePreferences => {
            const requestBody = {
                'Preferences': {
                    ...serverProfilePreferences,
                    ...params.profilePreferences,
                },
            };

            return fetch(
                requestEndpoint,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Basic ${params.authToken.token}`,
                    },
                    method: 'PUT',
                    body: JSON.stringify(requestBody),
                },
            )
                .then(handleResponse)
                .then(() => requestBody.Preferences);
        });
};

/**
 * Change password
 */
export const changePassword = function changePassword(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', '2');

    const resourceEndpoint = sprintf(
        resourceEndpoints.ACCOUNT_CHANGE_PASSWORD,
        params.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    const requestBody = {
        'OldPassword': params.oldPassword,
        'NewPassword': params.newPassword,
    };

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Basic ${params.authToken.token}`,
            },
            method: 'PUT',
            body: JSON.stringify(requestBody),
        },
    )
        .then(handleResponse);
};

/**
 * Send password reset email
 */
export const sendResetPasswordEmail = function sendResetPasswordEmail(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', '2');

    const resourceEndpoint = sprintf(
        resourceEndpoints.ACCOUNT_SEND_PASSWORD_RESET_EMAIL,
        params.affiliateId,
    );

    queryParams.append('userName', params.email);

    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${params.trustedApplicationToken}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};

/**
 * Reset password
 */
export const resetPassword = function resetPassword(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', '2');

    const resourceEndpoint = sprintf(
        resourceEndpoints.ACCOUNT_RESET_PASSWORD,
        params.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    const requestBody = {
        'Token': params.token,
        'NewPassword': params.newPassword,
    };

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${params.trustedApplicationToken}`,
            },
            method: 'PUT',
            body: JSON.stringify(requestBody),
        },
    )
        .then(handleResponse);
};
