import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MediaItemPopoverContext from './MediaItemPopoverContext';
import MediaItemPopover from './MediaItemPopover';
import config from '../../config';

class MediaItemPopoverProvider extends Component {
    static propTypes = {
        paddingTop: PropTypes.number,
        paddingRight: PropTypes.number,
        paddingBottom: PropTypes.number,
        paddingLeft: PropTypes.number,
        renderPopoverContent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
    };

    state = {
        isCallerHovered: false,
        mediaItem: null,
        elementNode: null,
    };

    toggleMediaItemPopover = (isCallerHovered, mediaItem, elementNode) => {
        this.setState({
            isCallerHovered: isCallerHovered,
            mediaItem: mediaItem,
            elementNode: elementNode,
        });
    };

    render() {
        const {children, paddingTop, paddingRight, paddingBottom, paddingLeft, renderPopoverContent} = this.props;
        const {isCallerHovered, mediaItem, elementNode} = this.state;

        return (
            <MediaItemPopoverContext.Provider
                value={{
                    toggleMediaItemPopover: this.toggleMediaItemPopover,
                }}
            >
                {children}
                <MediaItemPopover
                    mediaItem={mediaItem}
                    isCallerHovered={isCallerHovered}
                    elementNode={elementNode}
                    screenPadding={{
                        top: paddingTop,
                        right: paddingRight,
                        bottom: paddingBottom,
                        left: paddingLeft,
                    }}
                    renderPopoverContent={renderPopoverContent}
                    isDebugPopoverEnabled={config.DEBUG_POPOVER}
                />
            </MediaItemPopoverContext.Provider>
        );
    }
}

export default MediaItemPopoverProvider;
