import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {
    BEMClassNameResolver,
    BEMClassNameResolverPropTypes,
    BEMClassNameResolverDefaultProps,
} from '../../ui-kit/BEMClassNameResolver';
import './PromotionBannerItem.scss';
import MediaItemPreviewButtonView from '../../media-item-preview-button-view/MediaItemPreviewButtonView';

@withRouter
@BEMClassNameResolver('vub-c-promotion-banner-item')
class PromotionBannerItem extends Component {
    static propTypes = {
        ...BEMClassNameResolverPropTypes,
        mediaItem: PropTypes.object,
        history: PropTypes.object,
        resolveMediaItemRoute: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...BEMClassNameResolverDefaultProps,
        mediaItem: null,
        history: null,
    };

    changeRoute = () => {
        const {mediaItem, history, resolveMediaItemRoute} = this.props;
        history.push(resolveMediaItemRoute({mediaItemId: mediaItem.id}));
    };

    render() {
        const {resolveClassName, resolveMediaItemRoute} = this.props;
        const {mediaItem} = this.props;
        const imageUrl = mediaItem.imageThumbnail ? `${mediaItem.imageThumbnail}&q=70` : null;

        return (
            <div className={resolveClassName()} onClick={this.changeRoute} style={{backgroundImage: `url(${imageUrl})`}}>
                <div className={resolveClassName('__content')}>
                    <Link to={resolveMediaItemRoute({mediaItemId: mediaItem.id})} className={resolveClassName('__title')}>
                        {mediaItem.title}
                    </Link>
                    <MediaItemPreviewButtonView mediaItem={mediaItem} />
                </div>
            </div>
        );
    }
}

export default PromotionBannerItem;
