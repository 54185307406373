import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './MediaItemViewPrimaryInfo.scss';

export default class MediaItemViewPrimaryInfo extends PureComponent {
    static propTypes = {
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        mediaItem: null,
    };

    render() {
        const {mediaItem} = this.props;

        return (
            <div className="vub-c-media-item-view-primary-info">
                <p className="vub-c-media-item-view-primary-info__segment">
                    <span className="vub-c-media-item-view-primary-info__title">Certificate:</span>
                    {mediaItem.contentAdvisoryRating}
                </p>
                <p className="vub-c-media-item-view-primary-info__segment">
                    {mediaItem.year}
                </p>
                <p className="vub-c-media-item-view-primary-info__segment">
                    {mediaItem.durationMinutes}
                </p>
            </div>
        );
    }
}
