/**
 * SMPAccountAPI resource endpoints
 */
export default {
    // GET /accounts/v1/{accountId}
    GET_ACCOUNT: '/accounts/v1/%s',

    // POST /account/v1
    CREATE_ACCOUNT: '/account/v1',

    // GET /accounts/v1/{accountId}/entitlements
    GET_ACCOUNT_ENTITLEMENTS: '/accounts/v1/%s/entitlements',

    // GET /accounts/v1/{accountId}/paymentMethods
    GET_ACCOUNT_PAYMENT_METHODS: '/accounts/v1/%s/paymentMethods',
};
