import {sprintf} from 'sprintf-js';
import resourceEndpoints from './resourceEndpoints';
import handleResponse from '../handleResponse';

/**
 * Get basket items
 */
export const getBasketItems = function getBasketItems(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', 2);

    const resourceEndpoint = sprintf(
        resourceEndpoints.BASKET,
        params.ffAPI.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.ffAPI.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Basic ${params.authToken.token}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};

/**
 * Add mediaItem offer to the basket
 */
export const addMediaItemOfferToBasket = function addMediaItemOfferToBasket(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', 2);

    const resourceEndpoint = sprintf(
        resourceEndpoints.BASKET,
        params.ffAPI.affiliateId,
    );
    queryParams.append('offerId', params.offerId);
    queryParams.append('includeMediaItem', params.includeMediaItem ? params.includeMediaItem : false);
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.ffAPI.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    const requestBody = {};

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Basic ${params.authToken.token}`,
            },
            method: 'POST',
            body: JSON.stringify(requestBody),
        },
    )
        .then(handleResponse);
};

/**
 * Remove item from basket
 */
export const removeBasketItem = function removeBasketItem(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', 2);

    const resourceEndpoint = sprintf(
        resourceEndpoints.BASKET,
        params.ffAPI.affiliateId,
    );
    queryParams.append('basketItemId', params.basketItemId);
    queryParams.append('includeMediaItem', params.includeMediaItem ? params.includeMediaItem : false);
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.ffAPI.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Basic ${params.authToken.token}`,
            },
            method: 'DELETE',
        },
    )
        .then(handleResponse);
};

/**
 * Remove all items from basket
 */
export const removeAllBasketItems = function removeAllBasketItems(params) {
    return getBasketItems(params)
        .then(basketItemDTOCollection => {
            return Promise.all(
                basketItemDTOCollection.map(basketItemDTO => {
                    return removeBasketItem({
                        ...params,
                        basketItemId: basketItemDTO.BasketItemId,
                    });
                }),
            );
        });
};

/**
 * Returns DEBUG payment token
 */
export const getDebugPaymentToken = function getDebugPaymentToken(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', 2);

    const resourceEndpoint = sprintf(
        resourceEndpoints.PURCHASE_GET_DEBUG_PAYMENT_TOKEN,
        params.ffAPI.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.ffAPI.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    const requestBody = {
        'CardNumber': '4242424242424242',
        'Cvc': '111',
        'ExpirationYear': 2021,
        'ExpirationMonth': 12,
        'CardOwnerName': 'CardOwnerName',
        'Country': 'UK',
        'CountryCode': 'GBR',
        'City': 'London',
        'AddressState': 'UK',
        'AddressLine1': 'AddressLine1',
        'AddressLine2': 'AddressLine2',
        'PostalCode': 'WC2N 5DU',
        'ProviderType': 'Stripe',
    };

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Basic ${params.authToken.token}`,
            },
            method: 'POST',
            body: JSON.stringify(requestBody),
        },
    )
        .then(handleResponse);
};

/**
 * Charge basket with temporary card token
 */
export const chargeBasketWithTemporaryCardToken = function chargeBasketWithTemporaryCardToken(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', 2);

    const resourceEndpoint = sprintf(
        resourceEndpoints.PURCHASE_CHARGE_CUSTOMER_BASKET,
        params.ffAPI.affiliateId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.ffAPI.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    const requestBody = {
        'TempCardToken': params.temporaryCardToken,
        'AmountDue': params.amountDue,
        'CurrencyCode': params.currencyCode,
        'PaymentProviderType': 'Stripe',
        'SaveCardWithProvider': false,
    };

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Basic ${params.authToken.token}`,
            },
            method: 'POST',
            body: JSON.stringify(requestBody),
        },
    )
        .then(handleResponse);
};

/**
 * Create purchase order for SVOD mediaItem offer
 */
export const createPurchaseOrderItemSVOD = function createPurchaseOrderItemSVOD(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', '2');

    const resourceEndpoint = sprintf(
        resourceEndpoints.PURCHASE_ORDER_ITEM_SVOD,
        params.ffAPI.affiliateId,
        params.offerId,
    );
    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.ffAPI.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Basic ${params.authToken.token}`,
            },
            method: 'POST',
        },
    )
        .then(handleResponse);
};

/**
 * Get current entitlements
 */
export const getCurrentEntitlements = function getCurrentEntitlements(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('v', 2);

    const resourceEndpoint = sprintf(
        resourceEndpoints.ENTITLEMENTS_CURRENT,
        params.ffAPI.affiliateId,
    );
    queryParams.append('includeMediaItem', params.includeMediaItem ? params.includeMediaItem : false);
    queryParams.append('includeMediaAssets', params.includeMediaAssets ? params.includeMediaAssets : false);
    queryParams.append('includeParentAndChild', params.includeParentAndChild ? params.includeParentAndChild : false);
    queryParams.append('take', params.limit || 5000);
    queryParams.append('skip', params.offset || 0);

    const queryParamsString = queryParams.toString();
    const requestEndpoint = `${params.ffAPI.serviceURL}${resourceEndpoint}${queryParamsString
        ? '?' + queryParamsString : ''}`;

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Basic ${params.authToken.token}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};
