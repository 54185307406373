import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './MediaItemViewBoxCover.scss';

export default class MediaItemViewBoxCover extends PureComponent {
    static propTypes = {
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        mediaItem: null,
    };

    render() {
        const {mediaItem} = this.props;

        return (
            <div className="vub-c-media-item-view-box-cover">
                <div className="vub-c-media-item-view-box-cover__image-placeholder">
                    <img
                        className="vub-c-media-item-view-box-cover__image"
                        src={`${mediaItem.imageBoxCover}&q=70&w=200`}
                        alt="tile"
                    />
                </div>
            </div>
        );
    }
}
