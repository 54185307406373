import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {mediaItemClassification} from '@comrock/vub-wls-media-service';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import {withMediaCTA, withMediaCTAPropTypes, withMediaCTADefaultProps} from '../media-entitlement/withMediaCTA';
import './MediaItemCTAButtonBarView.scss';
import CTAButton from '../ui-elements/CTAButton';
import SubscriptionLabel from '../ui-elements/subscription-label/SubscriptionLabel';

@withMediaCTA
@withMediaRouteHelpers({mediaRouteResolver})
class MediaItemCTAButtonBarView extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        ...withMediaCTAPropTypes,
        mediaItem: PropTypes.object,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        ...withMediaCTADefaultProps,
        mediaItem: null,
    };

    render() {
        const {isUserSignedIn, signInUser, userAccount} = this.props;
        const {mediaItem, currentEntitlementsByMediaItem} = this.props;
        const {isFastCheckoutInProgress, fastCheckoutMediaItemOffer, startMediaItemOfferPlayback} = this.props;
        const {resolveMediaItemRoute} = this.props;
        const entitlement = currentEntitlementsByMediaItem && currentEntitlementsByMediaItem.get(mediaItem.id);
        const purchasedOffer = entitlement ? mediaItem.offers
            .find(offer => offer.id === entitlement.offerId) : null;
        const startPurchasedOfferPlayback = () => startMediaItemOfferPlayback(purchasedOffer);

        const hasBothRentOffers = mediaItem.offers
            .find(offer => offer.termsType === 'OneTime' && offer.resolution === 'SD')
            && mediaItem.offers
                .find(offer => offer.termsType === 'OneTime' && offer.resolution === '1080p');

        const hasSVODOffers = !!mediaItem.offers.find(offer => offer.termsType === 'Recurring');

        const mediaItemOffers = hasBothRentOffers
            ? mediaItem.offers.filter(offer => offer.termsType === 'OneTime')
            : (hasSVODOffers
                ? [mediaItem.offers.find(offer => offer.termsType === 'Recurring')]
                : mediaItem.offers.filter(offer => offer.termsType === 'OneTime')
            );

        const signInUserAndRedirectToMediaItemRoute = () => signInUser({
            newUserSessionRoute: resolveMediaItemRoute({mediaItemId: mediaItem.id}),
        });

        return (
            <div className="vub-c-media-item-cta-button-bar-view">
                {!isUserSignedIn
                && mediaItemOffers.map(offer => {
                    if (offer.termsType === 'Recurring') {
                        return (
                            <CTAButton
                                key="cta-subscribe"
                                onClick={signInUserAndRedirectToMediaItemRoute}
                            >
                                Subscribe
                            </CTAButton>
                        );
                    }

                    if (offer.termsType === 'OneTime' && offer.resolution === '1080p') {
                        const price = offer.rentalPrice.toLocaleString('en', {
                            style: 'currency',
                            currency: offer.currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });

                        return (
                            <CTAButton
                                key={offer.id}
                                onClick={signInUserAndRedirectToMediaItemRoute}
                            >
                                Rent HD <span className="vub-c-cta-button__price">{price}</span>
                            </CTAButton>
                        );
                    }

                    if (offer.termsType === 'OneTime' && offer.resolution === 'SD') {
                        const price = offer.rentalPrice.toLocaleString('en', {
                            style: 'currency',
                            currency: offer.currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });

                        return (
                            <CTAButton
                                key={offer.id}
                                onClick={signInUserAndRedirectToMediaItemRoute}
                            >
                                Rent SD <span className="vub-c-cta-button__price">{price}</span>
                            </CTAButton>
                        );
                    }

                    return null;
                })}

                {(mediaItem.classification === mediaItemClassification.MOVIE
                    || mediaItem.classification === mediaItemClassification.EPISODE)
                && isUserSignedIn
                && !entitlement
                && mediaItemOffers.map(offer => {
                    if (offer.termsType === 'Recurring') {
                        const startOfferPlayback = () => startMediaItemOfferPlayback(offer);
                        return (
                            <Fragment key={offer.id}>
                                <CTAButton
                                    onClick={startOfferPlayback}
                                    isDisabled={isFastCheckoutInProgress}
                                >
                                    Play
                                </CTAButton>
                                <span className="vub-c-media-item-cta-button-bar-view__play-message">
                                    Included with your subscription.
                                    {userAccount.primarySubscription && (
                                        <SubscriptionLabel>
                                            {userAccount.primarySubscription.type}
                                        </SubscriptionLabel>
                                    )}
                                </span>
                            </Fragment>
                        );
                    }

                    if (offer.termsType === 'OneTime' && offer.resolution === '1080p') {
                        const fastCheckoutOffer = () => fastCheckoutMediaItemOffer(offer);
                        const price = offer.rentalPrice.toLocaleString('en', {
                            style: 'currency',
                            currency: offer.currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });

                        return (
                            <CTAButton
                                key={offer.id}
                                onClick={fastCheckoutOffer}
                                isDisabled={isFastCheckoutInProgress}
                            >
                                Rent HD {price}
                            </CTAButton>
                        );
                    }

                    if (offer.termsType === 'OneTime' && offer.resolution === 'SD') {
                        const fastCheckoutOffer = () => fastCheckoutMediaItemOffer(offer);
                        const price = offer.rentalPrice.toLocaleString('en', {
                            style: 'currency',
                            currency: offer.currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });

                        return (
                            <CTAButton
                                key={offer.id}
                                onClick={fastCheckoutOffer}
                                isDisabled={isFastCheckoutInProgress}
                            >
                                Rent SD {price}
                            </CTAButton>
                        );
                    }

                    return null;
                })}

                {(mediaItem.classification === mediaItemClassification.MOVIE
                    || mediaItem.classification === mediaItemClassification.EPISODE)
                && isUserSignedIn
                && entitlement
                && purchasedOffer
                && (
                    <Fragment key={purchasedOffer.id}>
                        <CTAButton onClick={startPurchasedOfferPlayback}>
                            Play
                        </CTAButton>
                        <div className="vub-c-media-item-cta-button-bar-view__play-message">
                            Playable until {entitlement.rentalExpiryTime
                        && entitlement.rentalExpiryTime.format('DD/MM/YYYY [at] HH:mm [(GMT)]')}
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default MediaItemCTAButtonBarView;
