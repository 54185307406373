/**
 * Static data for orderBy options
 *
 * @type {Array}
 */
const orderByOptions = [
    {
        id: 0,
        value: 'a-z',
        name: 'A-Z',
        query: [
            'titleLong:ASC',
        ],
    },
    {
        id: 1,
        value: 'z-a',
        name: 'Z-A',
        query: [
            'titleLong:DESC',
        ],
    },
    {
        id: 2,
        value: 'year',
        name: 'Release year',
        query: [
            'year:DESC',
            'titleLong:ASC',
        ],
    },
];

export default orderByOptions;
