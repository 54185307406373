import WLSConfigurationService from '@comrock/vub-wls-configuration-service';
import MediaService from '@comrock/vub-wls-media-service';
import MediaProviderCCSearchInMemory from '@comrock/vub-wls-media-provider-cc-search-in-memory';
import store from 'store';
import ua from 'universal-analytics';
import uuidGenerator from 'uuid';
import AuthService from './vub-wls-auth-service';
import AuthProviderSMP from './vub-wls-auth-provider-smp';
import AuthProviderFilmFlex from './vub-wls-auth-provider-film-flex';
import MediaEntitlementService from './vub-wls-media-entitlement-service';
import MediaEntitlementProviderFilmFlex from './vub-wls-media-entitlement-provider-film-flex';
import MediaEntitlementProviderSMP from './vub-wls-media-entitlement-provider-smp';
import CustomMediaRouteResolver from './navigation/CustomMediaRouteResolver';
import config from './config';

export const wlsConfigurationService = new WLSConfigurationService({
    serviceURL: config.WLS_CONFIGURATION_API_URI,
});

const mediaProviderCCSearchInMemory = new MediaProviderCCSearchInMemory({
    serviceURL: config.CC_SEARCH_API_URI,
    apiKey: config.CC_SEARCH_API_KEY,
    apiVersion: config.CC_SEARCH_API_VERSION,
    providerId: config.CC_SEARCH_API_PROVIDER_ID,
    provider: config.CC_SEARCH_API_PROVIDER,
});

export const mediaService = new MediaService(mediaProviderCCSearchInMemory);

// TODO auth provider branching should be done in affiliate package
let authProvider;
let mediaEntitlementProvider;

if (!config.TEMP_USE_SSO_AUTH_PROVIDER) {
    authProvider = new AuthProviderFilmFlex({
        serviceURL: config.FF_API_URI,
        apiVersion: config.FF_API_VERSION,
        trustedApplicationToken: config.FF_API_TRUSTED_APPLICATION_TOKEN,
        affiliateId: config.FF_API_AFFILIATE_ID,
    });

    mediaEntitlementProvider = new MediaEntitlementProviderFilmFlex({
        ccIdentityAPI: {
            serviceURL: config.CC_IDENTITY_API_URI,
            clientId: config.CC_IDENTITY_API_CLIENT_ID,
            clientSecret: config.CC_IDENTITY_API_CLIENT_SECRET,
        },
        ccAPI: {
            serviceURL: config.CC_API_URI,
            providerId: config.CC_API_PROVIDER_ID,
            provider: config.CC_API_PROVIDER,
        },
        ffAPI: {
            serviceURL: config.FF_API_URI,
            apiVersion: config.FF_API_VERSION,
            trustedApplicationToken: config.FF_API_TRUSTED_APPLICATION_TOKEN,
            affiliateId: config.FF_API_AFFILIATE_ID,
        },
    });
}

if (config.TEMP_USE_SSO_AUTH_PROVIDER) {
    authProvider = new AuthProviderSMP({
        ccIdentityAPI: {
            serviceURL: config.CC_IDENTITY_API_URI,
            clientId: config.CC_IDENTITY_API_CLIENT_ID,
            clientSecret: config.CC_IDENTITY_API_CLIENT_SECRET,
        },
        uxpIdentityService: {
            serviceURL: config.UXP_IDENTITY_URI,
        },
        smpAccountAPI: {
            serviceURL: config.SMC_ACCOUNT_API_URI,
        },
    });

    mediaEntitlementProvider = new MediaEntitlementProviderSMP({
        mediaProviderCCSearchInMemory,
        ccIdentityAPI: {
            serviceURL: config.CC_IDENTITY_API_URI,
            clientId: config.CC_IDENTITY_API_CLIENT_ID,
            clientSecret: config.CC_IDENTITY_API_CLIENT_SECRET,
        },
        ccAPI: {
            serviceURL: config.CC_API_URI,
            providerId: config.CC_API_PROVIDER_ID,
            provider: config.CC_API_PROVIDER,
        },
    });
}

export const authService = new AuthService(authProvider);

export const mediaEntitlementService = new MediaEntitlementService(mediaEntitlementProvider);

const gaCID = store.get('ga_cid') || uuidGenerator.v4();
store.set('ga_cid', gaCID);
export const gaService = ua(config.GA_TRACKING_ID, gaCID, {https: true});

export const mediaRouteResolver = new CustomMediaRouteResolver();
