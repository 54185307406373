import qs from 'qs';
import resourceEndpoints from './resourceEndpoints';
import handleResponse from '../handleResponse';

/**
 * Get Content Cloud Identity Server token
 */
export const getCCIdentityToken = function getCCIdentityToken(params) {
    const requestEndpoint = `${params.ccIdentityAPI.serviceURL}${resourceEndpoints.TOKEN}`;

    const formData = {
        'grant_type': 'client_credentials',
        'client_id': params.ccIdentityAPI.clientId,
        'client_secret': params.ccIdentityAPI.clientSecret,
        'scope': params.scope,
        'resolution': params.resolution,
        'platformclass': params.platformClass,
        'platformsubclass': params.platformSubclass,
        'delivery': params.delivery,
        'region': params.region,
    };

    return fetch(
        requestEndpoint,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
            },
            method: 'POST',
            body: qs.stringify(formData),
        },
    )
        .then(handleResponse);
};
