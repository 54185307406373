import React from 'react';
import {Link} from 'react-router-dom';
import routePaths from '../../routePaths';
import resolveRoute from '../../navigation/resolveRoute';
import './LogoFooter.scss';

const LogoFooter = () => (
    <Link to={resolveRoute(routePaths.STOREFRONT)} className="vub-c-logo-footer" />
);

export default LogoFooter;
