import store from 'store';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import {providerAuthenticationType, providerAuthenticationProtocol} from '../vub-wls-auth-service';
import * as smpAccountAPIResourceMethods from './smp-account-api/resourceMethods';
import * as responseHelpers from './responseHelpers';

/**
 * Injects service parameters into API resource method
 */
const injectServiceParameters = (apiResourceMethod, serviceParameters) => params => apiResourceMethod({
    ...serviceParameters,
    ...params,
});

/**
 * Vubiquity WLS auth provider for SMP identity API
 */
export default class AuthProviderSMP {
    /**
     * @param {Object} serviceParameters
     * @returns {this}
     */
    constructor(serviceParameters = {}) {
        this.serviceParameters = serviceParameters;
        /**
         * @type {Object}
         * @property {function} createAccount
         * @property {function} getAccount
         * @property {function} getAccountEntitlements
         * @property {function} getAccountPaymentMethods
         */
        this.resources = [
            smpAccountAPIResourceMethods,
        ].reduce((resources, apiResourceMethods) => {
            const apiResources = Object.keys(apiResourceMethods).reduce((apiResources, methodKey) => {
                apiResources[methodKey] = injectServiceParameters(
                    apiResourceMethods[methodKey],
                    {
                        ...serviceParameters,
                        authToken: this.authToken,
                    },
                );
                return apiResources;
            }, {});

            return {
                ...resources,
                ...apiResources,
            };
        }, {});

        this.authToken = null;
        this.userSessionTimeout = null;
    }

    authenticationType = providerAuthenticationType.SINGLE_SIGN_ON;

    authenticationProtocol = providerAuthenticationProtocol.OAUTH2;

    getOAuth2AuthorizationUri(params) {
        params = {...params, ...this.serviceParameters};
        const resourceEndpoint = '/gateway/PlayVu';

        const queryParams = new URLSearchParams();
        queryParams.append('response_type', params.responseType);
        queryParams.append('client_id', params.ccIdentityAPI.clientId);
        queryParams.append('redirect_uri', params.redirectUri);
        if (params.scope) queryParams.append('scope', params.scope);
        if (params.state) queryParams.append('state', params.state);
        const queryParamsString = queryParams.toString();

        return `${params.ccIdentityAPI.serviceURL}${resourceEndpoint}${queryParamsString
            ? '?' + queryParamsString : ''}`;
    }

    getAccountSettingsUri(params) {
        params = {...params, ...this.serviceParameters};
        const resourceEndpoint = '/oidc/billing';

        return `${params.uxpIdentityService.serviceURL}${resourceEndpoint}`;
    }

    createUserAccount(params) {
        // no op
    }

    authenticateUser(params) {
        const resources = this.resources;
        const {authToken} = params;

        const jwt = jwtDecode(authToken);
        const {external_id: externalId, email: emailAddress, account_id: accountId, name, sub} = jwt;
        return resources.getAccount({accountId, authToken})
            .catch(error => {
                const {response} = error;
                if (response && response.status === 404) {
                    return resources
                        .createAccount({externalId, emailAddress, identityId: accountId, name, sub, authToken});
                }

                throw error;
            })
            .then(userAccountDTO => {
                return Promise.all([
                    resources.getAccountEntitlements({accountId, authToken}).catch(() => []),
                    resources.getAccountPaymentMethods({accountId, authToken}).catch(() => []),
                ]).then(results => ({
                    userAccountDTO,
                    accountEntitlements: results[0],
                    accountPaymentMethods: results[1],
                }));
            })
            .then(({userAccountDTO, accountEntitlements, accountPaymentMethods}) => {
                this.startUserSession(authToken);

                return responseHelpers.formatAccountResponse({
                    userAccountDTO,
                    accountEntitlements,
                    accountPaymentMethods,
                    authToken,
                });
            });
    }

    startUserSession(authToken) {
        store.set('wls_cc_id_access_token', JSON.stringify(authToken));
        this.authToken = authToken;

        // set userSession timeout
        const jwt = jwtDecode(this.authToken);
        const tokenExpiration = moment.unix(jwt.exp);
        const tokenDuration = moment.duration(tokenExpiration.diff(moment()));
        this.userSessionTimeout = new Promise(resolve => {
            const timeout = setTimeout(() => {
                this.endUserSession();
                clearTimeout(timeout);
                resolve(true);
            }, tokenDuration.asMilliseconds());
        });
    }

    hasUserSessionExpired() {
        return this.userSessionTimeout;
    }

    restoreUserSession() {
        const storedAuthTokenDTO = store.get('wls_cc_id_access_token');
        if (!storedAuthTokenDTO) return null;

        const authToken = JSON.parse(storedAuthTokenDTO);
        if (!authToken) return null;

        const jwt = jwtDecode(authToken);
        if (moment() > moment.unix(jwt.exp)) {
            store.set('wls_cc_id_access_token', JSON.stringify(null));
            return null;
        }
        this.authToken = authToken;

        // set userSession timeout
        const tokenExpiration = moment.unix(jwt.exp);
        const tokenDuration = moment.duration(tokenExpiration.diff(moment()));
        this.userSessionTimeout = new Promise(resolve => {
            const timeout = setTimeout(() => {
                this.endUserSession();
                clearTimeout(timeout);
                resolve(true);
            }, tokenDuration.asMilliseconds());
        });

        return this.authToken;
    }

    endUserSession() {
        store.set('wls_cc_id_access_token', JSON.stringify(null));
        this.authToken = null;
    }

    getUserAccount() {
        const resources = this.resources;

        const jwt = jwtDecode(this.authToken);
        const {account_id: accountId} = jwt;
        return resources
            .getAccount({accountId, authToken: this.authToken})
            .then(userAccountDTO => {
                return Promise.all([
                    resources.getAccountEntitlements({accountId, authToken: this.authToken}).catch(() => []),
                    resources.getAccountPaymentMethods({accountId, authToken: this.authToken}).catch(() => []),
                ]).then(results => ({
                    userAccountDTO,
                    accountEntitlements: results[0],
                    accountPaymentMethods: results[1],
                }));
            })
            .then(responseHelpers.formatAccountResponse);
    }

    updateUserAccount() {
        // no-op
    }

    requestPasswordReset() {
        // no-op
    }

    resetPassword() {
        // no-op
    }
}
