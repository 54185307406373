import warning from 'warning';

/**
 * Vubiquity WLS Auth service
 *
 * provides auth service in application via given auth provider instance
 */
class AuthService {
    /**
     * @param {AuthProvider} authProvider - AuthProvider instance
     * @returns {Object}
     */
    constructor(authProvider) {
        this.authProvider = authProvider;

        return [
            'authenticationType',
            'authenticationProtocol',
            'getOAuth2AuthorizationUri',
            'createUserAccount',
            'authenticateUser',
            'hasUserSessionExpired',
            'restoreUserSession',
            'endUserSession',
            'getUserAccount',
            'updateUserAccount',
            'requestPasswordReset',
            'resetPassword',
        ].reduce((serviceInterface, providerProperty) => {
            serviceInterface[providerProperty] = this.forwardToAuthProvider(providerProperty);
            return serviceInterface;
        }, {});
    }

    forwardToAuthProvider = providerProperty => {
        const isPropertyAvailable = typeof this.authProvider[providerProperty] !== 'undefined';
        if (!isPropertyAvailable) {
            warning(isPropertyAvailable, `[auth-service] AuthProvider should implement '${providerProperty}'`);
            throw new Error('Interface implementation error');
        }

        return typeof this.authProvider[providerProperty] === 'function'
            ? (...args) => this.authProvider[providerProperty](...args)
            : this.authProvider[providerProperty];
    };
}

/**
 * Provider authentication types and
 */
const providerAuthenticationType = {
    BUILT_IN: 'BUILT_IN',
    SINGLE_SIGN_ON: 'SINGLE_SIGN_ON',
};

const providerAuthenticationProtocol = {
    TOKEN_BASED_AUTHENTICATION: 'TOKEN_BASED_AUTHENTICATION',
    OAUTH: 'OAUTH',
    OAUTH2: 'OAUTH2',
    OPEN_ID: 'OPEN_ID',
    OPEN_ID_CONNECT: 'OPEN_ID_CONNECT',
    SAML: 'SAML',
};

export {providerAuthenticationType, providerAuthenticationProtocol};

/**
 * AuthProvider definition
 *
 * @typedef {Object} AuthProvider
 */
export default AuthService;
