import warning from 'warning';

/**
 * WLS Media entitlement service
 *
 * provides entitlements service in application via given entitlements provider instance
 */
class MediaEntitlementService {
    /**
     * @param {MediaEntitlementProvider} mediaEntitlementProvider - MediaEntitlementProvider instance
     * @returns {Object}
     */
    constructor(mediaEntitlementProvider) {
        this.mediaEntitlementProvider = mediaEntitlementProvider;

        return [
            'setAuthToken',
            'checkoutSingleMediaItem',
            'chargeBasket',
            'createPurchaseOrderItemSVOD',
            'getCurrentEntitlements',
            'getMediaItemPlaybackSource',
        ].reduce((serviceInterface, providerMethod) => {
            serviceInterface[providerMethod] = this.forwardToProvider(providerMethod);
            return serviceInterface;
        }, {});
    }

    forwardToProvider = providerMethod => (...args) => {
        const isMethodAvailable = typeof this.mediaEntitlementProvider[providerMethod] === 'function';
        if (!isMethodAvailable) {
            warning(isMethodAvailable, `[media-entitlement-service] MediaEntitlementProvider should implement '${providerMethod}'`);
            throw new Error('Interface implementation error');
        }

        return this.mediaEntitlementProvider[providerMethod](...args);
    };
}

/**
 * MediaEntitlementProvider definition
 *
 * @typedef {Object} MediaEntitlementProvider
 */

export default MediaEntitlementService;
