import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {mediaRouteResolver} from '../services';
import {withMediaRouteHelpers, withMediaRouteHelpersPropTypes, withMediaRouteHelpersDefaultProps} from '../media/withMediaRouteHelpers';
import * as selectors from './mediaItemViewSelectors';
import {getMediaItem, getMediaItemChildren} from '../media/mediaBucketHelpers';
import './MediaItemTVShowView.scss';
import ContentRow from '../layout/ContentRow';
import MediaItemHeroImage from './elements/MediaItemHeroImage';
import MediaItemViewTitle from './elements/MediaItemViewTitle';
import MediaItemViewBoxCover from './elements/MediaItemViewBoxCover';
import MediaItemViewContentInfo from './elements/MediaItemViewContentInfo';
import MediaItemViewSynopsis from './elements/MediaItemViewSynopsis';
import MediaItemViewCredits from './elements/MediaItemViewCredits';
import MediaGallery from '../ui-elements/media-gallery/MediaGallery';
import MediaGalleryTilePortrait from '../ui-elements/media-gallery-tile/MediaGalleryTilePortrait';
import CustomSelect from '../ui-elements/custom-select/CustomSelect';
import Option from '../ui-elements/custom-select/Option';
import MediaItemCTAButtonBarView from '../media-item-cta-button-bar-view/MediaItemCTAButtonBarView';

@withRouter
@withMediaRouteHelpers({mediaRouteResolver})
class MediaItemTVShowView extends Component {
    static propTypes = {
        ...withMediaRouteHelpersPropTypes,
        mediaBucket: PropTypes.object,
        recommendationsMediaBucket: PropTypes.object,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {
        ...withMediaRouteHelpersDefaultProps,
        mediaBucket: null,
        recommendationsMediaBucket: null,
    };

    onSeasonSelect = value => {
        if (value === 'all') return;
        const {history, resolveMediaItemRoute} = this.props;

        history.push(resolveMediaItemRoute({mediaItemId: value}));
    };

    render() {
        const {mediaBucket, recommendationsMediaBucket, match, resolveMediaItemRoute} = this.props;
        const {mediaItemId} = match.params;
        const tvShowMediaItem = mediaBucket && mediaItemId ? getMediaItem(mediaBucket, mediaItemId) : null;
        const tvShowMediaItemSeasons = tvShowMediaItem ? getMediaItemChildren(mediaBucket, tvShowMediaItem) : null;

        const mediaItem = tvShowMediaItem;
        const seasonSelectOptions = tvShowMediaItemSeasons ? [
            {id: 'all', value: 'all', name: 'All Seasons'},
            ...tvShowMediaItemSeasons.toArray().map(seasonMediaItem => ({
                id: seasonMediaItem.id,
                value: seasonMediaItem.id,
                name: seasonMediaItem.title,
            })),
        ] : [];

        return mediaItem ? (
            <Fragment>
                <MediaItemHeroImage mediaItem={mediaItem}>
                    <ContentRow>
                        <div className="vub-c-media-item-tv-show-view">
                            <div className="vub-c-media-item-tv-show-view__box-cover">
                                <MediaItemViewBoxCover mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-tv-show-view__title">
                                <MediaItemViewTitle mediaItem={mediaItem} mediaBucket={mediaBucket} />
                            </div>
                            <div className="vub-c-media-item-tv-show-view__main">
                                <MediaItemCTAButtonBarView mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-tv-show-view__season-select">
                                <CustomSelect
                                    value="all"
                                    onChange={this.onSeasonSelect}
                                >
                                    {seasonSelectOptions.map(option => (
                                        <Option
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </Option>
                                    ))}
                                </CustomSelect>
                            </div>
                            <div className="vub-c-media-item-tv-show-view__content-info">
                                <MediaItemViewContentInfo mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-tv-show-view__synopsis">
                                <MediaItemViewSynopsis mediaItem={mediaItem} />
                            </div>
                            <div className="vub-c-media-item-tv-show-view__credits">
                                <MediaItemViewCredits mediaItem={mediaItem} />
                            </div>
                        </div>
                    </ContentRow>
                </MediaItemHeroImage>

                {recommendationsMediaBucket && recommendationsMediaBucket.media.size > 0 ? (
                    <MediaGallery
                        title="You might also like"
                    >
                        {recommendationsMediaBucket.media
                            .toArray()
                            .map(mediaItem => (
                                <MediaGalleryTilePortrait
                                    key={mediaItem.id}
                                    mediaItem={mediaItem}
                                    linkTo={resolveMediaItemRoute({mediaItemId: mediaItem.id})}
                                />
                            ))}
                    </MediaGallery>
                ) : null}
            </Fragment>
        ) : null;
    }
}

const createMapStateToProps = () => {
    const mediaBucketSelector = selectors.createMediaItemViewBucketSelector();
    const recommendationsMediaBucketSelector = selectors.createMediaItemRecommendationsViewBucketSelector();
    return (state, props) => ({
        mediaBucket: mediaBucketSelector(state, props),
        recommendationsMediaBucket: recommendationsMediaBucketSelector(state, props),
    });
};

export default connect(
    createMapStateToProps,
)(MediaItemTVShowView);
