import React from 'react';
import PropTypes from 'prop-types';
import './CTAButtonsLayout.scss';
import CTAButton from '../../ui-elements/CTAButton';

const CTAButtonsLayout = ({title}) => (
    <div className="vub-c-cta-buttons-layout">
        <div className="vub-c-cta-buttons-layout__title">
            {title}
        </div>
        <div className="vub-c-cta-buttons-layout__content">
            <div className="vub-c-cta-buttons-layout__container">
                <div className="vub-c-cta-buttons-layout__container-title">
                    default
                </div>
                <div className="vub-c-cta-buttons-layout__buttons">
                    <CTAButton>Rent from £12.80</CTAButton>
                    <CTAButton>Play</CTAButton>
                    <CTAButton>Download</CTAButton>
                    <CTAButton>SD £12.80</CTAButton>
                    <CTAButton className="vub-c-cta-button--secondary">Cancel</CTAButton>
                </div>
            </div>
            <div className="vub-c-cta-buttons-layout__container">
                <div className="vub-c-cta-buttons-layout__container-title">
                    disabled
                </div>
                <div className="vub-c-cta-buttons-layout__buttons">
                    <CTAButton isDisabled={true}>Rent from £12.80</CTAButton>
                    <div className="vub-c-cta-buttons-layout__empty-element" />
                    <CTAButton isDisabled={true}>Download</CTAButton>
                </div>
            </div>
        </div>
    </div>
);

CTAButtonsLayout.propTypes = {
    title: PropTypes.string,
};

CTAButtonsLayout.defaultProps = {
    title: 'buttons',
};

export default CTAButtonsLayout;
