import {mediaItemStaffMemberType} from '@comrock/vub-wls-media-service';

const findSearchQueryStringInMediaItem = (mediaItem, searchQueryString) => {
    searchQueryString = searchQueryString.toLowerCase();

    const isInTitle = typeof mediaItem.title !== 'undefined'
        && mediaItem.title.toLowerCase().includes(searchQueryString);
    if (isInTitle) return {key: 'title', value: mediaItem.title};

    const isInCast = mediaItem.staffMembers
        .filter(staffMember => staffMember.type === mediaItemStaffMemberType.ACTOR)
        .reduce((isInCast, staffMember) => (staffMember.name.toLowerCase().includes(searchQueryString)
            ? staffMember.name : isInCast), null);
    if (isInCast) return {key: 'cast', value: isInCast};

    const isInAuthor = mediaItem.staffMembers
        .filter(staffMember => staffMember.type === mediaItemStaffMemberType.DIRECTOR
            || staffMember.type === mediaItemStaffMemberType.WRITER)
        .reduce((isInAuthor, staffMember) => (staffMember.name.toLowerCase().includes(searchQueryString)
            ? staffMember.name : isInAuthor), null);
    if (isInAuthor) return {key: 'director', value: isInAuthor};

    const isInSynopsis = mediaItem.synopsisShort.toLowerCase().includes(searchQueryString);
    if (isInSynopsis) return {key: 'synopsis', value: mediaItem.synopsisShort};

    return null;
};

const createHtmlString = content => searchQueryString => {
    const foundedString = new RegExp(searchQueryString, 'gi');
    const indexOfQueryString = content.search(foundedString);
    const emphasized = `<em>${content.slice(indexOfQueryString, indexOfQueryString + searchQueryString.length)}</em>`;
    return tagName => `<${tagName}>${content.replace(foundedString, emphasized)}</${tagName}>`;
};

export const markSearchQueryString = (mediaItem, searchQueryString) => {
    const result = findSearchQueryStringInMediaItem(mediaItem, searchQueryString);

    if (result) {
        return {
            node: createHtmlString(result.value)(searchQueryString)('div'),
            meta: result.key,
        };
    }

    return {};
};
